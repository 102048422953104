export function formatUIValue(value: unknown): string {
    if (value === null || value === undefined) {
        return '-';
    }

    return String(value);
}

export function formatCommas(value: string | number): string {
    return Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(Number(value));
}

export function formatPrice (price: string | number) {
    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(Number(price));
}

export function formatWalletAddress(address: string): string {
    return `${address.slice(0, 5)}...${address.slice(-5)}`;
}
