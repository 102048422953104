import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import {
    mapToBigNumber,
    mapToNumber,
    toBigIntString,
    toDecimalString,
} from './number';
import { getDeadline } from './deadline';

export enum BurnToken {
    Bankx = 'BankX',
    XSD = 'XSD',
}

interface BurnBankxConfig {
    arbitrageContract: ethers.Contract;
    bankxTokenContract: ethers.Contract;
    routerContract: ethers.Contract;
    xsdTokenContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;

    bankxInputAmount: string;
    bankxMinAmount: string;
    ethMinAmount: string;
    xsdToApprove: string;

    goToNextLoadingMessage(message?: string): void;
}
export async function burnBankx(
    {
        arbitrageContract,
        bankxTokenContract,
        routerContract,
        xsdTokenContract,
        provider,

        bankxInputAmount: _bankxInputAmount,
        bankxMinAmount: _bankxMinAmount,
        ethMinAmount: _ethMinAmount,
        xsdToApprove: _xsdToApprove,

        goToNextLoadingMessage,
    }: BurnBankxConfig
): Promise<void> {
    // bankx_amount_d18.mul(bankx_price_usd)
    const decimals = 18;
    const bankxInputAmount = toBigIntString(_bankxInputAmount, decimals);
    const bankxMinAmount = toBigIntString(_bankxMinAmount, decimals);
    const ethMinAmount = toBigIntString(_ethMinAmount, decimals);
    const xsdToApprove = toBigIntString(Math.ceil(Number(_xsdToApprove)), decimals);

    Logger.log('burnBankx: bankxInputAmount', _bankxInputAmount, bankxInputAmount);
    Logger.log('burnBankx: bankxMinAmount', _bankxMinAmount, bankxMinAmount);
    Logger.log('burnBankx: ethMinAmount', _ethMinAmount, ethMinAmount);
    Logger.log('burnBankx: xsdToApprove', _xsdToApprove, xsdToApprove);

    const signer = provider.getSigner();
    const deadline = await getDeadline(provider);
    goToNextLoadingMessage();
    // no slippage
    const bankxReceipt = await bankxTokenContract.connect(signer).approve(arbitrageContract.address, bankxInputAmount);
    Logger.log('burnBankx: bankxReceipt', bankxReceipt);
    await bankxReceipt.wait(1);

    goToNextLoadingMessage();
    // xsd = (bankxAmount * bankxPrice / silverPrice) * (1 - slippage)
    const xsdReceipt = await xsdTokenContract.connect(signer).approve(routerContract.address, xsdToApprove);
    Logger.log('burnBankx: xsdReceipt', xsdReceipt);
    await xsdReceipt.wait(1);

    goToNextLoadingMessage();
    // burnBankX(bankxAmount, ethMinAmount, bankxMinAmount, deadline)
    const receipt = await arbitrageContract.connect(signer).burnBankX(bankxInputAmount, ethMinAmount, bankxMinAmount, deadline);
    Logger.log('burnBankx: receipt', receipt);
    goToNextLoadingMessage(CustomLoadingMessage.Arbitrage);
    await receipt.wait(3);
}

interface BurnXSDConfig {
    arbitrageContract: ethers.Contract;
    bankxTokenContract: ethers.Contract;
    routerContract: ethers.Contract;
    xsdTokenContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;

    xsdInputAmount: string;
    xsdMinAmount: string;
    ethMinAmount: string;
    bankxToApprove: string;

    goToNextLoadingMessage(message?: string): void;
}
export async function burnXSD(
    {
        arbitrageContract,
        bankxTokenContract,
        routerContract,
        xsdTokenContract,
        provider,

        xsdInputAmount: _xsdInputAmount,
        xsdMinAmount: _xsdMinAmount,
        ethMinAmount: _ethMinAmount,
        bankxToApprove: _bankxToApprove,

        goToNextLoadingMessage,
    }: BurnXSDConfig
): Promise<void> {
    // XSD_amount.mul(xag_usd_price).div(283495).mul(1e4);
    const decimals = 18;
    const xsdInputAmount = toBigIntString(_xsdInputAmount, decimals);
    const xsdMinAmount = toBigIntString(_xsdMinAmount, decimals);
    const ethMinAmount = toBigIntString(_ethMinAmount, decimals);
    const bankxToApprove = toBigIntString(Math.ceil(Number(_bankxToApprove)), decimals);

    Logger.log('burnXSD: xsdInputAmount', _xsdInputAmount, xsdInputAmount );
    Logger.log('burnXSD: xsdMinAmount', _xsdMinAmount, xsdMinAmount );
    Logger.log('burnXSD: ethMinAmount', _ethMinAmount, ethMinAmount );
    Logger.log('burnXSD: bankxToApprove', _bankxToApprove, bankxToApprove );

    const signer = provider.getSigner();
    const deadline = await getDeadline(provider);

    goToNextLoadingMessage();
    // no slippage here
    const xsdArbitrageReceipt = await xsdTokenContract.connect(signer).approve(arbitrageContract.address, xsdInputAmount);
    Logger.log('burnXSD: xsdArbitrageReceipt', xsdArbitrageReceipt);
    await xsdArbitrageReceipt.wait(1);

    goToNextLoadingMessage();
    // bankx = (inputAmount * silverPrice /  bankxPrice) * (1 - slippage)
    const bankxReceipt = await bankxTokenContract.connect(signer).approve(routerContract.address, bankxToApprove);
    Logger.log('burnXSD: bankxReceipt', bankxReceipt);
    await bankxReceipt.wait(1);

    goToNextLoadingMessage();
    // TODO: burnXSD(xsd, ethMin, xsdMin, deadline)
    const receipt = await arbitrageContract.connect(signer).burnXSD(xsdInputAmount, ethMinAmount, xsdMinAmount, deadline);
    Logger.log('burnXSD: collateral pool receipt', receipt);
    goToNextLoadingMessage(CustomLoadingMessage.Arbitrage);
    await receipt.wait(3);
}

export function getTokenToBurn(priceDifference: number | string, isTestModeReverseCondition: boolean): BurnToken | string {
    const differential = Number(priceDifference);

    if (isTestModeReverseCondition) {
        if (differential < 0) {
            return BurnToken.Bankx;
        }

        if (differential > 0) {
            return BurnToken.XSD;
        }
    }

    /*
        differential > 0 ? burn bankx for xsd
        differential < 0 ? burn xsd for bankx
        differential === 0 ? do nothing

        mintterraxsd
        redeemterraxsd
        collectRedemption

        weth of
        eth for gas

        price of xsd - price of silver
    */
    if (differential > 0) {
        return BurnToken.Bankx;
    }

    if (differential < 0) {
        return BurnToken.XSD;
    }

    return '';
}

interface Profit {
    amount: string;
    tokenToBurn: ReturnType<typeof getTokenToBurn>;
    usdAmount: string;
    amountToApprove: string;
    isProfitable: boolean;
    ethMinAmount: string;
}

interface CalculateProfitConfig {
    slippage: string;
    priceDifference: number | string;
    bankxPrice: string;
    silverPrice: string;
    xsdPrice: string;
    inputAmount: string;
    ethPrice: string;
    isTestModeReverseCondition: boolean;
}
export function calculateProfit(
    {
        bankxPrice,
        inputAmount,
        isTestModeReverseCondition,
        priceDifference,
        silverPrice,
        slippage,
        xsdPrice,
        ethPrice,
    }: CalculateProfitConfig
): Profit {
    const tokenToBurn = getTokenToBurn(priceDifference, isTestModeReverseCondition);
    const decimals = 18;

    // return the expected amount after slippage has been calculated
    function getSlippageAdjusted(amount: string | number): number {
        const percentage = 1 - Number(slippage) / 100;

        // for burn, the slippage is calculated twice so we want to adjust to user expectations
        // e.g. bankx -> eth -> xsd
        // if full usd value is $100 and user-entered slippage is 10%, the final amount should be $90
        // the adjusted slippage is sqrt of .9 which is .948683...
        //     the min eth should then have usd value of $94.8683...
        // the adjusted slippage is again applied on the amount of $94.8683
        //     the min bankx should then have usd value of $90; the result of $94.8683... * .948683...
        // if user sets slippage to 10%, we want minimum amount to be 90% instead of 81% (the result of input * 90% * 90%)
        // to adjust for the 2 layers, the slippage entered should be the square root of 90%, which is 94.868329805%
        const adjustedPercentage = Math.sqrt(percentage);
        return Number(amount) * adjustedPercentage;
    }

    try {
        if (!Number(inputAmount)) {
            throw Error('invalid input');
        }

        const {
            bankxPrice: bankx,
            inputAmount: input,
            silverPrice: silver,
            xsdPrice: xsd,
        } = mapToBigNumber({
            bankxPrice,
            inputAmount,
            silverPrice,
            xsdPrice,
        }, decimals);

        switch (tokenToBurn) {
            case BurnToken.Bankx: {
                // per white paper: outputBankX = (inputBankX · XSDPrice) / silverPrice
                const outputBankX = toDecimalString(input.mul(xsd).div(silver), decimals);
                const outputUSD = Number(outputBankX) * Number(bankxPrice);

                // this must be the squareroot of set slippage since it is used in 1 of 2 transactions
                const slippageAdjustedUSDForEth = getSlippageAdjusted(outputUSD)
                const expectedMinEth = slippageAdjustedUSDForEth / Number(ethPrice);

                // this must be the squareroot of set slippage since it is used in 1 of 2 transactions
                const slippageAdjustedUSDForBankX = getSlippageAdjusted(slippageAdjustedUSDForEth)
                const expectedMinBankX = slippageAdjustedUSDForBankX / Number(bankxPrice);

                // xsd = (bankxAmount * bankxPrice / silverPrice) * (1 - slippage)
                const xsdToApprove = toDecimalString(input.mul(bankx).div(silver), decimals);

                const profit = {
                    amount: String(expectedMinBankX),
                    isProfitable: expectedMinBankX > Number(inputAmount),
                    tokenToBurn,
                    usdAmount: String(slippageAdjustedUSDForBankX),
                    amountToApprove: xsdToApprove,
                    ethMinAmount: String(expectedMinEth),
                };

                Logger.log('calculateProfit:', profit);
                return profit;
            }
            case BurnToken.XSD: {
                // per white paper: outputXSD = (inputXSD · silverPrice) / XSDPrice
                const outputXSD = toDecimalString(input.mul(silver).div(xsd), decimals);
                const outputUSD = Number(outputXSD) * Number(xsdPrice);

                // this must be the squareroot of set slippage since it is used in 1 of 2 transactions
                const slippageAdjustedUSDForEth = getSlippageAdjusted(outputUSD);
                const expectedMinEth = slippageAdjustedUSDForEth / Number(ethPrice);

                // this must be the squareroot of set slippage since it is used in 2 of 2 transactions
                const slippageAdjustedUSDForXSD = getSlippageAdjusted(slippageAdjustedUSDForEth)
                const expectedMinXSD = slippageAdjustedUSDForXSD / Number(xsdPrice);

                // bankx = (inputAmount * silverPrice /  bankxPrice) * (1 - slippage)
                const bankxToApprove = toDecimalString(input.mul(silver).div(bankx), decimals);

                const profit = {
                    amount: String(expectedMinXSD),
                    isProfitable: expectedMinXSD > Number(inputAmount),
                    tokenToBurn,
                    usdAmount: String(slippageAdjustedUSDForXSD),
                    amountToApprove: bankxToApprove,
                    ethMinAmount: String(expectedMinEth),
                };
                Logger.log('calculateProfit:', profit);
                return profit;
            }
            default:
                throw Error('invalid token');
        }
    } catch (e) {
        return{
            amount: '-',
            tokenToBurn,
            usdAmount: '-',
            amountToApprove: '-',
            isProfitable: true,
            ethMinAmount: '-',
        };
    }
}

interface GetArbitrageMaxXsdConfig {
    bankxPrice: string;
    maxXsd: string;
    xsdPrice: string;
}

export function getArbitrageLimits(config: GetArbitrageMaxXsdConfig): { maxBankx: string; maxXsd: string; } {
    const {
        bankxPrice,
        maxXsd,
        xsdPrice
    } = mapToNumber(config);

    const maxBankx = maxXsd * xsdPrice / bankxPrice;

    return {
        maxBankx: maxBankx.toFixed(5),
        maxXsd: maxXsd.toFixed(5),
    };
}

export async function getIsArbitragePaused(arbitrageContract: ethers.Contract): Promise<boolean> {
    // https://ossllc.atlassian.net/browse/BAN-306
    // confusing naming, but when the result of `pause_arbitrage` is true, it actually means unpaused.
    const isUnpaused = await arbitrageContract.pause_arbitrage();
    Logger.log('getIsArbitragePaused:', isUnpaused);

    return !isUnpaused;
}
