export enum Paths {
    Looping = '/looping',
    Arbitrage = '/arbitrage',
    Certificate = '/certificate',
    Collateral = '/collateral',
    Swap = '/swap',
    Liquidity = '/liquidity-pool',
    Mint = '/mint',
    Redeem = '/redeem',
    Xsd = '/xsd',
    Silver = '/silver',
    Nft = '/nft',
    BuyNft = '/buy-nft',
    Pricing = '/pricing',
    NftLeaderboard = '/nft-leaderboard',
    SellLiquidity = '/sell-liquidity',
}
