import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { Logger } from 'helpers/logging';
import { Storage } from 'helpers/storage';
import { getThemedClassNameAlt } from 'helpers/theme';
import React, {
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';

import Styles from './styles.module.scss';

export interface ModalConfig {
    name: string;
    cta: string;
    acceptMessage: string;
    declineMessage: string;
    checkboxMessage: string;
    onAccept(): void;

    // this prevents a modal from being permanently disabled
    cannotBeDisabled?: boolean;
}

interface ModalProviderValue{
    triggerModal(config: ModalConfig | null): Promise<void>;
}

type ModalStateMap = Record<string, boolean>;
const Context = createContext<ModalProviderValue>({
    triggerModal: () => Promise.resolve(),
});

const MODAL_SETTINGS_STORAGE_KEY = 'mdl_998_sjvL';

const storedSettings = JSON.parse(Storage.get(MODAL_SETTINGS_STORAGE_KEY) || '{}');

export function useModal(): ModalProviderValue {
    return useContext(Context);
}

function saveSettings(state: ModalStateMap): void {
    Storage.set(MODAL_SETTINGS_STORAGE_KEY, JSON.stringify(state));
}

export function ModalProvider({ children }: React.PropsWithChildren): React.ReactElement {
    const [isVisible, setIsVisible] = useState(false);

    const [disabledModals, setDisabledModals] = useState<ModalStateMap>(storedSettings);
    const [viewedModals, setViewedModals] = useState<ModalStateMap>({});

    const disabledModalRef = useRef(disabledModals);
    disabledModalRef.current = disabledModals;

    const viewedModalsRef = useRef(viewedModals);
    viewedModalsRef.current = viewedModals;

    const userPromiseRef = useRef<() => void>(() => null);

    const modalConfigRef = useRef<ModalConfig>({
        name: 'sample-name',
        cta: 'CTA goes here',
        acceptMessage: 'accept',
        declineMessage: 'decline',
        checkboxMessage: 'permanenetly disable the modal',
        onAccept: () => null,
    });

    const triggerModal = useCallback(async function(config: ModalConfig | null): Promise<void> {
        if (!config) {
            Logger.log('triggerModal: no config');
            return;
        }

        const isDisabled = disabledModalRef.current[config.name] || viewedModalsRef.current[config.name] || false;

        if (isDisabled) {
            Logger.log('triggerModal: isDisabled');
            return;
        }

        setIsVisible(true);
        modalConfigRef.current = config;

        return new Promise(res => {
            userPromiseRef.current = res;
        });
    }, []);

    const onClose = useCallback((shouldDisable: boolean): void => {
        const name = modalConfigRef.current.name;
        const disableLocked = modalConfigRef.current?.cannotBeDisabled || false;

        setIsVisible(false);

        if (disableLocked) {
            return;
        }

        setViewedModals(prev => ({
            ...prev,
            [name]: true
        }));

        if (shouldDisable) {
            setDisabledModals((prev) => {
                const result = {
                    ...prev,
                    [name]: true,
                };

                saveSettings(result);

                return result;
            });
        }
    }, []);

    const onAccept = useCallback((shouldDisable: boolean): void => {
        onClose(shouldDisable);
        modalConfigRef.current.onAccept();
    }, [onClose]);

    const onDecline = useCallback((shouldDisable: boolean): void => {
        onClose(shouldDisable);
    }, [onClose]);

    return (
        <Context.Provider value={{ triggerModal }}>
            {isVisible && (
                <Modal
                    cta={modalConfigRef.current.cta}
                    acceptMessage={modalConfigRef.current.acceptMessage}
                    declineMessage={modalConfigRef.current.declineMessage}
                    checkboxMessage={modalConfigRef.current.checkboxMessage}
                    onAccept={onAccept}
                    onDecline={onDecline}
                />
            )}
            { children }
        </Context.Provider>
    );
}

interface ModalProps {
    cta: string;
    acceptMessage: string;
    declineMessage: string;
    checkboxMessage: string;
    onAccept(shouldPermanentlyDisable: boolean): void;
    onDecline(shouldPermanentlyDisable: boolean): void;
}

function Modal(props: ModalProps): React.ReactElement {
    const checkboxRef = useRef<HTMLInputElement>(null);
    const { isDarkTheme } = useAppSettings();

    function handleAccept(): void {
        const shouldDisable = checkboxRef.current?.checked || false;
        props.onAccept(shouldDisable);
    }

    function handleDecline(): void {
        const shouldDisable = checkboxRef.current?.checked || false;
        props.onDecline(shouldDisable);
    }

    const isDeclineButtonVisible = props.declineMessage?.length > 0;
    const isCheckboxVisible = props.checkboxMessage?.length > 0;

    return (
        <div className={Styles.modalContainer}>
            <section className={getThemedClassNameAlt(Styles.modalContent, isDarkTheme, Styles.modalContentDark)}>
                <p className={cx('mt-2')}>{props.cta}</p>
                <button className={cx(Styles.modalButton, 'button-1 mt-4')} onClick={handleAccept}>{props.acceptMessage}</button>

                { // the decline button
                    isDeclineButtonVisible && (
                        <button className={cx(Styles.modalButton, 'button-1 mt-4')} onClick={handleDecline}>{props.declineMessage}</button>
                    )
                }

                { // the checkbox
                    isCheckboxVisible && (
                        <div>
                            <label htmlFor="modal-checkbox" className={Styles.modalCheckbox}>
                                <input className="mr-2 mt-4" name="modal-checkbox" type="checkbox" ref={checkboxRef} id="modal-checkbox"/>
                                {props.checkboxMessage}
                            </label>
                        </div>
                    )
                }
            </section>
        </div>
    );
}
