import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { every } from 'lodash';

import { usdToEth } from './conversions';
import { getBankxInterest } from './liquidity';
import {
    mapToBigNumber,
    toBigIntString,
    toDecimalString,
} from './number';
import { systemCalculations } from './pool';
import { getDeadline } from './deadline';

export async function getCollateralRaised(collateralContract: ethers.Contract): Promise<string> {
    const DECIMALS = 18;
    const raised = await collateralContract.amountpaid();
    const result = toDecimalString(raised, DECIMALS);

    Logger.log('getCollateralRaised', result, raised);

    return result;
}

export interface CollateralInfo {
    amountRaised: number;
    bankxInterest: number;
    deficit: number;
    maxContribution: number;
    maxContributionInEth: number;
    vestingTime: string;
    xsdInterest: number;
}

export function getCollateralInfo(
    deficit: number,
    amountRaised: number,
    ethPriceInUsd: string,
): CollateralInfo {
    const ratio = Math.trunc(deficit / amountRaised * 100);
    const isValidRatio = every([!Number.isNaN(ratio), ratio !== 0]);

    if (!isValidRatio) {
        return {
            amountRaised,
            bankxInterest: 0,
            deficit,
            maxContribution: 0,
            maxContributionInEth: 0,
            vestingTime: '-',
            xsdInterest: 0,
        };
    }

    const maxContribution = deficit - amountRaised;
    const result = {
        amountRaised,
        bankxInterest: 0.07,
        deficit,
        maxContribution,
        maxContributionInEth: Number(usdToEth(String(maxContribution), ethPriceInUsd)),
        xsdInterest: 0.00,
        vestingTime: '3 weeks',
    };

    if (ratio > 2 / 3) {
        result.xsdInterest = .05;
        result.vestingTime = '1 week';
    } else if (ratio > 1 / 3) {
        result.xsdInterest = .02;
        result.vestingTime = '2 weeks';
    }

    return result;
}

interface NegativeCollateralRewardValues {
    bankx: string;
    bankxRoi: string;
    xsd: string;
    xsdRoi: string;
    totalRoi: number;
}

export function getRewardValues(
    inputAmount: string,
    ethPrice: string,
    bankxPrice: string,
    xsdPrice: string,
    collateralInfo: CollateralInfo,
    targetContractAddress: string,
): NegativeCollateralRewardValues {
    const bankxInterestRaw = getBankxInterest(targetContractAddress);
    const bankxRoi = '7%';
    const xsdRoi =  `${(collateralInfo.xsdInterest * 100).toFixed(0)}%`;
    const totalRoi = bankxInterestRaw + collateralInfo.xsdInterest;

    try {
        if (!inputAmount || isNaN(Number(inputAmount))) {
            throw Error('invalid input');
        }

        const decimals = 18;
        const {
            inputAmount: inputAmountD18,
            bankxPrice: bankxPriceD18,
            ethPrice: ethPriceD18,
            xsdPrice: xsdPriceD18,
            xsdInterest,
            bankxInterest,
            one: oneD18,
        } = mapToBigNumber({
            bankxInterest: String(bankxInterestRaw),
            bankxPrice,
            ethPrice,
            inputAmount,
            one: '1',
            xsdInterest: String(collateralInfo.xsdInterest),
            xsdPrice,
        }, decimals);

        const contributionInUsd = ethPriceD18.mul(inputAmountD18).div(oneD18);
        const bankx = contributionInUsd.mul(bankxInterest).div(bankxPriceD18);
        const xsd = contributionInUsd.mul(xsdInterest).div(xsdPriceD18);

        return {
            bankx: formatUnits(bankx, decimals),
            bankxRoi,
            xsd: formatUnits(xsd, decimals),
            xsdRoi,
            totalRoi,
        };

    } catch (e) {
        return {
            bankx: '-',
            bankxRoi,
            xsd: '-',
            xsdRoi,
            totalRoi: 0,
        };
    }
}

interface BuyBackBankxConfig {
    bankxInputAmount: string;
    expectedEth: string | null;
    collateralPoolContract: ethers.Contract;
    bankxTokenContract: ethers.Contract;
    pidContract: ethers.Contract;
    proxyContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;
    goToNextLoadingMessage(message?: string): void;
    chain: string;
    priceCheckBlockDelay: number;
}
export async function buyBackBankx(
    {
        bankxInputAmount,
        expectedEth,
        collateralPoolContract,
        bankxTokenContract,
        provider,
        goToNextLoadingMessage,
        pidContract,
        proxyContract,
        chain,
        priceCheckBlockDelay,
    }: BuyBackBankxConfig
): Promise<void> {
    if (!expectedEth) {
        return;
    }

    const signer = provider.getSigner();
    const decimals = 18;
    const eth = toBigIntString(expectedEth, decimals);
    const bankx = toBigIntString(bankxInputAmount, decimals);
    const deadline = await getDeadline(provider)

    Logger.log('buyBackBankx: values', bankxInputAmount, bankx, expectedEth, eth);
    Logger.log('buyBackBankx: address', bankxTokenContract.address, collateralPoolContract.address);
    // 17000 17000000000000000000000 0.2085444468 208544446800000000

    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay);

    goToNextLoadingMessage();
    const bankxReceipt = await bankxTokenContract.connect(signer).approve(collateralPoolContract.address, bankx);
    await bankxReceipt.wait(1);
    Logger.log('buyBackBankx: bankx receipt', bankxReceipt);

    goToNextLoadingMessage();
    const collateralReceipt = await collateralPoolContract.connect(signer).buyBackBankX(bankx, eth, deadline);
    await collateralReceipt.wait(1);

    goToNextLoadingMessage();
    Logger.log('buyBackBankx: collateral receipt', collateralReceipt);
}

interface BuyBackXSDConfig {
    xsdInputAmount: string;
    expectedEth: string | null;
    collateralPoolContract: ethers.Contract;
    xsdTokenContract: ethers.Contract;
    pidContract: ethers.Contract;
    proxyContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;
    goToNextLoadingMessage(message?: string): void;
    chain: string;
    priceCheckBlockDelay: number;
}
export async function buyBackXSD(
    {
        xsdInputAmount,
        expectedEth,
        collateralPoolContract,
        xsdTokenContract,
        provider,
        goToNextLoadingMessage,
        pidContract,
        proxyContract,
        chain,
        priceCheckBlockDelay,
    }: BuyBackXSDConfig
): Promise<void> {
    if (!expectedEth) {
        return;
    }

    const signer = provider.getSigner();
    const decimals = 18;
    const eth = toBigIntString(expectedEth, decimals);
    const xsd = toBigIntString(xsdInputAmount, decimals);
    const deadline = await getDeadline(provider)
    Logger.log('buyBackXSD: values', xsdInputAmount, xsd, expectedEth, eth);

    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay);

    goToNextLoadingMessage();
    const bankxReceipt = await xsdTokenContract.connect(signer).approve(collateralPoolContract.address, xsd);
    await bankxReceipt.wait(1);
    Logger.log('buyBackXSD: bankx receipt', bankxReceipt);

    goToNextLoadingMessage();
    const collateralReceipt = await collateralPoolContract.connect(signer).buyBackXSD(xsd, eth, deadline);
    await collateralReceipt.wait(1);

    goToNextLoadingMessage(CustomLoadingMessage.PositiveCollateral);
    Logger.log('buyBackXSD: collateral receipt', collateralReceipt);
}

export function calculateBuyBack(
    bankxInputAmount: string,
    tokenPriceInUsd: string,
    ethPriceInUsd: string,
): null | string {
    try {
        const asNumber = Number(bankxInputAmount);
        if (asNumber < 0 || !asNumber) {
            return null;
        }

        const decimals = 18;

        const {
            bankxInputAmount: inputD18,
            tokenPriceInUsd: tokenPriceD18,
            ethPriceInUsd: ethUsdD18,
        } = mapToBigNumber({
            bankxInputAmount,
            tokenPriceInUsd,
            ethPriceInUsd,
        }, decimals);

        const expectedEthD18 = inputD18.mul(tokenPriceD18).div(ethUsdD18);

        return formatUnits(expectedEthD18, decimals);
    } catch (e) {
        return null;
    }
}

interface AddCollateralConfig {
    inputAmount: string,
    provider: ethers.providers.Web3Provider,
    routerContract: ethers.Contract,
    pidContract: ethers.Contract,
    proxyContract: ethers.Contract,
    targetPoolAddress: string,
    walletAddress: string | null,
    goToNextLoadingMessage(message?: string): void,
    customLoadingMessage?: string;
    chain: string;
    priceCheckBlockDelay: number;
}
export async function addCollateral(
    {
        inputAmount,
        provider,
        routerContract,
        targetPoolAddress,
        walletAddress,
        goToNextLoadingMessage,
        customLoadingMessage,
        pidContract,
        proxyContract,
        chain,
        priceCheckBlockDelay,
    }: AddCollateralConfig
): Promise<void> {
    if (!inputAmount || !walletAddress) {
        return;
    }

    const decimals = 18;
    const input = toBigIntString(inputAmount, decimals);
    const signer = provider.getSigner();
    Logger.log('addCollateral: input', inputAmount, input, targetPoolAddress);

    // step 1 - price check
    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay);

    // step 2 - approval
    goToNextLoadingMessage();
    const deadline = await getDeadline(provider);
    const receipt = await routerContract.connect(signer).userAddLiquidityETH(targetPoolAddress, deadline, { value: input });

    // step 3 - wait
    Logger.log('addCollateral: receipt', receipt);
    goToNextLoadingMessage(customLoadingMessage);
    await receipt.wait(1);
}
