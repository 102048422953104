import { useGlobalValues } from 'context/ContractValuesProvider';
import { Context, ReferralsContext } from 'context/ReferralsProvider';
import { useCallback, useContext } from 'react';

export function useReferrals(): ReferralsContext {
    return useContext(Context);
}

export function getReferralLink(userAddress: string | undefined, route: string): string {
    if (!userAddress) {
        return '';
    }

    return `https://app.bankx.io/#/${route}?ref=${userAddress}`;
}

interface ReferralLink {
    link: string;
    getText(start: number, end?: number): string;
}
export function useGetReferralLink(route = 'xsd'): ReferralLink {
    const { walletValues } = useGlobalValues();

    const link = getReferralLink(walletValues.address, route);

    const getText = useCallback((trimLength: number, trimLengthEnd?: number): string => {
        const start = trimLength;
        const end = trimLengthEnd ?? trimLength;

        return `${link.substring(0, start)}...${link.substring(link.length - end)}`;
    }, [link]);

    return {
        link,
        getText
    };
}
