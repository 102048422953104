import { Storage } from 'helpers/storage';
import { debounce } from 'lodash';

const ARBITRARY_SETTINGS_KEY = '_skdo09823id';

export enum LoopingSettings {
    MaxGas = 'maxGas',
    MaxSlippage = 'maxSlippage',
    MaxXSD = 'maxXSD',
}

type Settings = Record<LoopingSettings, string>;

export function getStoredSettings(): Settings {
    try {
        const settings = JSON.parse(Storage.get(ARBITRARY_SETTINGS_KEY) || '{}');
        return {
            maxGas: settings.maxGas || '',
            maxSlippage: settings.maxSlippage || '',
            maxXSD: settings.maxXSD || '',
        };
    } catch {
        return {
            maxGas: '',
            maxSlippage: '',
            maxXSD: '',
        };
    }
}

export const saveSettingsToStorage = debounce((settings: Settings): void => {
    Storage.set(ARBITRARY_SETTINGS_KEY, JSON.stringify(settings));
}, 500, {
    leading: false,
    trailing: true
});
