import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { Logger } from 'helpers/logging';
import {
    addCommas,
    toAmount,
    toCurrency,
} from 'helpers/number';
import React, { useMemo } from 'react';
import ReactToolTip from 'react-tooltip';

import Styles from './styles.module.scss';

interface FormattedValueProps {
    isCurrency?: boolean;
    isLoading?: boolean;
    isPercentage?: boolean;
    isRaw?: boolean;
    precision?: number;
    suffix?: string;
    value: string | undefined;
    hasPlusMinus?: boolean;
    disableTooltip?: boolean;
    showDashOnEmptyString?: boolean; // when given an empty string, show a dash instead of "0"
    isDynamicPrecision?: boolean; // adjust to the min precision that shows a non-zero value; this will not adjust below the requested precision
}

const DISABLE_TOOLTIP = true;

export function FormattedValue({
    isCurrency,
    isLoading,
    isPercentage = false,
    precision: defaultPrecision = 2,
    isDynamicPrecision = true,
    suffix = '',
    value = '',
    hasPlusMinus = false,
    disableTooltip = false,
    isRaw = false,
    showDashOnEmptyString = false,
}: FormattedValueProps): React.ReactElement {
    const { isDarkTheme } = useAppSettings();

    const { areValuesLoading } = useGlobalValues();
    const isTooltipDisabled = useMemo((): boolean => {
        if (DISABLE_TOOLTIP) {
            return true;
        }

        const mantissa = value.split('.')[1];

        Logger.log('FormattedValue mantissa', mantissa, 'value', value);
        return disableTooltip || mantissa === undefined || mantissa.length <= 2;
    }, [disableTooltip, value]);

    let precision = defaultPrecision;

    if (isDynamicPrecision) {
        precision = Math.max(findMinNonZeroPrecision(value), defaultPrecision);
    }

    const config = { precision };
    const isDashValue = showDashOnEmptyString && value === '';
    let formatted = toAmount(value, config);

    if (isCurrency) {
        formatted = toCurrency(value, precision);
    }
    if (isDashValue) {
        formatted = '-';
    }
    if (isPercentage && !isDashValue) {
        formatted = toAmount(Number(value) * 100, { precision: 2 }) + '%';
    }

    const tooltip = (isCurrency ? '$' : '') + addCommas(value);
    const isInvalid = Number.isNaN(Number(value));

    if (isLoading || areValuesLoading) {
        return <img className={cx(Styles.loading)} src="/images/loading/ellipsis.svg" alt="loading" />;
    }

    if (isRaw) {
        return <span style={{ whiteSpace: 'pre' }}>{value}</span>;
    }

    if (isInvalid) {
        return <span>-</span>;
    }

    const plusMinusPrefix = hasPlusMinus && Number(value) > 0 ? '+' : '';

    // another instance of ReactToolTip can trigger the data-tip property
    if (isTooltipDisabled) {
        return <span>{formatted}{suffix}</span>;
    }
    return (
        <>
            <span data-tip={tooltip}>{plusMinusPrefix}{formatted}{suffix}</span>
            <ReactToolTip type={isDarkTheme ? 'light' : undefined}/>
        </>
    );
}

// return the precision required to get a non-zero value
function findMinNonZeroPrecision(value: string | number): number {
    const val = String(value).replaceAll(/[^0-9.]/g, '');
    const [whole, fractional] = val.split('.');

    if (Number(whole) > 0) {
        return 0;
    }

    let precision = 0;

    for (let i = 0; i <= fractional?.length || 0; i++) {
        if (fractional[i] !== '0') {
            precision = i + 1;
            break;
        }
    }

    Logger.debug(`findMinNonZeroPrecision: value=${value} whole=${whole} fractional=${fractional} precision=${precision}`);

    return precision;
}
