import { WalletState } from '@web3-onboard/core';
import { useConnectWallet } from '@web3-onboard/react';
import { useWeb3Context } from 'context/Web3Provider';
import {
    ChainIdsHex, Chains, CustomTokenLabels
} from 'helpers/chains';
import { identity } from 'lodash';
import { useMemo } from 'react';

interface WalletData {
    address: string | null;
    balance: string | null;
    chain: string | null;
    isConnected: boolean;
    state: WalletState | null;
    token: string;
    updateBalances(): void;
    priceCheckBlockDelay: number;
    tokenLabel: string;
}

export enum Token {
    Bnb = 'BNB',
    Eth = 'ETH',
    Avalanche = 'AVAX',
    Polygon = 'MATIC',
    Fantom = 'FTM',
}

function getToken(chain: string): string {
    switch(chain) {
        case ChainIdsHex.BinanceSmartChain:
        case ChainIdsHex.BinanceTestnet:
            return Token.Bnb;

        case ChainIdsHex.Avalanche:
            return Token.Avalanche;

        case ChainIdsHex.PolygonMainnet:
            return Token.Polygon;

        case ChainIdsHex.Fantom:
            return Token.Fantom;

        case ChainIdsHex.EthereumMainnet:
        case ChainIdsHex.Goerli:
        case ChainIdsHex.Kovan:
        case ChainIdsHex.Sepolia:
        case ChainIdsHex.Rinkeby:
        case ChainIdsHex.ArbitrumOne:
        case ChainIdsHex.OptimismMainnet:
        default:
            return Token.Eth;
    }
}

const defaultValues: WalletData = {
    address: '',
    balance: '0',
    chain: null,
    isConnected: false,
    state: null,
    token: '',
    updateBalances: identity,
    priceCheckBlockDelay: 3,
    tokenLabel: '',
};

function getPriceCheckBlockDelay(chain: string | null): number {
    switch(chain) {
        case ChainIdsHex.Avalanche:
        case ChainIdsHex.Base:
        case ChainIdsHex.Fantom:
            return 5;
        case ChainIdsHex.ArbitrumOne:
        case ChainIdsHex.BinanceSmartChain:
        case ChainIdsHex.PolygonMainnet:
            return 4;
        default:
            return 3;
    }
}

export function useWallet(): WalletData {
    const connectedWallet = useConnectWallet();
    const [{ wallet }] = connectedWallet;
    const { isReady } = useWeb3Context();

    const chain = wallet?.chains?.[0]?.id || null;
    const token = getToken(chain as string);
    const balance = wallet?.accounts?.[0]?.balance?.[token] || null;
    const updateBalances = connectedWallet[3];
    const priceCheckBlockDelay = getPriceCheckBlockDelay(chain);

    return useMemo((): WalletData => {

        if (!isReady) {
            return { ...defaultValues };
        }

        const chainIntId = parseInt(chain as string, 16);
        const chainMeta = Chains[chainIntId];
        const tokenLabel = CustomTokenLabels[chainIntId] || chainMeta?.token || '-';

        const result = {
            address: wallet?.accounts?.[0]?.address || null,
            balance,
            chain,
            isConnected: Boolean(wallet),
            state: wallet,
            token,
            updateBalances,
            priceCheckBlockDelay,
            tokenLabel,
        };

        return result;
    }, [
        balance,
        token,
        chain,
        wallet,
        updateBalances,
        isReady,
        priceCheckBlockDelay,
    ]);
}
