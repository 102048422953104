import React from 'react';

export function CalendarIcon(): React.ReactElement {
    return (
        <svg
            id="calendar"
            xmlns="http://www.w3.org/2000/svg"
            width="24.431"
            height="24.431"
            viewBox="0 0 24.431 24.431"
        >
            <g id="Group_608" data-name="Group 608">
                <g id="Group_607" data-name="Group 607">
                    <circle
                        id="Ellipse_8"
                        data-name="Ellipse 8"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(17 9)"
                        fill="#01303c"
                    />
                    <path
                        id="Path_131"
                        data-name="Path 131"
                        d="M20.614,1.909H19.373V.954a.954.954,0,1,0-1.909,0v.954H13.122V.954a.954.954,0,1,0-1.909,0v.954H6.919V.954a.954.954,0,1,0-1.909,0v.954H3.817A3.822,3.822,0,0,0,0,5.726V20.614a3.822,3.822,0,0,0,3.817,3.817h7.3a.954.954,0,0,0,0-1.909h-7.3a1.911,1.911,0,0,1-1.909-1.909V5.726A1.911,1.911,0,0,1,3.817,3.817H5.01v.954a.954.954,0,1,0,1.909,0V3.817h4.295v.954a.954.954,0,1,0,1.909,0V3.817h4.342v.954a.954.954,0,1,0,1.909,0V3.817h1.241a1.911,1.911,0,0,1,1.909,1.909v5.44a.954.954,0,0,0,1.909,0V5.726A3.822,3.822,0,0,0,20.614,1.909Z"
                        fill="#01303c"
                    />
                    <path
                        id="Path_132"
                        data-name="Path 132"
                        d="M275.774,270a5.774,5.774,0,1,0,5.774,5.774A5.78,5.78,0,0,0,275.774,270Zm0,9.639a3.865,3.865,0,1,1,3.865-3.865A3.869,3.869,0,0,1,275.774,279.639Z"
                        transform="translate(-257.116 -257.116)"
                        fill="#01303c"
                    />
                    <path
                        id="Path_133"
                        data-name="Path 133"
                        d="M373.338,331.956h-.429v-1a.954.954,0,0,0-1.909,0v1.956a.954.954,0,0,0,.954.954h1.384a.954.954,0,1,0,0-1.909Z"
                        transform="translate(-353.297 -314.253)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_9"
                        data-name="Ellipse 9"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(13 9)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_10"
                        data-name="Ellipse 10"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(9 13)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_11"
                        data-name="Ellipse 11"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(5 9)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_12"
                        data-name="Ellipse 12"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(5 13)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(5 17)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_14"
                        data-name="Ellipse 14"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(9 17)"
                        fill="#01303c"
                    />
                    <circle
                        id="Ellipse_15"
                        data-name="Ellipse 15"
                        cx="1"
                        cy="1"
                        r="1"
                        transform="translate(9 9)"
                        fill="#01303c"
                    />
                </g>
            </g>
        </svg>
    );
}
