import { size } from 'lodash';
import { useCallback, useState } from 'react';

interface CustomLoadingMessageUsage {
    isLoading: boolean;
    message: string;
    reset(numberOfSteps?: number): void;
    next(message?: string): void;
}

function createMessage(message: string, nextStep: number, totalSteps: number): string {
    const count = totalSteps > 1 ? `(${nextStep}/${totalSteps})` : '';

    return `${message} ${count}`;
}

interface State {
    message: string;
    totalSteps: number;
    currentStep: number;
}

export enum CustomLoadingMessage {
    AwaitingApproval = 'Awaiting Approval',
    AwaitingXSDApproval = 'Await XSD Approval',

    // NFT CD messages
    CertificateBankXApproval = 'Approving Lockup Contract',
    CertificateNFTApproval = ' Approving NFT Contract',
    CertificateApproveNFT = 'Approve NFT',
    Locking = 'Locking',

    Arbitrage = 'Swapping',
    Mint = 'Minting XSD',
    LiquidityWithdraw = 'Collecting Rewards',
    Liquidity = 'Selling Liquidity',
    NegativeCollateral = 'Adding Collateral',
    PositiveCollateral = 'Buying Collateral',
    Redeem = 'Collecting Rewards',
    Swap = 'Swapping',
    Recalculate = 'Calculating',
    MintingNFT = 'Minting',
    Claiming = 'Claiming',
    SystemsCalculations = 'System Calculations',
    PriceCheck = 'Price Check',

    SendingCollateral = 'Sending Collateral',
    ConfirmSendingXSD = 'Confirm Sending XSD',
    CompletingTransaction = 'Completing Transaction',
    CollectingRedemption = 'Collecting Redemption',
    ConfirmingSwap = 'Confirming Swap',
}

export function useCustomLoadingMessage(): CustomLoadingMessageUsage {
    const [state, setState] = useState<State>({
        message: '',
        totalSteps: 0,
        currentStep: 0,
    });

    // this function is called at the beginning and the end of the contract interaction
    // the call in the beginning of the contract interaction primes the total steps so it can show "{message} ({currentStep}/{totalSteps})"
    // the call at the end of the contract interaction will clear the custom loading message
    const reset = useCallback((numberOfSteps = 0): void => {
        setState({
            totalSteps: numberOfSteps,
            message: '',
            currentStep: 0
        });
    }, []);

    // Control the content of the custom loading message
    // The number of times "next" invoked must match the number passed into the "reset" function
    const next = useCallback((message = CustomLoadingMessage.AwaitingApproval): void => {
        setState((prev): State => {
            const nextStep = Math.min(prev.totalSteps, prev.currentStep + 1);

            return {
                ...prev,
                message: createMessage(message, nextStep, prev.totalSteps),
                currentStep: nextStep,
            };
        });
    }, []);

    const isLoading = size(state.message) > 0;

    return {
        isLoading,
        message: state.message,
        reset,
        next,
    };
}
