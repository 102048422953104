
import { ChainIdsHex, Chains } from 'helpers/chains';
import { Logger } from 'helpers/logging';

import { BinanceSmartChainAddresses } from './binance_smartchain';
import { BinanceTestnetAddresses } from './binance_testnet';
import { EthMainnetAddresses } from './ethereum_mainnet';
import { SepoliaAddresses } from './sepolia';
import { ArbitrumOneAddresses } from './arbitrum_one';
import { Contract } from './types';
import { PolygonAddresses } from './polygon_mainnet';
import { OptimismMainnetAddresses } from './optimism_mainnet';
import { AvalancheAddresses } from './avalanche';
import { FantomAddresses } from './fantom';
import { BaseAddresses } from './base';

export * from './types';

function _getAddress(chainId: string, contractName: keyof typeof Contract): string {
    switch(chainId) {
        case ChainIdsHex.BinanceTestnet:
            return BinanceTestnetAddresses[contractName] || '';

        case ChainIdsHex.BinanceSmartChain:
            return BinanceSmartChainAddresses[contractName] || '';

        case ChainIdsHex.EthereumMainnet:
            return EthMainnetAddresses[contractName] || '';

        case ChainIdsHex.ArbitrumOne:
            return ArbitrumOneAddresses[contractName] || '';

        case ChainIdsHex.PolygonMainnet:
            return PolygonAddresses[contractName] || '';

        case ChainIdsHex.OptimismMainnet:
            return OptimismMainnetAddresses[contractName] || '';

        case ChainIdsHex.Avalanche:
            return AvalancheAddresses[contractName] || '';

        case ChainIdsHex.Fantom:
            return FantomAddresses[contractName] || '';

        case ChainIdsHex.Base:
            return BaseAddresses[contractName] || '';

        case ChainIdsHex.Sepolia:
        default:
            return SepoliaAddresses[contractName] || '';
    }

}

export function getAddress(chainId: string, contractName: keyof typeof Contract): string {
    const result = _getAddress(chainId, contractName);
    Logger.debug('getAddress: [chainId, contractName, result]', chainId, contractName, result);

    return result;
}

export function getChainLabel(chainIdHex: string): string {
    const int = parseInt(chainIdHex, 16);

    return Chains[int]?.label || 'unknown';
}

function printInsertStatement(): void {
    const chains = [
        {
            alias: 'bsc-mainnet',
            chainHex: ChainIdsHex.BinanceSmartChain,
            addressMap: BinanceSmartChainAddresses
        },
        {
            alias: 'bsc-testnet',
            chainHex: ChainIdsHex.BinanceTestnet,
            addressMap: BinanceTestnetAddresses
        },
        {
            alias: 'eth-mainnet',
            chainHex: ChainIdsHex.EthereumMainnet,
            addressMap: EthMainnetAddresses
        },
        {
            alias: 'sepolia',
            chainHex: ChainIdsHex.Sepolia,
            addressMap: SepoliaAddresses
        },
        {
            alias: 'arbitrum-one',
            chainHex: ChainIdsHex.ArbitrumOne,
            addressMap: ArbitrumOneAddresses
        },
        {
            alias: 'polygon',
            chainHex: ChainIdsHex.PolygonMainnet,
            addressMap: PolygonAddresses
        },
        {
            alias: 'optimism',
            chainHex: ChainIdsHex.OptimismMainnet,
            addressMap: OptimismMainnetAddresses
        },
        {
            alias: 'avalanche',
            chainHex: ChainIdsHex.Avalanche,
            addressMap: AvalancheAddresses
        },
        {
            alias: 'fantom',
            chainHex: ChainIdsHex.Fantom,
            addressMap: FantomAddresses
        },
        {
            alias: 'base',
            chainHex: ChainIdsHex.Base,
            addressMap: BaseAddresses,
        }
    ];

    const inserts = chains.flatMap(chain => {
        return [
            `('xsdPool', '${chain.addressMap.XSDPool}', '${chain.chainHex}', current_timestamp, '${chain.alias}')`,
            `('xsdToken', '${chain.addressMap.XSDToken}', '${chain.chainHex}', current_timestamp, '${chain.alias}')`,
            `('router', '${chain.addressMap.RouterAddress}', '${chain.chainHex}', current_timestamp, '${chain.alias}')`,
        ]
    })

    const output = `
        with raw as (
            select * from (values ${inserts}) t(name, address, chain_id, created_at, notes)
        ), to_insert as (
            select raw.* from raw
            left join bankx.contracts c
                on c.address = raw.address
                and c.chain_id = raw.chain_id
            where c.id is null
        )
        insert into bankx.contracts (name, address, chain_id, created_at, notes)
        select * from to_insert;
    `;

    Logger.log(output.replace(/\s{2}/g, ''));
}

if (process.env.REACT_APP_PRINT_INSERTS === 'true') {
    printInsertStatement();
}
