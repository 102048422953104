import { AddressMap } from './types';

export const SepoliaAddresses: AddressMap = {
    BankXToken:           '0x7B624117242b1559B2BE4c85b928aCc38A55a8bb',
    XSDToken:             '0x64d5518a8816265c0cded66b1ac67f732476b1a3',
    BankXPool:            '0xE9096A40a1e1d7A3b4dbe86197f6Dff68351F4b3',
    XSDPool:              '0x9Bf54D597Bce7CB61b3F460780D57dF97c898a27',
    CollateralPool:       '0xF0438a30cF48e283809AFb008e5d0fbC7530C8E5',
    PIDController:        '0x1f6850c50BaBCD72E99A9D86124E9D55694Dc08f',
    RewardManager:        '0xd076d8E066372E2310FDF36bB6fDEaD173EDe1c4',
    RouterAddress:        '0x8Ea8D6054EbECc0d1C1BBf302c804fF38abfb270',
    Arbitrage:            '0x356De590145f6aC5cA735EA6934a53986c7ba3b4',
    CertificateOfDeposit: '0x145D359d906Be39F9813aCd7b0C77266F60dCF5C',
    BankXNFT:             '0xBBbE1e8f234cBB367B9Be4f844068e3253232029',
    NFTBonus:             '0xB817e4563F732df4d12b8939CE2A3ee94cE7819A',
};

// 11/15/2023 2054PST
// BankXToken address     0x7B624117242b1559B2BE4c85b928aCc38A55a8bb
// XSDToken address       0x64d5518a8816265c0cded66b1ac67f732476b1a3
// BankXPool address      0xE9096A40a1e1d7A3b4dbe86197f6Dff68351F4b3
// XSDPool address        0x9Bf54D597Bce7CB61b3F460780D57dF97c898a27
// CollateralPool address 0xF0438a30cF48e283809AFb008e5d0fbC7530C8E5
// PID controller address 0x1f6850c50BaBCD72E99A9D86124E9D55694Dc08f
// Reward Manager         0xd076d8E066372E2310FDF36bB6fDEaD173EDe1c4
// Router address         0x8Ea8D6054EbECc0d1C1BBf302c804fF38abfb270
// Arbitrage:             0x356De590145f6aC5cA735EA6934a53986c7ba3b4


// 11/09/2023 1228PST
// BankXToken address      0xB197d65089dBC863B68854f56f3bC7485617c9dB
// XSDToken address        0xA9946A2f0B56dC5Cd9c8ec773A20907e757027a3
// BankXPool address       0xe46c664c72030F1C46c837eC55F0590221E49297
// XSDPool address         0xFb241BA77b33e9CE86e37aB8d860d606F4b22Db5
// CollateralPool address  0xC15B7ABe0837F3859204C7dfee7d46DF5e8C53f9
// PID controller address  0xD91628f597a2657bAB5E35A40599eE89F682BCcF
// Reward Manager          0x1f99c9b96F8bd35E64750733B9E8523D8416712F
// Router address          0xfdC6434315CC99dc934e62AC9a9E4899aa05e864
// Arbitrage:              0x2981c888118A1BA91Ca096E1f834cfa6953361FF

// 11/02/2023 0613PDT
// BankXToken address      0x64a33a2E0439C078f128344F33Ff8fe4CD754a68
// XSDToken address        0x4f0b5072978C05D82E0E62F37261553383BebFB8
// BankXPool address       0x3A9eE94f0178d535E1132Edf6104F7EF3C2d79F7
// XSDPool address         0xc7869A0BA01FB2fF2f75aF84e98fCbBC190531b6
// CollateralPool address  0xb109Da3c7C33826270148277d274E7b77a662ea4
// PID controller address  0xFA85B84a0C90D52C056386cE474e4a61A08d5D4e
// Reward Manager          0x6B4628B733CaAE6EE9E5f3a414183Ee5F21BbEdd
// Router address          0x2B38EAb07d168fF6F906172e69266b746CC28f9e
// Arbitrage:              0xb878e9BF2047fC12B9dFb8F552Ee196a6E2D520c

// 06/29/2023 0829PDT
// BankXToken address      0x7aDdEcFEe1C3038Ba6742E04288c533D29B43490
// XSDToken address        0x5a8346014A275e27a756a13983C820E413De2D60
// BankXPool address       0x167c403AC07169Cc5aCda3E7325533a91173f1cc
// XSDPool address         0xaD7BE53403c479633dA5cdF9D00f4Be2E758FbdE
// CollateralPool address  0x8181f98a40007126Df42D95FE16CB956aAfd39F6
// PID controller address  0x64E080A413f1Fe5cf25960D019Ccb8A8e7F3239d
// Reward Manager          0xA28fE830cd1f18E86C03eeFD7B84B39793A64f3B
// Router address          0x4C8251af739cf3976BCF16C28ecC8dF63bC05EE9
// Arbitrage:              0x132d8CDFd83126Ba20b77A20D47dbe23565CB613

// 06/29/2023 0807PDT
// BankXToken address      0x03fFc2eDeAf012edd460E84FF5efad2Cad668218
// XSDToken address        0x59BAcC512aB505d5874EC5059F4eF2E4F3Cc1094
// BankXPool address       0xcfFDa9f4585b79d4d42359a30d095325b85Bf203
// XSDPool address         0x11FA966cFdd8e5A918279D757aA479077e801043
// CollateralPool address  0xD3Aff0cD3Db34660682388C43BfA7447AA2dB106
// PID controller address  0x07013A1cD337581546e1a36c5f35F5d3B22de2b5
// Reward Manager          0xA09767eeB70853Da0fcA113Aac1cd6Bfe844aCd3
// Router address          0xB7616Ddf0B20be86d14744FeEbdcA078c7136692
// Arbitrage:              0xCF108dFd587f98bb56b3d2497666Cb1778e91dA6

// 05/22/2023 1140PDT
// BankXToken address      0xDd80B8c7994a8d6773DA19c91fDE93e300bb491e
// XSDToken address        0x0ddc5fB26dAd1CbBf889fd78F57acC52B434e4D9
// BankXPool address       0xB0d225242FEE5d0EBAe36985d5C5c45C8b61421D
// XSDPool address         0x71A30E680bF9203aEfc94Beb75cc586D14c11482
// CollateralPool address  0xE42B1599Acb3C300Fc93e08B306a7ac631aA146B
// PID controller address  0x6BeE7a75c11FBf1cBfCF992d6e3216585bdb57ed
// Reward Manager          0xC8A610cdFa6d6BEeFCF516ee310498D7Bf0C79Bb
// Router address          0x5D6e317cD2482F9e2CF628c54aA63745E6c18234
// Arbitrage:              0xde4fb3C06E5f50cC762CF3eA6D5b61787fc26479
// CD address:             0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address:       0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address:       0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 05/06/2023 2234PDT
// BankXToken address      0xDd80B8c7994a8d6773DA19c91fDE93e300bb491e
// XSDToken address        0x0ddc5fB26dAd1CbBf889fd78F57acC52B434e4D9
// BankXPool address       0xB0d225242FEE5d0EBAe36985d5C5c45C8b61421D
// XSDPool address         0x71A30E680bF9203aEfc94Beb75cc586D14c11482
// CollateralPool address  0x6Eb1A1E779da11fA1C51613f9b8871Fc3Ab5ECe1
// PID controller address  0x6BeE7a75c11FBf1cBfCF992d6e3216585bdb57ed
// Reward Manager          0xC8A610cdFa6d6BEeFCF516ee310498D7Bf0C79Bb
// Router address          0x5D6e317cD2482F9e2CF628c54aA63745E6c18234
// Arbitrage:              0xde4fb3C06E5f50cC762CF3eA6D5b61787fc26479
// CD address:             0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address:       0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address:       0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 05/06/2023 2205PDT
// BankXToken address      0x5286D300aBe7597d849B181c852Eb43c85fe6B10
// XSDToken address        0xed9a09075FF122A621D5905A907fC895C33c46fB
// BankXPool address       0x3B503A3aA75D889e83c19b4e68FffE48C003e5bf
// XSDPool address         0x0D20B8fBD9F892693FCE17107Cf2940cd9F331D2
// CollateralPool address  0xac105F27fAAb047852E54694D6858CF9D3277f67
// PID controller address  0xA2489F83FdAF771A081377B226d93C4f6C62171a
// Reward Manager          0xF22FF542e57B47Cd423D414a13B8A47ca769404E
// Router address          0xC2Ee1A266B1488ce9Eb2a661fE531e8Fee3471D3
// Arbitrage:              0xE5A072d889971671ecaD0986A3316605d34d097d
// CD address:             0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address:       0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address:       0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 05/03/2023 0839PDT
// BankXToken address:     0x113eea4CA624A52CEC86b849698a1Bb6cB9AE69c
// XSDToken address:       0x69Cf3B77b8897Fb3097D8fdc9b22f8aa99313839
// BankXPool address:      0x38e1d7fA572d7768863F3F87C5A112f363A31e1a
// XSDPool address:        0x0583d0eE13A2f7e19c376f5f830A64F38Eb03298
// CollateralPool address: 0x9C238375038FE1613E066e398A8D849757E7F4Ef
// PID controller address: 0x5E5C3B378Dd9403aB5f44e0248A6301d597FFB43
// Reward Manager:         0x5f11647b42CCE16Ec47703ed7Dc5C7E23a08a69D
// Router address:         0x0d2eb6D27637491cd1D3de4B9C5d1f60CCDA8FA5
// Arbitrage:              0xCd2050ef24eb6C453b4B99c8814B93a06C946870
// CD address:             0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address:       0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address:       0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 04/28/2023 1717PDT
// BankXToken address:     0xF826CA1a3b25063d894f8Dd2a24c6c8ea53c48fe
// XSDToken address:       0x7199eCFC24ADe86137a3F7D6d86db6A2c8569b08
// BankXPool address:      0xc63Eb913C8097349e705D3E7350b1f05079992c3
// XSDPool address:        0xA23fd534919Fb2A7677018348E7Df44D124f5680
// CollateralPool address: 0x97Bdc1dFc278C8aa944e8869476A467672D1b131
// PIDcontroller address:  0x5136ffb281e964496206972fBc6DB6e262661aED
// Reward Manager:         0xef5C29D7bfce56365dECe9C2B4bcE15895E8bF72
// Router address:         0x009Cc8A8961c83EC4f71a701DB98924e315F7467
// Arbitrage:              0x928E13Ca22abAFd6924a2Cf15e0A2A68350bb3C3
// CD address              0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address        0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address        0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 04/23/2023 1034PDT
// BankXToken address      0x8453C6Dd3EE4A71Fd40188851F62975a925DdF3c
// XSDToken address        0x9eAA83655bEd105aDcb753e10A44aA09d7B4f51B
// BankXPool address       0x572A9C5fDAD718cff9Bd8F2b8159B40D352c6B70
// XSDPool address         0x81F1109ED51fC74fA123C0BE622dd4D4B4679704
// CollateralPool address  0x0bB7472D30a820fD058753d910a56230a3b2bCd0
// PID controller address  0x65b47d1C0cDA7E6f56D83635a396569Ec7c813e3
// Reward Manager          0x583D0645B127B1d6898871C447B1C5De35a6916F
// Router address          0x7385e58A02310D3A8780DCc318FAD2d130adf0f9
// Arbitrage:              0x66442B623BAa29BF6574c3f8E8C6ceAfFD224803
// CD address              0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address        0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address        0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 04/18/2023 1030PDT
// BankXToken address      0xc6364341D235264E884943d0a5326f611B4e3E82
// XSDToken address        0x507a9d0de4D96d1Ae14882e1694d5526B63F2d00
// BankXPool address       0x92E84af2f3299A3a41F8513149449618E2860d56
// XSDPool address         0x506291116202c8C0A381D5AD7FEea3788BA990d9
// CollateralPool address  0xf14aC925D081373Cf3c49d298031Bb23Dbc3fBa0
// PID controller address  0x244375D70C35Be9399Cf33049342E9eB7b45833C
// Reward Manager          0xDf88431717A5d8149B4C58515285148e1f7bD327
// Router address          0x071A8fe5e2a4CABaBcF7DD979407b9fCFa74b830
// Arbitrage:              0xe3F5C1C1b96DFf41f9A47CCC9CaEB199b9ef4A60
// CD address              0x145D359d906Be39F9813aCd7b0C77266F60dCF5C
// BankXNFT address        0xBBbE1e8f234cBB367B9Be4f844068e3253232029
// NFTBonus address        0xB817e4563F732df4d12b8939CE2A3ee94cE7819A

// 03/20/2023 2231PDT
// BankXToken address      0x9989a90f0B8B196E6923b46CE56D76072A9Aa1CE
// XSDToken address        0x231b8265eE5e4021dAd0c36cec7096226bf14933
// BankXPool address       0xa9D489207F5524cd3c75eb3eaA2b6312623FD285
// XSDPool address         0x2d021aB757B6626E734c3b47Ef5dB48f204D97D9
// CollateralPool address  0xF5ABbDB65d46DEA88992CF3F82E6c83F1D0b8B3F
// PID controller address  0xE112Df85fD4d9131627B2410FdA22A2B23393141
// Reward Manager          0xE0C6d316b90Db08Ffe0845ce00f753349AAA2B3A
// Router address          0x9e4f9bE208Ab54Ff0d49f7346fb53bDA0F94DaaB
// Arbitrage:              0x44bAEb0F2087470A00bF197D83730B92a52cbaeb
// CD address              0x21ff5bF0eAC611E47bC149388A8a6Ed405a20E0E
// BankXNFT address        0x67e9822c78178784B3809E374Db3f2536F41dc17
// NFTBonus address        0xD48029bf56C538b4ff6fcB7887FF01B72f01Fa71

// 03/20/2023 0854PDT
// BankXToken address      0x99e0C30f8209383Af2cfA2d04590ac066d04Ac97
// XSDToken address        0x99594ea6F97f0908D3Cdc2020d5cdF4fFCBeE394
// BankXPool address       0xF688343615aB6E83cd843ec9e7B9B2c2c6eb8762
// XSDPool address         0x629aC06293EC71746F2CE67592ffaFEE5F95EAf1
// CollateralPool address  0x395635F380eE80362b949e332bC0D04B270A50D0
// PID controller address  0xB6Bf2ee8EfF07141C19DAFa8586DbEd3130DD487
// Reward Manager          0x4324cf3dC64E98Eec5802d49c1081f76717D7E55
// Router address          0x848d18d2B4fc12112aC78Bce8E04A035B84a8970
// Arbitrage:              0x9a1153353d6690c24FCCe7dAc1f44b1d4D3475Ef
// CD address              0x21ff5bF0eAC611E47bC149388A8a6Ed405a20E0E
// BankXNFT address        0x67e9822c78178784B3809E374Db3f2536F41dc17
// NFTBonus address        0xD48029bf56C538b4ff6fcB7887FF01B72f01Fa71

// 03/17/2023 2247PDT
// BankXToken address      0xd3d496794D81A07C8611e9C3201BB4DaB03F3D41
// XSDToken address        0x5A9a17A0e2806Dbfaca8d96D37e55ACA688C780c
// BankXPool address       0x501625471C49544814547CA98CFCEFcDc797B2EF
// XSDPool address         0x8194566418A77A0926d88313e17492d1Ed27299E
// CollateralPool address  0x4d19713F94Ce396b4969570866F5415F8C2ea5BB
// PID controller address  0xf38c3d304cDD00356603e6D008AF05Dd8a1cE857
// Reward Manager          0x4b02EA3F6D5c03ea6430F588AD5B953cdAc03394
// Router address          0xa2AB8Dad2EcA642A0ceA5B656fFc208b11c53cAa
// Arbitrage:              0xDf9ffF4A18FDC0C4F693Faf45e5e2fEcA4381dEe

// 03/17/2023 2135PDT
// BankXToken address      0x5cf68FE0Cd4764ce109a1e95A4b70C5Ad39E1f92
// XSDToken address        0xC1eD6De4ED222273987813538741b1175C290A67
// BankXPool address       0x16851D63ef0A7Fd17F289cA22A4183A97b1584b8
// XSDPool address         0x6FCed8e9B481232e4D96e79fd49daFd8e6Da39B2
// CollateralPool address  0x312c945DF318E92A2A54F7324bD2382a772dD5F4
// PID controller address  0x48573170b651CfE62D11BEd20609EddCb3B5f383
// Reward Manager          0xAD55430ebA1c6460aEC62EB28Ed9FcFc26c75593
// Router address          0x1A241F6E256Bc53FE308353a76F2EA3E916D7B5D
// Arbitrage:              0x70BE71ECAc2EC81377CAB8A6F12e3cF814CfFBF9

// 03/17/2023 0925PDT
// BankXToken address     0x35F9CEDC502A89973b825dB252F122007664eA55
// XSDToken address       0x9CaB5a9cda112d04c3Ff7959e8219dF601fc6939
// BankXPool address      0x7f4A4675FA6cfe2417773bd9611d0F3D6b70f25b
// XSDPool address        0x050cb4245815394C7018103FE88a5f4E5cd49Ac1
// CollateralPool address 0x3cfaf99827E6EaD22f56a5497a4B6285B63a8895
// PID controller address 0x7A5fe856a936149034Be29D2CecFFDd4E6E6f4D6
// Reward Manager         0x049682142E8823FD26000A18f2ac6bEf86656a38
// Router address         0xf8a9f064Dd945640AB3E90A3b4C6d6A66D06A84D
// Arbitrage:             0x0197C8CdD3d1DD3f2d099463A0FD7f1fCe9A36A0
// CD address             0x21ff5bF0eAC611E47bC149388A8a6Ed405a20E0E
// BankXNFT address       0x67e9822c78178784B3809E374Db3f2536F41dc17
// NFTBonus address       0xD48029bf56C538b4ff6fcB7887FF01B72f01Fa71

// 03/16/2023 2109PDT
// BankXToken address     0x35F9CEDC502A89973b825dB252F122007664eA55
// XSDToken address       0x9CaB5a9cda112d04c3Ff7959e8219dF601fc6939
// BankXPool address      0x7f4A4675FA6cfe2417773bd9611d0F3D6b70f25b
// XSDPool address        0x050cb4245815394C7018103FE88a5f4E5cd49Ac1
// CollateralPool address 0x3cfaf99827E6EaD22f56a5497a4B6285B63a8895
// PID controller address 0x7A5fe856a936149034Be29D2CecFFDd4E6E6f4D6
// Reward Manager         0x049682142E8823FD26000A18f2ac6bEf86656a38
// Router address         0xf8a9f064Dd945640AB3E90A3b4C6d6A66D06A84D
// Arbitrage:             0x0197C8CdD3d1DD3f2d099463A0FD7f1fCe9A36A0

// 03/14/2023 2104PDT
// BankXToken address     0xfB51F8560C1fa9834B6eF59eE28339B65A1824Fc
// XSDToken address       0x62b005619E4F420fd6C6d5B95cA6b09892F6FB42
// BankXPool address      0x828BbC01861bB64cDE5F4b84E8896Ca3bC5E2Ec4
// XSDPool address        0xAF96605F7046Fd5E7b11949ab4196745927ebeCc
// CollateralPool         address 0x77AD18dC49aC6129297FD112e2511502729038bf
// PID controller         address 0xeee9629828910bFe1dCe721AAf6c94ffc02370C6
// Reward Manager         0x26dCc552401882D78Eb05630d5345d52298101d5
// Router address         0x3b29e78e070Ce42550bC932EDA4BB555c3FF9e30
// Arbitrage:             0x9fB7204beeC7705C9fC5C94602eB629c5C56E41f

// 03/13/2023 2351PDT
// BankXToken address     0x41145c667Cb725617dE64926c2EDF24e1630A61b
// XSDToken address       0x38DDEEFfa480C2fB906Bbd962A7FBdff0086E917
// BankXPool address      0xa59dBb07ecccE5b2d88dBA3C36c019abc90ea853
// XSDPool address        0xB32068F7eEE8F5774C5E267B44dbCAcbbE4A793E
// CollateralPool         address 0x9dfd7051baE68544456bA8DB96113E56e1aAdc6e
// PID controller         address 0x98882c4bb67ce1224A597d77C0971f81C774c837
// Reward Manager         0xaFcB86220D9A2B013ac5399A0c14817A9E6B8DE6
// Router address         0xf6Ce72C9ce02Cf3515C3cE9576Faa31d3C6f7E11
// Arbitrage:             0x528C983503D529723F9893336c956233761E1BaA

// 03/13/2023 2136PDT
// BankXToken address     0xe5339D407C1Bb02795ed89D13BfE5011AE48Ed77
// XSDToken address       0x79C1Eb2AbB41cb7e5B4F9f10207dc965aC35c157
// BankXPool address      0x6cFb2d82AA9E6Da501026e67Ae3954584991def0
// XSDPool address        0x429f937ACa96c85E0c79f2Acca4cC4f58ea30916
// CollateralPool         address 0x2704F7fa45F152D832036d6548D46171b2f20b0b
// PID controller         address 0x8884AC3165D738242BaFD3541010037B214393f8
// Reward Manager         0xD31d67801f0eF94B233c3438f4C8EB03c90e362a
// Router address         0x18F15b554a42A8be85eCD44C5d781FA996fF7686
// Arbitrage:             0xD513b8099E0C4B707733BB43972788534393F5c4

// 02/27/2023 0847PST
// BankXToken address     0x62430AdbBCBAEc445d0b5a60e5b09a9ec7eEbbDc
// XSDToken address       0x8AB52D2665F76dB274B3F7c93a9FB39927D4CcBf
// BankXPool address      0x731d52f6D7DDAEb38c9aC3919BF0B6e2D6d92fFC
// XSDPool address        0x53aBdA57c19Cb0c82d6E7fcaB417950d9cD18C60
// CollateralPool address 0x759d85626D92ddB0e1A1544410c58689C531b84A
// PID controller address 0x08ef4bb8E9b956bB0731A2b611Fc105B37b0E48E
// Reward Manager         0x963CcB438409Cf7Fe05d5Fb63d231ED041CA44Da
// Router address         0x802BD4343fdf7888cBD22696f63D0dC0cBA262c9
// Arbitrage:             0x6705b3d32Fe6E4cf7E67b6A56F59e7C925b75D87
// CD:                    0x71e3E0B4fc4F7C2a1334A1433352A821A8Ec8B33
// BankXNFT:              0x9123ffe49B31200925e385735E9419B366fe0a48
// NFTBonus address       0x49E5dcc1952eD686a72c725538d72a666DD44f8A [did not get new address for this contract]

// 02/15/2023 2117PST
// BankXToken address     0xF74c0b3Cb5B84dDC9b9D12bc0c2ff10116912910
// XSDToken address       0xB1eaA493381F2Be63E53c1D388Ba5D054b4D9aEc
// BankXPool address      0xbD9D112b49b932FA1389b854882521202c62eA8e
// XSDPool address        0x1AC1eDe7F248355136b8be8f4639346B521B8508
// CollateralPool         address 0x88C477a7a1Cc2aD1faf17Abe7e1a9B834AC7B93D
// PID controller         address 0x87c81B2bE7385B839d7d078CD5bc8A58C0Ea1010
// Reward Manager         0x700c8c076361eA81EeB1044fb5E3960a2bD67F5f
// Router address         0x2Dc7000b20c6E6348184Ef01c87E127fa00F0c65
// Arbitrage:             0xB2dDB2FA68eE9d5D673E64B9f1dB74b30E6D518D
// CD:                    0x71e3E0B4fc4F7C2a1334A1433352A821A8Ec8B33
// BankXNFT address       0x9123ffe49B31200925e385735E9419B366fe0a48
// NFTBonus address       0xc91271263De97e1aAe3FB27d3bd866E5699FfCA3

// 02/09/2023 2103PST
// BankXToken address     0x164f82fBB19aE7F7E24468264b962b3E55d2A591
// XSDToken address       0x7a71589498aF0B5aE68e1f97ECFA7BDC6203C1a5
// BankXPool address      0x3f63c52f7c6c1d3c95E36518C50A3984C6f28488
// XSDPool address        0xc4F8557F58a98655b751f3536d468c647eec76Fc
// CollateralPool address 0xE4056924a16BA9D1B912c20bCF8b015653d75d6d
// PID controller address 0x7EC05925fAaB4f855e5fBBbf2f08d9e9a571477e
// Reward Manager         0x276C4d56fB1AA222f315E932511A6DE5Cc64a3d2
// Router address         0x2B9f58227aA00188B3ed3e94fD2b3E6b9670b012
// Arbitrage:             0x2A9E1CCB7739804E68B5Fdd2469aBBC2B93858BB

// 02/01/2023 0753PT
// BankXToken address     0x7177E9cd8c0332329aA22C4F70F964f9BE58E9eb
// XSDToken address       0xD8882b730F53aC8078B3D2666038cafC4D7Fe76e
// BankXPool address      0x89701E1CD0D3E3EA01C7c2aeAA3a6Ad2c1a9B806
// XSDPool address        0x7d7B8A4c56aB9A3898Ec81c7459948cE011A8775
// CollateralPool address 0xD1e43aDa2dc72C8E36555489D296e4B17FEFeEcd
// PID controller address 0xa8c198009677d7D9863D642E5916c982D269D7AE
// Reward Manager         0xe2F83eF4aD4BBf5E678dDeFCC71641e37Fd2d51c
// Router address         0x0A09b14Ad9245a525545134D9ab88Ed0b25D37a2
// Arbitrage:             0x282faAa6Df063a4aC6739526AE96aAa354913C83

// 01/19/2023 0832PT
// BankXToken address     0x7F112120B0a69547c7390b735eB7Efc096647952
// XSDToken address       0x4109929a634C4BB8169A1981e419D0c036bc5541
// BankXPool address      0xDe8e68C8c58d073563C8A3464A9F6b05f22147b6
// XSDPool address        0xB15de4c6a4484AF6372a4f175C55745FfA91B5d3
// CollateralPool address 0x16944a916de7B9ea69799A93648354568244F5ce
// PID controller address 0x74f0AF903a707761E8c947A586753339aF292b49
// Reward Manager         0xe84cB23dFFD7C71addc68ab432D0858a1fC9422F
// Router address         0xbef3973FAc2A7cD29695B5D27093FF64e156cf35
// Arbitrage:             0xA605cfc94e519B8b70F73a3680a3227426d7381A

// 12/19/2022 0820 Sepolia
// BankXPool address      0x979DadBe8BA746926C8C05B3dF76539fF790A666
// BankXToken address     0x95f6d0D1DBDEC1e22b2b2B502B5e5B70c0f35B1C
// CollateralPool address 0xc5e53A88190A77Ad084cB04f88dAF1FcEA266EFe
// PID controller address 0xcbad37DD73D72650515516816D463554500f3C47
// Router address         0x77115865df3Ca1c1CeA8f733161DBb7706550b58
// XSDPool address        0x7436E02F243356f8D88433A91569130442B098b0
// XSDToken address       0x5795eD9369EeD94C8c884E034F7222D192bdBDF5
// Reward Manager         0xf31A4E7ac834FBdb91FC62BC91FbD18e5a2E034d

// 12/15/2022 Sepolia
// BankXPool              0x7763dDa6e1aa4A688EE01FFe4cB0C90dD45A3676
// BankXToken             0xcc6F2ed3B1159a5f26BCb6d29e743273389E3fD5
// CollateralPool         0x7e4826Cb412588700Dcf022f8E046b975C987Df3
// PIDController          0xbF43957C91d7d619E8ed98596a6E83a17C341De5
// RouterAddress          0xBE0BfF2B5d89443C89E98e7Ef7E0DeaA5b71633d
// XSDPool                0x31a71c0Cec0Ea70b4CA18cA3E8ab162831F6c1a9
// XSDToken               0xABC9EE34FB9db21E022D5fC39015F773AB5fe93B
// RewardManager          0xCEc8599577DaE0ed7Df8B9636CbD00b55C41A735
// CertificateOfDeposit   0xFa0D22EFd3c572C392FcD9e61f9195dC783c30ef

// 12/7/2022 0854 Sepolia
// BankXPool address      0xaF912B179d691f66d1700106f2b845d43dD52d66
// BankXToken address     0x6a819533F4f814729c3B8f63DF9E7c5b6Bf6Cf34
// CollateralPool address 0x58a4F26676f7e03deE97c2b7c24b688d361B5219
// PID controller address 0x4317c1F101A019528bC70d792702447E49Af77Ff
// Router address         0x339DeFaD8A4E8A2bC1222282E1c281002bF08e6a
// XSDPool address        0x12d2dEd91E11D80186c0FF7120fbb219da1cD4c4
// XSDToken address       0x21548CB5f223A7b4Cab065360E740499073A1817
// Reward Manager         0x269aCF2d7986A8A8c6cE982FA051555808864968

// 12/7/2022 0827PT Sepolia
// BankXPool address      0x0Ffa5c0812F2488a4e051CDBee4AF11ee51A38fE
// BankXToken address     0xFeE12a72ADE2A9bc8A17DF54b2a3C51f6B300dfc
// CollateralPool address 0x67b477009891011746EB2d6880C5C805B842b33A
// PID controller address 0xa25DCcff2407Fe07555454a2a321908fe72782e5
// Router address         0xBB145835B3940C327AD3F19c0B07Ddf7971F875e
// XSDPool address        0xbBbAC69bd90ce1d9EAA39D889B6f49b78996C4df
// XSDToken address       0x88AD15884fD8c692192C8cD992Ea3900ad723CD1
// Reward Manager         0x976bcC781cb4A2296aa75609c82Cd25eF0090c95

// 11/28/2022 0853PT Sepolia
// BankXPool address      0xb10491BA4399a74BBcF5e913205A2fAcE375822a
// BankXToken address     0x210a920B4f750254878E4aff1DA6981A4e7d3712
// CollateralPool address 0x4A40cF25C09Db909674d58c08Dd02e4B45c12077
// PID controller address 0xfE947E50e27D9d1FEDD6687E8C65A16842530739
// Router address         0xEC7207713De864D9F8A2BC5A0A46ed29C339eb2b
// XSDPool address        0x1CED2203C159af37A990BDcfF2b256521514bCD8
// XSDToken address       0xD9253Db1e4374c6c3261B9826ba40fE8EAb05Fab
// Reward Manager         0xC42D2fBBc3841aE996183A746e58D6E22f54BcDC

// 11/23/2022 0826PT Sepolia
// BankXPool address      0x1AD8cbf828662aDe4C0cc952868E82Aaff553A1D
// BankXToken address     0xcF977a7B846EAeC197E8602fACB535Db6AE72e8c
// CollateralPool address 0x38fC063DA7c640eb5CE108c429075B30e42fc7f1
// PID controller address 0x4670ceF71244BA3827a21E1870329DF301E57131
// Router address         0x4E5790cd349fC4843e9c5d9eF21e38F1C16e1308
// XSDPool address        0x00349A519F972Af540024fE1f73634F6e610e8Da
// XSDToken address       0x8840d6b114Cc0B4766ff418F47A92Cc0359D1345
// Reward Manager         0xbd05f70f64defE5024487a0D831450c0cD5Fb94e

// 11/18/2022 0859PT Sepolia
// BankXPool address      0xeef6DFa9091aDa7a5C0A2a3cd82Edf7845fA03b7
// BankXToken address     0xc393773a2Dc94cCA27EF97f23e7CB156e2aF630c
// CollateralPool address 0xBc5a59A98bbC43e3cA9889E4eDD59e0E85C318Da
// PID controller address 0xcaaD0D76Af209520D24b1f6531c613ADB4A69DaA
// Router address         0x38bf1Aa91d6aC199C571A2B80850d39b12B04674
// XSDPool address        0x86a74fB05715E973ee81fC0E631df3601f72414B
// XSDToken address       0x5e2ca861A0714D262C5F9fd5791FB33401AACc23
// Reward Manager         0x7E18B21c5f6295c9fF8F7cFE8CF27EED36257bf2

// 11/18/2022 0831PT Sepolia
// BankXPool address      0x4B75aD636807FEa83f472b14Bb549C49f52d4ae5
// BankXToken address     0x7ffDc33BAB8219b8711f40189A13e77c34c45202
// ethusd oracle:         N/A
// xagusd oracle:         N/A
// CollateralPool address 0x6Eb1c8e5acd80F41F29F5ec6f572615f74B08AEe
// PID controller address 0x416f39a59Fc3258a3633720aA76b256841FD91BB
// Router address         0xfADDa925e10d07430f5d7461689fd90d3D81bB48
// XSDPool address        0x225Fed0Ae5DEFDd2E76213C2E57043b728402A46
// XSDToken address       0x2c17859060511e4737b6CeA7104915bBE9D2d9b5
// Reward Manager         0x83819750E19EC166C223e0EB3C0Aa3618a3cD76D

// 11/16/2022 0808PT Sepolia
// BankXPool address      0x82B33FC877e46b2dF2bf7F3deF55e859F1b1BACf
// BankXToken address     0x64f4918bC357D9Cdf5c70b7E89C1C131575C8C20
// ethusd oracle:         N/A
// xagusd oracle:         N/A
// CollateralPool address 0xF453493EF4d2754f0D988392DA5f09C276e05Ff2
// PID controller address 0xc49aBc2bfa002e9e446f7C46a7cf48962411F199
// Router address         0xe455a6f2fCcD7020485f79c6660597Ec1C58A97B
// XSDPool address        0xE0b44F9A2B7585994Fe1a75912Fe6489d37eB664
// XSDToken address       0x7362Db9362763BB8eaffba2876904BdC5FE57cBd
// Reward Manager         0x1868de455eB102e084014ba2FE75F316480a3824
// WETH                   0xc12141D90480c861eB424079A4BcBCFf99Bd1174

// 11/09/2022 0839PT
// BankXPool address      0x7FA36b7F3493D74d11528368b2f3c3D90f8eb6f1
// BankXToken address     0x1Ea4AfCc23b77cFdeCB72d71e30Ff31B077C3D02
// ethusd oracle          0x5a6b7fb69b2BB85e434e94AF6c9EB0aA60020D4B
// xagusd oracle          N/A
// CollateralPool address 0x5285b7EE921ef7C82A824871feDb4E874f9B15C0
// PID controller address 0x5963B2449d82F422D9A15888E76E1EEA10bc429a
// Router address         0x76b4149EFdD25333323bF5395345966FE053c3Bc
// XSDPool address        0x9C7d0726DB76BBfc0b06d3eA1016Ec0751f8093C
// XSDToken address       0x59E458afB234291761B1cD07F72e40fA70D9C5bA

// 11/08/2022 2127PT
// BankXPool address      0x07b18ad933B0e846F465B1a83e19cD677B4d25BA
// BankXToken address     0x0e2914e304aa0605bD623848a9CC09CD4E912E76
// ethusd oracle          0xCc4c5CC90FAf89522B58F91c41e4D6B4D69a3509
// xagusd oracle          N/A
// CollateralPool address 0xbD3321176a02e6749F808eF4dbc92245661e7e16
// PID controller address 0x92957C3592890bcF594690ec05E30e6e3F297322
// Router address         0xCd286063368328c54F0Fbd9eE0bc1d82d6D66766
// XSDPool address        0xf96dBf5B485AE943a5D88144E80A0ceB17A9AA35
// XSDToken address       0xcBe094aba296Cd241c66129A8b8163a0b6e5e661

// 11/01/2022 2131PT
// BankXPool address      0x3D749D77B8904cfE6843273F33ebA734a46e74C2
// BankXToken address     0x4317c1F101A019528bC70d792702447E49Af77Ff
// ethusd oracle          0x21495beBFcd7ae1397b2986C143d91604f4A4E7A
// xagusd oracle          N/A
// CollateralPool address 0xa5F819488687164a4Cb9790990E7c34225557166
// PID controller address 0xd739DD4a331ec5C5E6Ddd1255F417e986FEE60Bc
// Router address         0x5c03eA64989CA3b57Be09DDeb60B9376d33FFb6f
// XSDPool address        0x2129Fad8daa9E92314049A23de80C664628445A7
// XSDToken address       0x339DeFaD8A4E8A2bC1222282E1c281002bF08e6a

// 10/25/2022 2115PT
// BankXPool address      0xf136973aDd04A6857aC2E1b6aB9169Ff0D947538
// BankXToken address     0x256b3Da233BaA8879F0e6F1bC7Ea4359D7AfD7FF
// ethusd oracle          0x2191C84eBA523dC58714346D9885deE6977E877b
// xagusd oracle          N/A
// CollateralPool address 0xa14b9BC8Da2bE79956265029400b347BEc068e19
// PID controller address 0xa35C7Ba9Cd975b1455495339B2898FC78C3d4c28
// Router address         0x9030146E07D645ceFc9749B0A31565C873e4cd03
// XSDPool address        0x64b97D652c159f09d2b97494105B55b4486cdf1F
// XSDToken address       0x8D0a6A11B9323fe19d419f9e54e42fa082151BBf

// 10/24/2022 0824PT
// BankXPool address      0x416f39a59Fc3258a3633720aA76b256841FD91BB
// BankXToken address     0x910e6E8BBea24cd8A6feEf40153298e03fceCdb8
// ethusd oracle          0xa27bCFa4137697544Ba89CCF044ea28497372095
// xagusd oracle          N/A
// CollateralPool address 0x2D473CDf43A8aF6024e5DB2ae78CC546E74D6A71
// PID controller address 0xE9F3EB24a300Ff4F6B9Baf97a3862a4b8EAc8b92
// Router address         0x04d18A90a0F34cc315D83638c3f9c8D223627493
// XSDPool address        0xfADDa925e10d07430f5d7461689fd90d3D81bB48
// XSDToken address       0x6Eb1c8e5acd80F41F29F5ec6f572615f74B08AEe

// 10/18/2022 2200PT
// BankXPool address      0x5930Cb54DE37905BB55A1Aaf9412e01EA396eB5a
// BankXToken address     0x544AD8A260c6E0966CddcB07B9e0BD603faC3eB0
// ethusd oracle          0xa2c31b708f426325904627E18BF998bb4F898728
// xagusd oracle          N/A
// CollateralPool address 0x0e900Cf0d2283d296ceef2e3fE9E91F2244DA78F
// PID controller address 0xeb058D640B2CB6E54402dC199c86Df52e27A2Ad9
// Router address         0xa954Dd5Ec26236f1EA2beE15dE2A2d89BC568645
// XSDPool address        0xAEd9AaC0633B10F5A2936A7aB91F878003D79c42
// XSDToken.address       0x6b4a2a3ef363d977e45C6DB603e60A8A58970d63

// 10/10/2022 0806PT
// BankXPool address      0xbFe870d31D5D509AfD694347da816C8C6c713f54
// BankXToken address     0x51B884865D1E979BCAaCf868052A6BbFbcB362a3
// ethusd oracle          0x6262b8fc9665463757fF1a3cC3ce4f150f232456
// xagusd oracle          N/A
// CollateralPool address 0xDF6937017310ae58418a4f3D6fe628a504138184
// PID controller address 0x6b78c60351eA5915d2C6B63c08087251f9FaE6a0
// Router address         0xAf8F680911A1DECbDb8412f0CCd34c73F2B91464
// XSDPool address        0xAb7d79F1DF09B5b1f8BEbcACb9c0724691463B98
// XSDToken address       0xDB49E54400019AcB3a943aC8b5f2bfa6595Bd0ae

// 10/03/2022 0812PT
// BankXPool address      0x285555864Bd3740CA89Ccc40382aF9e3F07F41d5
// BankXToken address     0xf6Ce72C9ce02Cf3515C3cE9576Faa31d3C6f7E11
// ethusd oracle          0x7c593Ef638685767Ee6DbF9d83D52d71Fd84C888
// xagusd oracle          0xfB5F82E7992a095a4A0024bF473f09839e7BA6F3
// CollateralPool address 0x88db19bA7D13D1599DACC02412Bbc48049A32139
// PID controller address 0x3388806f0C33388062868029CC3A49B1F92E854E
// Router address         0xc1dfE47F425f67a9EDFF17B883305989330EF250
// XSDPool address        0x20841153ACF74e510db242658DCcbE3474e5769d
// XSDToken address       0x528C983503D529723F9893336c956233761E1BaA

// 09/30/2022 0805PT
// BankXPool address      0xd79B3B7E2B04f219aDDF51313ab07C25B4383eCE
// BankXToken address     0x226a0C74838bAbbe13fF1373f050A6F6218E7192
// ethusd oracle          0x1e00D097dF30912645e9AEB2798b19CD5e6f2a01
// xagusd oracle          0xE064c999cA1886657A646a91F6B57E70953B33C1
// CollateralPool address 0x8D279FC733f81bD72c09Dc76a5d9eb1eB2388D0c
// PID controller address 0x9e6564404947a1362529E7e152899140dfE54d59
// Router address         0xE6b77311B582768aF71568A17E62DA663cc96815
// XSDPool address        0x6d6964c60E640Dd87DEF6a7C5142A5acBE5dB480
// XSDToken.address       0x607D8Ee3D88d9CDD2b5bCED41f33E571dbb317A3

// 9/20/2022 0804PT
// BankXPool address      0x35f91a74c8E02326A20D12B173fBA2B7BdB0E85f
// BankXToken address     0x5124823F2e6940E0468FfaC6650669E9fDa78Ec0
// ethusd oracle          0x4a2E0F6f00FA70Ae1a1Ae4344D686F0d59eeCE57
// xagusd oracle          0xca1aeeaA12D23125f3238Be0eE36D21C3E59FE1B
// CollateralPool address 0x4fD353d710C922ed925F720d48cf130b10A2040c
// PID controller address 0xd535193bfde70CFae7a7a05bE0b5f30b00a8e1f6
// Router address         0xCB9F469cc9715dCF301dDe29dfE64D121606f5eF
// XSDPool address        0x537F48FC9389eAf32128f5615a17eD0C6518c5b4
// XSDToken address       0x4e5F3a5824F0Bbc0B5ED84794963DA5BD26bBC61

// 9/9/2022 0813PT
// BankXPool address      0xCA2f2dC5DB3926782A0746a55297443e5F2FeC94
// BankXToken address     0xb3D4069fe66AAcB7849707c68BcBcCE6dB4431b7
// ethusd oracle          0xeB47Aec999670DEe857FAe6a75EeDFddBf19FE95
// xagusd oracle          0x574573fA83C08E27F298A2A7686D4196eB718633
// CollateralPool address 0x59CcECa6a8ff8Ff9E8970730569D11a6EDd4F831
// PID controller address 0xF74c0b3Cb5B84dDC9b9D12bc0c2ff10116912910
// Router address         0xB1eaA493381F2Be63E53c1D388Ba5D054b4D9aEc
// XSDPool address        0xebCe44f08365b001a726e47B0C42cB4BB94cd897
// XSDToken.address       0x232F9b4e728FDE7EE59e68F46DB9b49c8C66A098

// 9/7/2022 0816PT
// BankXPool address      0x7105E03162de5764C580DF743C36bf5F5F47AF48
// BankXToken address     0x282faAa6Df063a4aC6739526AE96aAa354913C83
// ethusd oracle          0xF0401f26fF826F5aD9a692C7C668C2CAbBA1711A
// xagusd oracle          0xA5a6CAA25F67427979d5deb1a21C802a43817E28
// CollateralPool address 0x9F84126c03F7422a014e68a03161f4e3395Ae14D
// PID controller address 0xF935a28bc11e4F127dBf456cBA72F0b00D457554
// Router address         0x64AbB5559D967c9bae792c704387eF19473f09b2
// XSDPool address        0xfE031E03D27ac77c2f4FC848F15F508EEAd9714a
// XSDToken.address       0x460B8a384839Be6B6d8e5A8F5ac19A43F0E1a553

// 8/31/2022 0802PT
// BankXPool address      0xb515E898E22428b3DC215274464dEe8AF59b6b8c
// BankXToken address     0x45368eC8092cDA6449851E56603EC8acB77F85f2
// ethusd oracle          0xa7276eD5689c98d3D35cBA5B043e729DaAadc837
// xagusd oracle          0xbce338e9Dbd9498a03d4f1b6B428C222443326cA
// CollateralPool address 0xA580401b64d1Ea8B498C6B10bd2F92eB18b60Ed0
// PID controller address 0x3668EFDAB84e09263cF443Bd7F2b951102C22f7B
// Router address         0xCBeE3cE9287d945Ffe60Dfa973421aeA2216173d
// XSDPool address        0xDc9B4b5e30C825Ea69202147C226D9082a2676Bc
// XSDToken.address       0x68065e7D35ebc456ea8Ce3BDF6c3c4A22C15c387

// 8/26/2022 0808PT
// BankXPool address      0x8344d08FcF349394759eaB238eb8606164f1e5fb
// BankXToken address     0x5963B2449d82F422D9A15888E76E1EEA10bc429a
// ethusd oracle          0xF30512Ce817294d5B1dE72A5e641596023c22a8f
// xagusd oracle          0x6Fce5B1a0254A46b41439613BB5AC4fCdc0A0C86
// CollateralPool address 0xb47AF1faEdbF8D0C765DC44D4860cfdB6D2CBF4d
// PID controller address 0x9017d247D9B89BaC4d2B50c2df89BA63d14108F7
// Router address         0x59EaF2462EfaAe6e1C149558Aa49fA0a7e89eC8B
// XSDPool address        0xE97051EdDedBC6f6aE2f5219A63c8a9C508f9Ea3
// XSDToken.address       0x76b4149EFdD25333323bF5395345966FE053c3Bc

// 8/22/2022 0816PT
// BankXPool address      0x454BC644D7A7C47bceD3f84B089D9ECdA89De028
// BankXToken address     0x64Ba790F9C49fb90AB3613124ba01117298EC133
// ethusd oracle          0x2d4643a70Dec42ed064468C70bf66550117952eb
// xagusd oracle          0x200cFc1F254c6Ab29A0e0575F8938df6e4c7aa41
// CollateralPool address 0xb2EB57477F8d2bB001D0cf4e9Cd45A393A084e0C
// PID controller address 0x8f72156B55354001F36F6C0F46e3e00Bf4F1FED6
// Router address         0x81faFf643AB0581Cd5959e65b7fF255164a8784e
// XSDPool address        0xd1de68Fe8cb875f994B364cD0820F519547DD7D4
// XSDToken.address       0xD3BCfdf15da886eEbcE4c5af9A698351400664d0

// 8/6/2022 0846 PT
// BankXWETHPool:         0x4Ef6f4f57E387048D064013018D8eD2E673Ae285
// BankXToken:            0x82a6405B9C38Eb1d012c7B06642dcb3D7792981B
// EthUsd Oracle:         0xA421782b5F28cb87140fcd1E4Ed829BcA495d067
// XagUsd Oracle:         0x9C000477fe38949B00A839CbD9c917A1Fe75b688
// CollateralPool:        0xaC23bE1b0Ffaa1dE214FaE738D1ADad167e13a79
// PID Controller:        0x34768e0B2D1d0FDe577eA432601253B05eaD453F
// Router:                0x2946Ca638EB11830E83Feaa130aA8B13d37C3772
// XSDWETHPool:           0x8C5f30FE72DD9DE6dc2c458F8b935e2D9F29c970
// XSDStableCoin:         0x37CAE90a88Bd9ae430a6A757CF21116D8F50a963
