import cx from 'classnames';
import React from 'react';

import Styles from './styles.module.scss';

interface LabelProps {
    className?: string;
    text: string;
}

export function Label(props: LabelProps): React.ReactElement {
    return (
        <div className={cx(Styles.label, props.className)}>{props.text}</div>
    );
}
