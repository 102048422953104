import './styles.scss';

import cx from 'classnames';
import { FormattedValue } from 'components/FormattedValue';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { Settings } from 'components/Settings';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import {
    getStoredSettings,
    LoopingSettings,
    saveSettingsToStorage,
} from 'helpers/looping';
import React from 'react';
import { useState } from 'react';

export function Looping() {
    const [info, setInfo] = useState(false);
    const { getThemedClass: tcx, isDarkTheme } = useAppSettings();
    const { mintInterestRate, walletValues } = useGlobalValues();

    const interestRateOutput = mintInterestRate ? `${Number(mintInterestRate) * 100}%` : '-';

    const [settings, setSettings] = useState(getStoredSettings());

    function onSettingsInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { name, value } = event.currentTarget;

        setSettings(prev => {
            const updated = {
                ...prev,
                [name]: value
            };

            saveSettingsToStorage(updated);

            return updated;
        });
    }

    return (
        <DashboardLayout>
            <div className={cx(tcx('content-wrapper'), tcx('redeem-page'))}>
                <TopCards />

                <div className={cx(tcx('main-card'), tcx('card-bg'))}>
                    <div className="row">
                        <div className="col-md-6 r-mb">
                            <div className={cx(tcx('white-card'), 'wc-1')}>
                                <Settings title='Looping'/>
                                <div className="wc-body">
                                    <div className="form-group with-max-btn">
                                        <input
                                            className={cx(tcx('form-control'), 'with-max-btn')}
                                            placeholder="Redeem"
                                            type="number"
                                        />
                                        <button className="button-1 max-btn">
                                            MAX
                                        </button>
                                    </div>
                                    <div
                                        className={tcx('side-info-box2')}
                                        style={{ marginBottom: '0' }}
                                    >
                                        <ul>
                                            <li>
                                                <label>{walletValues.tokenLabel} Available:</label>
                                                <FormattedValue value={walletValues.balance} />
                                            </li>
                                            <li>
                                                <label>BankX Available:</label>
                                                <FormattedValue value={walletValues.bankx} />
                                            </li>
                                            <li>
                                                <label>Minting Interest Rate: </label>
                                                <span>{interestRateOutput}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 r-mb">
                            <div className={cx(tcx('white-card'), 'wc-2')}>
                                <div className="wc-title space-between">
                                    {info ? (
                                        <>
                                            <span></span>
                                            <img
                                                src="/images/icon/close.svg"
                                                onClick={() => setInfo(false)}
                                                className="info-icon2"
                                                alt=""
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <h3>Status</h3>
                                            <img
                                                src="/images/icon/info.svg"
                                                onClick={() => setInfo(true)}
                                                className="info-icon2"
                                                alt=""
                                            />
                                        </>
                                    )}
                                </div>
                                {info ? (
                                    <>
                                        <div className="center-out" style={{ height: '100%' }}>
                                            <p
                                                className={tcx('info-text')}
                                                style={{ marginBottom: '8vh' }}
                                            >
                                                LOOPING multiplies your interest earnings WITHOUT THE
                                                RISK OF LIQUIDITION when you mint the XSD stablecoin.
                                                Start with collateral in your wallet. If you need BankX
                                                tokens, the system will automatically buy the amount you
                                                need to mint the stablecoin. This percentage is posted
                                                in the 4 system boxes above. The &quot;Collateral To Mint&quot;
                                                refers to the native blockchain token percentage needed
                                                (ETH or BNB) with the balance to 100% collateralization
                                                in BankX tokens. Next, the system will mint XSD which
                                                earns interest in the form of more BankX tokens for the
                                                entire time the XSD you mint is in circulation. Now that
                                                XSD has been minted and sent to your wallet, the system
                                                will automatically buy the collateral and BankX tokens
                                                you need to mint again. LOOP as many times as you like.
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={tcx('list-container')}>
                                            <div
                                                className={tcx('side-info-box2')}
                                                style={{ marginBottom: '0' }}
                                            >
                                                <ul>
                                                    <li>
                                                        <img src="/images/icon/check.svg" alt="" />
                                                        <span>Buy BankX if Needed</span>
                                                    </li>
                                                    <li>
                                                        <img src="/images/icon/arrow-cycle.svg" alt="" />
                                                        <span>Mint XSD</span>
                                                    </li>
                                                    <li>
                                                        <img src="/images/icon/hourglass.svg" alt="" />
                                                        <span>Buy Collateral</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={cx('row', tcx('row-status'))}>
                        <div className="col-md-6 r-mb">
                            <div className={cx(tcx( 'si-list'), 'max-list')}>
                                <ul>
                                    <li className="looping-settings__list-item">
                                        <span className="looping-settings__list-item-label">
                                            Max Slippage
                                        </span>
                                        <input
                                            className="looping-settings__input form-control"
                                            name={LoopingSettings.MaxSlippage}
                                            onChange={onSettingsInputChange}
                                            type="number"
                                            value={settings.maxSlippage}
                                        />
                                    </li>
                                    <li className="looping-settings__list-item">
                                        <span className="looping-settings__list-item-label">
                                            Max XSD off the PEG
                                        </span>
                                        <input
                                            className="looping-settings__input form-control"
                                            name={LoopingSettings.MaxXSD}
                                            onChange={onSettingsInputChange}
                                            type="number"
                                            value={settings.maxXSD}
                                        />
                                    </li>
                                    <li className="looping-settings__list-item">
                                        <span className="looping-settings__list-item-label">
                                            Max Gas Pay
                                        </span>
                                        <input
                                            className="looping-settings__input form-control"
                                            name={LoopingSettings.MaxGas}
                                            onChange={onSettingsInputChange}
                                            type="number"
                                            value={settings.maxGas}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 r-mb">
                            <div className={tcx('bu-wrap')}>
                                {isDarkTheme ? (
                                    <img
                                        className="looging-btn"
                                        src="/images/icon/looping-btn-dark.svg"
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        className="looging-btn"
                                        src="/images/icon/looping-btn.svg"
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
