import { useConnectWallet } from '@web3-onboard/react';
import cx from 'classnames';
import { ConnectButton } from 'components/ConnectButton';
import { CopyIcon, CopyText } from 'components/CopyText';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { PurchaseNFTBox } from 'components/PurchaseNFTBox';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { Logger } from 'helpers/logging';
import type { TNFT } from 'helpers/nft';
import {
    claimNFTRewards, getNFTLabel, getNFTsOwned
} from 'helpers/nft';
import { addCommas } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useGetReferralLink, useReferrals } from 'hooks/useReferrals';
import { useEffect, useState } from 'react';
import React from 'react';
import { default as ReactSelect } from 'react-select';

function NFTPage(): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();
    const [{ wallet }] = useConnectWallet();
    const { contracts, getProvider } = useWeb3Context();
    const { bankxNftContract, nftBonusContract } = contracts;
    const [ownedNFTs, setOwnedNFTs] = useState<TNFT[]>([]);
    const userAddress = wallet?.accounts[0].address;
    const { referrer: referrerAddress } = useReferrals();
    const { nftInfo } = useGlobalValues();
    const { totalSupply } = nftInfo || {};
    const [selectedNFTIndex, setSelectedNFTIndex] = useState<number>();
    const [isLoadingOwnedNFTs, setIsLoadingOwnedNFTs] = useState(true);
    const provider = getProvider();

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    useEffect(
        function () {
            if (!userAddress || typeof totalSupply === 'undefined') {
                return;
            }
            setIsLoadingOwnedNFTs(true);
            getNFTsOwned(
                userAddress,
                bankxNftContract,
                nftBonusContract,
                totalSupply,
                true
            )
                .then((ownedNftInfo) => {
                    setOwnedNFTs(ownedNftInfo);
                    setIsLoadingOwnedNFTs(false);
                })
                .catch((error) => {
                    setIsLoadingOwnedNFTs(false);
                    Logger.error('NFTPage: Error getting NFT info', { cause: error });
                });
        },
        [
            userAddress,
            bankxNftContract,
            nftBonusContract,
            totalSupply
        ]
    );

    const totalXSD = ownedNFTs.length
        ? ownedNFTs[0].rewardsAvailable + ownedNFTs[0].rewardsClaimed
        : 0;
    const currentRewardsAvailable =
    selectedNFTIndex !== undefined
        ? ownedNFTs[selectedNFTIndex].rewardsAvailable
        : 0;
    const referral = useGetReferralLink('buy-nft');

    Logger.log('NFTPage:', {
        currentRewardsAvailable,
        ownedNFTs,
        referrerAddress,
        totalSupply,
        selectedNFTIndex,
    });

    async function handleClaim() {
        if (selectedNFTIndex === undefined) {
            return;
        }
        try {
            resetLoadingMessages();
            await claimNFTRewards(
                ownedNFTs[selectedNFTIndex].id,
                bankxNftContract,
                provider,
                goToNextLoadingMessage
            );
        } catch (error) {
            resetLoadingMessages();
            Logger.error('NFTPage: Error claiming rewards', { cause: error });
            throw error;
        } finally {
            resetLoadingMessages();
            if (userAddress && totalSupply) {
                setIsLoadingOwnedNFTs(true);
                getNFTsOwned(
                    userAddress,
                    bankxNftContract,
                    nftBonusContract,
                    totalSupply,
                    true
                )
                    .then((ownedNftInfo) => {
                        setOwnedNFTs(ownedNftInfo);
                        setIsLoadingOwnedNFTs(false);
                    })
                    .catch((error) => {
                        setIsLoadingOwnedNFTs(false);
                        Logger.error('NFTPage: Error getting NFT info', { cause: error });
                    });
            }
        }
    }

    return (
        <DashboardLayout>
            <div className={cx('content-wrapper', tcx('nft-page'))}>
                <div
                    className={cx(tcx('main-card'), tcx('card-bg'))}
                    style={{
                        paddingBottom: 5,
                        paddingTop: 15,
                    }}
                >
                    <div className='row'>
                        <div className='col-md-5 curved-transition-container'>
                            <PurchaseNFTBox
                                className={tcx('curved-transition')}
                                userAddress={userAddress}
                                referrerAddress={referrerAddress}
                            />
                        </div>
                        <div className='col-md-7 r-mb mb-4'>
                            <div
                                className={tcx('white-card')}
                                style={{
                                    paddingBottom: 0,
                                }}
                            >
                                <div className={tcx('item')}>
                                    <h6
                                        style={{
                                            textAlign: 'left',
                                            color: '#1882c4',
                                            fontWeight: 'bold',
                                        }}
                                    >
                      At every $100M in stablecoin minted, each NFT pays you.
                                    </h6>
                                    <p className='info-card-paragraph'>
                      Exclusive to the first 5,000 NFT&apos;s, each NFT will be
                      paid 1,000 XSD&apos;s when the XSD stablecoin minted
                      reaches $100M. Thereafter, 200 XSD is paid to each NFT for
                      every $100M in stablecoin minted.
                                    </p>
                                    <h6
                                        style={{
                                            textAlign: 'left',
                                            color: '#1882c4',
                                            fontWeight: 'bold',
                                        }}
                                    >
                      Each NFT increases your Token Lockup Reward earnings:
                                    </h6>
                                    <p className='info-card-paragraph'>
                      Locking up 1-5 NFT’s per Token Lockup Rewards boosts your earnings.
                                        Each NFT increases your Token Lockup Rewards by 10%.
                      Maximum of 5 can be locked up per Token Lockup Reward.

                                    </p>
                                    <h6
                                        style={{
                                            textAlign: 'left',
                                            color: '#1882c4',
                                            fontWeight: 'bold',
                                        }}
                                    >
                      Private Telegram and Discord:
                                    </h6>
                                    <p className='info-card-paragraph'>
                      Access to BankX information before it is released to the
                      public.
                                    </p>
                                    {ownedNFTs.length && (
                                        <div
                                            className='info-card-paragraph bg-7'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                margin: '0 auto',
                                                textAlign: 'center',
                                                padding: '10px 15px',
                                                border: '1.5px solid #e85f5f',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: '.625rem',
                                                    overflowWrap: 'anywhere',
                                                }}
                                            >
                                                <b>Join The NFT Owners Private Group:</b>
                                                <br />
                                                <br />
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://t.me/+Gd2XLGksl1gzNzZh'
                                                >
                            https://t.me/+Gd2XLGksl1gzNzZh
                                                </a>
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '.625rem',
                                                    overflowWrap: 'anywhere',
                                                }}
                                            >
                                                <b>Follow Lance’s Twitter:</b>
                                                <br />
                                                <br />
                                                <a
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    href='https://twitter.com/lanceparkerio'
                                                >
                            https://twitter.com/lanceparkerio
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={cx('row', tcx('row-status'))}
                        style={{
                            position: 'relative',
                            paddingBottom: 5,
                            paddingTop: 5,
                        }}
                    >
                        <div className='col-md-6'>
                            <div
                                className={cx(tcx('si-list'), 'bg-7')}
                                style={{
                                    paddingBottom: 1,
                                    paddingTop: 12,
                                }}
                            >
                                <div
                                    className='wc-title'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        height: 30,
                                    }}
                                >
                                    <h3
                                        style={{
                                            fontSize: 'inherit',
                                            marginBottom: 0,
                                        }}
                                    >
                      Total XSD for Each NFT:
                                    </h3>
                                    <h3
                                        style={{
                                            fontSize: 'inherit',
                                            marginBottom: 0,
                                        }}
                                        className={cx(tcx('rewardNumber'), 'mr10')}
                                    >
                                        {addCommas(totalXSD.toString(), 0)}
                                    </h3>
                                </div>
                                <ul className='mr-2'>
                                    <div className='form-group'>
                                        {isLoadingOwnedNFTs ? (
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: '18px',
                                                        width: 'fit-content !important',
                                                        margin: '0 !important',
                                                    }}
                                                    src='/images/loading/ellipsis.svg'
                                                    alt='loading'
                                                />
                                                <span
                                                    style={{
                                                        paddingLeft: 8,
                                                        fontSize: 13,
                                                        color: 'black',
                                                        fontWeight: 600,
                                                    }}
                                                >
                            Fetching NFT’s
                                                </span>
                                            </div>
                                        ) : (
                                            <ReactSelect
                                                menuPlacement='top'
                                                options={ownedNFTs.map(
                                                    (nft: TNFT, index: number) => ({
                                                        value: index,
                                                        label: nft.id,
                                                    })
                                                )}
                                                hideSelectedOptions={false}
                                                onChange={(option) => {
                                                    setSelectedNFTIndex(option?.value || 0);
                                                }}
                                                getOptionLabel={(option) => getNFTLabel(option.label)}
                                                value={
                                                    selectedNFTIndex
                                                        ? {
                                                            value: selectedNFTIndex,
                                                            label: ownedNFTs[selectedNFTIndex].id,
                                                        }
                                                        : undefined
                                                }
                                                placeholder='Lockup NFT - Boost Rewards'
                                                className={tcx('selectDropdown')}
                                                styles={{
                                                    menuList: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            background: 'white',
                                                        };
                                                    },

                                                    indicatorsContainer: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            background: '#38436414',
                                                            color: '#01303c',
                                                            width: 60,
                                                            paddingLeft: 12,
                                                            borderTopRightRadius: 9,
                                                            borderBottomRightRadius: 9,
                                                        };
                                                    },
                                                    dropdownIndicator: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            color: '#01303c',
                                                        };
                                                    },
                                                    indicatorSeparator: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            display: 'none',
                                                        };
                                                    },
                                                    option: (baseStyles, { isFocused }) => {
                                                        return {
                                                            ...baseStyles,
                                                            background: isFocused
                                                                ? 'rgb(24, 130, 197)'
                                                                : 'white',
                                                            color: 'rgb(33, 37, 41)',
                                                        };
                                                    },
                                                    multiValueLabel: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            marginBottom: 0,
                                                        };
                                                    },
                                                    control: (baseStyles, { isFocused }) => {
                                                        return {
                                                            ...baseStyles,
                                                            ...(isFocused && {
                                                                boxShadow:
                                    '0 0 0 0.2rem rgb(0 123 255 / 25%)',
                                                                border: 'none',
                                                            }),
                                                            borderRadius: 9,
                                                        };
                                                    },
                                                    placeholder: (baseStyles) => {
                                                        return {
                                                            ...baseStyles,
                                                            paddingLeft: 20,
                                                            color: '#283280',
                                                            opacity: 0.4,
                                                        };
                                                    },
                                                }}
                                            />
                                        )}
                                    </div>

                                    {typeof selectedNFTIndex === 'number' && (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#1882c4',
                                                        marginBottom: 0,
                                                    }}
                                                >
                            XSD Paid:
                                                </h5>
                                                <h5 className={tcx('rewardNumber')}>
                                                    {ownedNFTs?.[selectedNFTIndex]?.rewardsClaimed ??
                              '-'}
                                                </h5>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        color: '#1882c4',
                                                        marginBottom: 0,
                                                    }}
                                                >
                            Unclaimed XSD:
                                                </h5>

                                                <h5 className={tcx('rewardNumber')}>
                                                    {ownedNFTs?.[selectedNFTIndex]?.rewardsAvailable ??
                              '-'}
                                                </h5>
                                            </div>
                                        </>
                                    )}
                                    <div className='form-group' style={{ marginTop: 6 }}>
                                        <ConnectButton
                                            className='button-1'
                                            disabled={
                                                isLoading ||
                          !currentRewardsAvailable ||
                          typeof selectedNFTIndex === 'undefined'
                                            }
                                            onClick={handleClaim}
                                            customLoadingMessage={customLoadingMessage}
                                        >
                        Claim
                                        </ConnectButton>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={tcx('bu-wrap')}>
                                <h5 className='referral-program-header'>Referral Program:</h5>
                                <p className='referral-program-body'>
                    Get 10% of the NFT sales for referring people who click on
                    your unique referral link. Connect your wallet to get your
                    referral link. Your commission will be sent directly to this
                    address after each of your referrals purchases.
                                </p>
                                {userAddress && (
                                    <p className='referral-program-link'>
                      Your Referral Link:
                                        <br />
                                        <CopyText text={referral.link}>
                                            {referral.getText(12)} <CopyIcon />
                                        </CopyText>
                                    </p>
                                )}
                                {!userAddress && (
                                    <p>Connect your wallet to get your referral link.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default NFTPage;
