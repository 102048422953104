import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import ArbitrageABI from 'contracts/ArbitrageABI.json';
import AvalancheChainlinkXAGUSDPriceConsumerABI from 'contracts/AvalancheChainlinkXAGUSDPriceConsumerABI.json';
import AvalancheCollateralPoolABI from 'contracts/AvalancheCollateralPoolABI.json';
import AvalanchePIDABI from 'contracts/AvalanchePIDABI.json';
import ProxyABI from 'contracts/AvalancheProxyABI.json';
import AvalancheRouterABI from 'contracts/AvalancheRouterABI.json';
import BankXNFTABI from 'contracts/BankXNFTABI.json';
import BankxPoolABI from 'contracts/BankXPoolABI.json';
import BankXTokenABI from 'contracts/BankXTokenABI.json';
import CDABI from 'contracts/CDABI.json';
import CollateralPoolABI from 'contracts/CollateralPoolABI.json';
import NFTBonusABI from 'contracts/NFTBonusABI.json';
import PidABI from 'contracts/PIDABI.json';
import RewardManagerABI from 'contracts/RewardManager.json';
import RouterABI from 'contracts/RouterABI.json';
import XSDPoolABI from 'contracts/XSDPoolABI.json';
import XSDStablecoinABI from 'contracts/XSDStablecoinABI.json';
import { ethers } from 'ethers';
import {
    Contract,
    ContractName,
    getAddress,
    getChainLabel,
} from 'helpers/addresses';
import { ChainIdsHex, getAvailableChainIdsHex } from 'helpers/chains';
import { Logger } from 'helpers/logging';
import { map, reduce } from 'lodash';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

const allowedChainIdsHex = getAvailableChainIdsHex();

const abiMapping = {
    [Contract.XSDToken]: XSDStablecoinABI,
    [Contract.XSDPool]: XSDPoolABI,
    [Contract.CollateralPool]: CollateralPoolABI,
    [Contract.BankXToken]: BankXTokenABI,
    [Contract.PIDController]: PidABI,
    [Contract.RouterAddress]: RouterABI,
    [Contract.BankXPool]: BankxPoolABI,
    [Contract.RewardManager]: RewardManagerABI,
    [Contract.CertificateOfDeposit]: CDABI,
    [Contract.Arbitrage]: ArbitrageABI,
    [Contract.NFTBonus]: NFTBonusABI,
    [Contract.BankXNFT]: BankXNFTABI,
    [Contract.XAGUSDPriceConsumer]: AvalancheChainlinkXAGUSDPriceConsumerABI,
};

const abiMappingWithPythSupport = {
    ...abiMapping,
    [Contract.CollateralPool]: AvalancheCollateralPoolABI,
    [Contract.PIDController]: AvalanchePIDABI,
    [Contract.RouterAddress]: AvalancheRouterABI,
    [Contract.XAGUSDPriceConsumer]: AvalancheChainlinkXAGUSDPriceConsumerABI,
    [Contract.Proxy]: ProxyABI,
};

function getAbiMapping(chain: string): Record<string, any> {
    switch(chain) {
        case ChainIdsHex.Avalanche:
        case ChainIdsHex.Fantom:
        case ChainIdsHex.Base:
            return abiMappingWithPythSupport;
        default:
            return abiMapping;
    }
}

type ContractMap = Record<ContractName, ethers.Contract>;
interface Web3State {
    isWalletConnected: boolean;
}
interface Web3Context extends Web3State {
    contracts: ContractMap;
    getProvider(): ethers.providers.Web3Provider;
    isReady: boolean;
    logContracts(log: string): void;
}

const Context = createContext<Web3Context>({
    getContract: null,
    getProvider: null,
} as unknown as Web3Context);

export function Web3Provider({
    children,
}: React.PropsWithChildren): React.ReactElement {
    const [isReady, setIsReady] = useState(false);
    const [{ wallet }] = useConnectWallet();
    const chain = wallet?.chains?.[0]?.id || '';

    const chainRef = useRef(chain);
    const providerRef = useRef<ethers.providers.Web3Provider | null>(null);

    const getProvider = useCallback((): ethers.providers.Web3Provider => {
        if (providerRef.current && chainRef.current === chain) {
            return providerRef.current;
        }

        const provider = window.ethereum && new ethers.providers.Web3Provider(window.ethereum);
        providerRef.current = provider;
        chainRef.current = chain;
        return provider;
    }, [chain]);

    const contracts = useMemo((): ContractMap => {
        const labels = ContractName as Record<Contract, ContractName>;
        const mapping = getAbiMapping(chain);
        const result = reduce(mapping, (memo: Partial<ContractMap>, abi, contractName): Partial<ContractMap> => {
            const provider = getProvider();
            const address = getAddress(chain, contractName as Contract);
            const contract = new ethers.Contract(address, abi, provider);

            // for convenience to avoid renaming (e.g. `BankXToken as bankxContract`)
            const name = labels[contractName as Contract];

            memo[name] = contract;
            return memo;
        }, {});

        return result as ContractMap;
    }, [chain, getProvider]);

    const [{ connectedChain }, setChain] = useSetChain();

    useEffect((): void => {
        if (connectedChain === null) {
            setIsReady(false);
            return;
        }
        const isAllowed = allowedChainIdsHex.includes(connectedChain.id);
        Logger.log('Web3Provider: chain is', {
            available: allowedChainIdsHex,
            current: connectedChain.id,
            isAllowed,
        });

        if (!isAllowed) {
            Logger.log('Web3Provider: trigger');
            setIsReady(false);
            // setChain({ chainId: allowedChainIdsHex[0] });
        } else {
            setIsReady(true);
        }
    }, [connectedChain, setChain]);

    const logContracts = useCallback((location: string): void => {
        const addresses = map(contracts, (c, name): [string, string] => {
            return [name, c.address];
        });

        const label = getChainLabel(chain);
        Logger.log(`${location}: ${label} addresses`, addresses);
    }, [chain, contracts]);

    const value = {
        isWalletConnected: Boolean(wallet),
        getProvider,
        contracts,
        isReady,
        logContracts,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useWeb3Context(): Web3Context {
    return useContext(Context);
}
