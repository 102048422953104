import React from 'react';

export const NftSvg: React.ReactNode = (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 23 23" >
        <defs>
            <clipPath id="clip-path-nft">
                <rect id="Rectangle_308" data-name="Rectangle 308" width="280" height="56" rx="18" transform="translate(29 626)" fill="#f5f6fa"/>
            </clipPath>
        </defs>
        <g id="Mask_Group_10" data-name="Mask Group 10" transform="translate(-40.5 -629.5)" clipPath="url(#clip-path-nft)">
            <rect id="Rectangle_307" data-name="Rectangle 307" width="50" height="56" rx="18" fill="#f5f6fa" opacity="0" transform="translate(29 626)"/>

            <g id="nft-2" data-name="nft" transform="translate(10 630)">
                <path id="Path_194" data-name="Path 194" d="M51.191,5.264,42.223.086a.644.644,0,0,0-.645,0L32.61,5.264a.644.644,0,0,0-.322.558V16.178a.644.644,0,0,0,.322.558l8.968,5.178a.644.644,0,0,0,.645,0l8.968-5.178a.644.644,0,0,0,.322-.558V5.822A.644.644,0,0,0,51.191,5.264Zm-.967,10.542L41.9,20.611l-8.324-4.806V6.194L41.9,1.389l8.324,4.806Z" transform="translate(0 0)" fill="#01303c"/>
                <path id="Path_195" data-name="Path 195" d="M101.218,192.8a.645.645,0,0,0-.645.645v2.576L99.4,193.2a.645.645,0,0,0-.595-.4h-.69a.645.645,0,0,0-.645.645v4.142a.645.645,0,0,0,1.289,0V195.01l1.177,2.824a.645.645,0,0,0,.595.4h.69a.645.645,0,0,0,.645-.645v-4.142A.645.645,0,0,0,101.218,192.8Z" transform="translate(-62.378 -184.516)" fill="#01303c"/>
                <path id="Path_196" data-name="Path 196" d="M226,193.445v4.142a.645.645,0,0,0,1.289,0v-1.081h1.381a.645.645,0,0,0,0-1.289h-1.381v-1.127h2.071a.645.645,0,0,0,0-1.289h-2.716A.645.645,0,0,0,226,193.445Z" transform="translate(-185.388 -184.516)" fill="#01303c"/>
                <path id="Path_197" data-name="Path 197" d="M330.1,194.089h.736v3.5a.645.645,0,0,0,1.289,0v-3.5h.736a.645.645,0,0,0,0-1.289H330.1A.645.645,0,0,0,330.1,194.089Z" transform="translate(-284.405 -184.516)" fill="#01303c"/>
            </g>
        </g>
    </svg>
);
