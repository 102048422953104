import cx from 'classnames';
import { Logger } from 'helpers/logging';
import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide  } from 'swiper/react';

import Styles from './styles.module.sass';

interface CarouseProps<T> {
    items: T[];
    children(item: T): React.ReactElement;
    onChange?(item: T | null): void;
}

export function Carousel<T = unknown>(props: CarouseProps<T>): React.ReactElement {
    const isMulti = props.items.length > 1;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onChange(swiper: any): void {
        const activeIndex = swiper.activeIndex;
        const item = props.items[activeIndex] || null;
        Logger.log('item is', item);

        if (props.onChange) {
            props.onChange(item);
        }
    }

    return (
        <Swiper
            slidesPerView={1}
            pagination={isMulti}
            navigation={isMulti}
            modules={[Navigation, Pagination]}
            spaceBetween={40}
            onActiveIndexChange={onChange}
        >
            {props.items.map((item, key): React.ReactElement => {
                return (
                    <SwiperSlide key={key}>
                        <div className={cx(Styles.content, { [Styles.contentWithNav]: isMulti })}>
                            {props.children(item)}
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
