import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';

import { formatBigNumberDate } from './date';
import { toDecimalString } from './number';
import { isVested as isVestedDate } from './wallet';

enum VestingTimestamps {
    Tier1 = 604800,
    Tier2 = 1209600,
    Tier3 = 1814400,
}

interface LiquidityPoolReward {
    bankx: string;
    eth: string;
    isVested: boolean;
    vestingDate: string;
    xsd: string;
}

interface LiquidityPoolSummary {
    bankxTotal: string;
    bankxVested: string;
    xsdTotal: string;
    xsdVested: string;
}
interface RewardInfo {
    items: LiquidityPoolReward[];
    summary: LiquidityPoolSummary;
}

export interface Rewards {
    bankx: RewardInfo;
    collateral: RewardInfo;
    xsd: RewardInfo;
}

function getDefaultLiquidityValue(): LiquidityPoolReward {
    return {
        bankx: '-',
        eth: '-',
        vestingDate: '-',
        isVested: false,
        xsd: '-',
    };
}

function getDefaultRewardInfo(): RewardInfo {
    return {
        items: [getDefaultLiquidityValue()],
        summary: {
            bankxTotal: '-',
            bankxVested: '-',
            xsdTotal: '-',
            xsdVested: '-',
        }
    };
}

export function getDefaultRewards(): Rewards {
    return {
        bankx: getDefaultRewardInfo(),
        collateral: getDefaultRewardInfo(),
        xsd: getDefaultRewardInfo(),
    };
}

function addNumberStrings(x: string, y: string): string {
    return String(Number(x) + Number(y));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatRewards(values: any): RewardInfo {
    const decimals = 18;
    const summary = {
        bankxTotal: '0',
        bankxVested: '0',
        xsdTotal: '0',
        xsdVested: '0',
    };

    Logger.log('formatRewards: values', values);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let items = values.map((value: any): LiquidityPoolReward => {
        const oneDayInSeconds = 86_400;
        const vestingDate = formatBigNumberDate(value.vestingtimestamp, { adjustmentInSeconds: oneDayInSeconds });
        const bankx = toDecimalString(value.bankxrewards, decimals);
        const eth = toDecimalString(value.ethvalue, decimals);
        const isVested = isVestedDate(vestingDate);
        const xsd = toDecimalString(value.xsdrewards, decimals);

        summary.bankxTotal = addNumberStrings(summary.bankxTotal, bankx);
        summary.xsdTotal = addNumberStrings(summary.xsdTotal, xsd);

        if (isVested) {
            summary.bankxVested = addNumberStrings(summary.bankxVested, bankx);
            summary.xsdVested = addNumberStrings(summary.xsdVested, xsd);
        }

        return {
            bankx,
            eth,
            vestingDate,
            isVested,
            xsd
        };
    }).filter((value: LiquidityPoolReward): boolean => {
        return value.vestingDate !== '-';
    });

    if (items.length === 0) {
        items = [getDefaultLiquidityValue()];
    }

    return {
        items,
        summary
    };
}

interface GetRewardsConfig {
    bankxPoolContract: ethers.Contract;
    collateralPoolContract: ethers.Contract;
    rewardsContract: ethers.Contract;
    walletAddress: string;
    xsdPoolContract: ethers.Contract;
}

export async function getRewards(
    {
        bankxPoolContract,
        collateralPoolContract,
        rewardsContract,
        walletAddress,
        xsdPoolContract,
    }: GetRewardsConfig
): Promise<Rewards> {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function getRewardsFromContract(address: string): any[] {
        return [
            VestingTimestamps.Tier1,
            VestingTimestamps.Tier2,
            VestingTimestamps.Tier3,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ].map((tier): any => {
            return rewardsContract.liquidity_provider(address, walletAddress, tier);
        });
    }

    const [
        bankx,
        xsd,
        collateral,
    ] = await Promise.all([
        Promise.all(getRewardsFromContract(bankxPoolContract.address)),
        Promise.all(getRewardsFromContract(xsdPoolContract.address)),
        Promise.all(getRewardsFromContract(collateralPoolContract.address)),
    ]);

    const rewards = {
        bankx: formatRewards(bankx),
        collateral: formatRewards(collateral),
        xsd: formatRewards(xsd),
    };

    Logger.log('getRewardsLiquidityProvider: rewards', rewards);

    return rewards;
}
