// import cx from 'classnames';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { NftLeaderboard } from 'components/NftLeaderboard';
import ReferralBanner from 'components/ReferralBanner';
import React from 'react';

export function NFTLeaderboardPage() {
    return (
        <DashboardLayout
            banner={<ReferralBanner route='buy-nft' />}
            hasSidebar={false}
            hasExandedLogo
            isDarkThemeOverride={false}
            hasDarkmodeBtn={false}
        >
            <link rel='stylesheet' href='/css/landing.css' />
            <NftLeaderboard />
        </DashboardLayout>
    );
}
