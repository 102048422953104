import { isProd } from './env';

enum DebugLevel {
    Disabled = 0,
    Debug,
    Info,
    Warn,
    Error
}

export class Logger {
    // disable logging if in prod url
    private static debugLevel = isProd() ? DebugLevel.Disabled : DebugLevel.Error;

    static debug(...args: unknown[]): void {
        if (DebugLevel.Debug <= this.debugLevel) {
            console.debug(...args);
        }
    }

    static log(...args: unknown[]): void {
        if (DebugLevel.Info <= this.debugLevel) {
            console.log(...args);
        }
    }

    static info(...args: unknown[]): void {
        if (DebugLevel.Info <= this.debugLevel) {
            console.log(...args);
        }
    }

    static warn(...args: unknown[]): void {
        if (DebugLevel.Warn <= this.debugLevel) {
            console.warn(...args);
        }
    }

    static error(...args: unknown[]): void {
        if (DebugLevel.Error <= this.debugLevel) {
            console.error(...args);
        }
    }
}
