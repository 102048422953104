import { AddressMap } from './types';

export const BinanceTestnetAddresses: AddressMap = {
    BankXToken:           '0x51eff86abBEC27fD92E4E942A1d97BD3b984572b',
    XSDToken:             '0x0eB96C57225BE7d5C08d8639f3CA2Ba61Dc75167',
    BankXPool:            '0x32688FC5197ec8f0C663a722ef8e40b9495C54bd',
    XSDPool:              '0x8ffc2503C22aabaF2322bdBAD264b86BE4Ba921a',
    CollateralPool:       '0x2C0cC30c6511F9B2ea6bFdFcb7FE84BB5dc959E8',
    PIDController:        '0xdef0Df62211D57F9B13F7b3a1f584aDB2338f1Bc',
    RewardManager:        '0xE67EE363d87aD03B86f9339cdf8b490d12C8740B',
    RouterAddress:        '0x5Dc6F9571E314fF3084174a2F4ec38BDB21eF499',
    Arbitrage:            '0x6aC6194eE936B827C4F1C425968e37c39aB88877',
    CertificateOfDeposit: '0xcf8aBE457D0E3bAD6f20233E6139bB239ebB08d5',
    BankXNFT:             '0x69Caa8E0f7A8ff50eED9bdD3e3eFC0802F22475F',
    NFTBonus:             '0x39cA9f6543ec4BAADc71cF92959814520C94DBcB',
};

// 07/01/2023 1222PD
// BankXToken address     0x51eff86abBEC27fD92E4E942A1d97BD3b984572b
// XSDToken address       0x0eB96C57225BE7d5C08d8639f3CA2Ba61Dc75167
// BankXPool address      0x32688FC5197ec8f0C663a722ef8e40b9495C54bd
// XSDPool address        0x8ffc2503C22aabaF2322bdBAD264b86BE4Ba921a
// CollateralPool address 0x2C0cC30c6511F9B2ea6bFdFcb7FE84BB5dc959E8
// PID controller address 0xdef0Df62211D57F9B13F7b3a1f584aDB2338f1Bc
// Reward Manager         0xE67EE363d87aD03B86f9339cdf8b490d12C8740B
// Router address         0x5Dc6F9571E314fF3084174a2F4ec38BDB21eF499
// Arbitrage:             0x6aC6194eE936B827C4F1C425968e37c39aB88877

// 03/20/2023 2237PD
// BankXToken address     0xD8f69E48eB50817fc66553b9DF4D608B80091A4e
// XSDToken address       0xd6dcc122F66883e18939Cf190C189Dd5127A9e9E
// BankXPool address      0x81688CdCC74963eFee785e76cb779A6F2B71B66c
// XSDPool address        0x21084DFBB97fA20F380022c12c631555FCcADB0b
// CollateralPool address 0x16CD48Bd4a8546EB3D5B0e4e8b7b25a7537ba08E
// PID controller address 0x570F822312b9631B1321ECf6706430Fd3AAb778E
// Reward Manager         0x0087F27B26E77Ed8E0BBDD696AdB26Cd23DAaC74
// Router address         0x5A83C2e5b168C5350e6418D7544e62D8F7C70C1B
// Arbitrage:             0x422F055dC20c6224Be597d3216D4d362ffC10475

// 03/20/2023 0856PDT
// BankXToken address     0x54d93EAAF149e7F5D9d2D02dD6e5bcc7454962AB
// XSDToken address       0x53285b4B437CE0dB07DFC4ad57071a15373D1bC0
// BankXPool address      0xBC12b4694d2097d96b985d9E8913F15B8D6c7Fd9
// XSDPool address        0x44455f694144DA028f64E26e26279dF83E7EBDB2
// CollateralPool address 0x6C9C04dbf2Ef5b05E57Ec4b19A96f92A75160181
// PID controller address 0xb8A95D81E8fea61E643628D628449261fcBDD290
// Reward Manager         0x376e4B073ADF27e1fE759E5F32c3392F8CC43e68
// Router address         0x5B906CefFB84556fbeBB2bdBD2A7f2d216345e95
// Arbitrage:             0x1c9F76A600fB4b6507a73815CDA61bDdC7f5aBC2
// CD address             0xcf8aBE457D0E3bAD6f20233E6139bB239ebB08d5
// BankXNFT address       0x69Caa8E0f7A8ff50eED9bdD3e3eFC0802F22475F
// NFTBonus address       0x39cA9f6543ec4BAADc71cF92959814520C94DBcB

// 03/17/2023 0927PDT
// BankXToken address     0x010cDb37555D134d9E321E420e74c49209Ff73Aa
// XSDToken address       0xbaC8397B77ABAcdBFDE1869aB42Fccb4af150Db1
// BankXPool address      0xB89a75fadF545FAf6Acd436A491b0D1E45F2029F
// XSDPool address        0x922e5c8835ad1acFf8f3d2b720E16dBD8702F9dE
// CollateralPool address 0xbf935F93286475C5Dbb76Da2d8EaBDE4ED4e653d
// PID controller address 0xa07d979072826eD6168769376Cd58FdBb4E3e906
// Reward Manager         0x3dF783Bf06E99F5054f593f2Cc3315c0FbFA759D
// Router address         0x1dA1A66212ccb40eec59549edA24BB9D41694FcF
// Arbitrage:             0x6152954770CCD7337A5cc2B41cf91d788168974a
// CD address             0xcf8aBE457D0E3bAD6f20233E6139bB239ebB08d5
// BankXNFT address       0x69Caa8E0f7A8ff50eED9bdD3e3eFC0802F22475F
// NFTBonus address       0x39cA9f6543ec4BAADc71cF92959814520C94DBcB
