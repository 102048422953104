export class Storage {
    private static encoder: TextEncoder = new TextEncoder();
    private static decoder: TextDecoder = new TextDecoder();
    private static encode(value: string): string {
        const temp = this.encoder.encode(value).toString();
        return btoa(temp);
    }
    private static decode(value: string): string {
        try {
            const temp = atob(value);
            const arr = JSON.parse(`[${temp}]`);
            return this.decoder.decode(new Uint8Array(arr));
        } catch {
            return '';
        }
    }

    static set(key: string, value: string): void {
        const item = this.encode(value);
        localStorage.setItem(key, item);
    }
    static get(key: string): string | null{
        const item = localStorage.getItem(key);

        if (item) {
            return this.decode(item);
        }

        return null;
    }
    static delete(key: string): void {
        localStorage.removeItem(key);
    }
}
