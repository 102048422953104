import { Logger } from "./logging";
import { ethers } from "ethers";
import { ChainIdsHex } from "./chains";

const url = 'https://hermes.pyth.network/v2/updates/price/latest';
const XAG_USD_ID = '0xf2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e';


type callbackWithPriceUpdateData = (priceUpdateData: any, updateFee: any) => Promise<any>;
type callback = () => Promise<any>;

export async function priceUpdateCallback(
    chain: string,
    priceUpdateCallback: callback,
    callback: callback,
    isRouterInteraction: boolean,
): Promise<any> {
    // router contract no longer needs priceUpdate
    if (isRouterInteraction) {
        return callback();
    }

    switch (chain) {
        case ChainIdsHex.Avalanche:
            // eslint-disable-next-line no-fallthrough
        case ChainIdsHex.Fantom:
            // eslint-disable-next-line no-fallthrough
        case ChainIdsHex.Base:
            return priceUpdateCallback();
        default:
            return callback();
    }
}

export async function chainsCallbacksWithPriceUpdateData(
    chain: string,
    proxyContract: ethers.Contract,
    withPriceUpdateData: callbackWithPriceUpdateData,
    withoutPriceUpdateData: callback,
    isRouterInteraction: boolean,
): Promise<any> {
    return priceUpdateCallback(
        chain,
        async() => {
            const priceUpdateData = await getPriceUpdateDataFromPyth();
            const updateFee = await getUpdateFee(proxyContract, priceUpdateData);
            return withPriceUpdateData(priceUpdateData, updateFee);
        },
        () => withoutPriceUpdateData(),
        isRouterInteraction,
    );
}

export async function getUpdateFee(
    proxyContract: ethers.Contract,
    priceUpdateData: any,
): Promise<string> {
    Logger.log('getUpdateFee address=', proxyContract.address);

    const updateFee = await proxyContract.getUpdateFee(priceUpdateData);
    Logger.log('getUpdateFee updateFee=', updateFee);

    return updateFee;
}


interface Parsed {
    id: string;
    price: {
        price: string; // the price needs; to be divided
        conf: string;
        expo: number;
        publish_time: number;
    };
}
interface ApiResponse {
    binary: {
        encoding: string;
        data: string[]; // this is what we need
    };
    parsed: Parsed[]; // we don't need this
}


export async function getSilverPricePythResponse(): Promise<ApiResponse> {
    const params = new URLSearchParams();
    params.append('ids[]', XAG_USD_ID);

    const res = await fetch(`${url}?${params}`, {
        method: 'get',
        headers: {
            'Content-type': 'application/json',
        },
    });

    return res.json();
}

export async function getPriceUpdateDataFromPyth(): Promise<any> {
    const body = await getSilverPricePythResponse();
    let updateData = body?.binary?.data?.[0];
    Logger.warn('getPriceUpdateDataFromPyth: updateData=', updateData)

    if (updateData.slice(0, 2) !== '0x') {
        updateData = `0x${updateData}`;
    }

    const result = [updateData];

    Logger.log('getPriceUpdateDataFromPyth: result=', result)

    return result;
}
