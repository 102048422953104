import { getSacAppBaseApiUrl } from 'helpers/api';

export function saveEthToXsdSwap(transactionHash: string, chain: string): void {
    const url = getSacAppBaseApiUrl();

    fetch(`${url}/bankx/transactions`, {
        method: 'post',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            transaction_hash: transactionHash,
            chain,
        }),
    });
}
