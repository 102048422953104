import React, {
    useEffect,
    useRef,
} from 'react';

import { useTextInputContext } from './context';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface TextInputProps extends InputProps {
    // required instead of optional
    name: string;
}

export function TextInput(props: TextInputProps): React.ReactElement {
    const {
        inputClearTs,
        setValue,
    } = useTextInputContext();

    const name = props.name;

    const inputRef = useRef(props.value as string);
    inputRef.current = props.value as string;

    // set value on component unmount
    useEffect((): (() => void) => {
        return (): void => {
            setValue(name, inputRef.current);
        };
    }, [name, setValue]);

    const inputClearRef = useRef(inputClearTs);
    // clears rendered input values only
    useEffect((): void => {
        if (inputClearRef.current !== inputClearTs) {
            setValue(name, '');
            inputClearRef.current = inputClearTs;
        }
    }, [
        inputClearTs,
        setValue,
        name
    ]);

    return (
        <input type="text" {...props}/>
    );
}
