import { useConnectWallet } from '@web3-onboard/react';
import { Globals, useGetGlobals } from 'hooks/useGetGlobals';
import { useGetWalletValues } from 'hooks/useGetWalletValues';
import React, { createContext } from 'react';

export interface GlobalsWithWalletValues extends Globals {
    walletValues: ReturnType<typeof useGetWalletValues>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GlobalValuesContext = createContext<GlobalsWithWalletValues>({} as any);

export function GlobalValuesProvider(props: React.PropsWithChildren): React.ReactElement {
    const globals = useGetGlobals();
    const walletValues = useGetWalletValues();
    const [{ wallet }] = useConnectWallet();
    const isConnected = Boolean(wallet);

    const value = {
        ...globals,
        walletValues,
    };

    return (
        <GlobalValuesContext.Provider value={value}>
            <>
                {globals.areValuesLoading && isConnected && <div className="disable-overlay-full"/>}
                {props.children}
            </>
        </GlobalValuesContext.Provider>
    );
}
