import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { formatUIValue } from 'helpers/formatting';
import { MintInfo as IMintInfo } from 'helpers/minting';
import { toBigNumber, toDecimalString } from 'helpers/number';
import React from 'react';

import { FormattedValue } from './FormattedValue';

interface MintInfoProps extends Partial<IMintInfo> {
    cardClassName?: string;
    isLoading?: boolean;
    listClassName?: string;
}

export function MintInfo(props: MintInfoProps): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();

    const interest = toDecimalString(toBigNumber(props?.interestRate || '0', 18), 18);
    const isLoading = props.isLoading || false;

    const { cardClassName = '', listClassName = '' } = props;

    return (
        <div className="col-md-6 r-mb mb-4">
            <div className={cx(tcx('white-card'), cardClassName)}>
                <div className="wc-title">
                    <h3>Mint Info</h3>
                </div>
                <div className="item">
                    <div className={cx(tcx('si-list'))}>
                        <ul className={cx('mr-2', listClassName)}>
                            <li>
                                <b>Date of Last Mint/Redemption</b>
                                {isLoading
                                    ? <FormattedValue isLoading value="" />
                                    : <span>{formatUIValue(props?.timeLastMintedFormatted)}</span>
                                }
                            </li>
                            <li>
                                <b>Total XSD Minted</b>
                                <FormattedValue value={props.totalMinted || '-'} isLoading={isLoading} precision={4}/>
                            </li>
                            <li>
                                <b>Average Interest Rate</b>
                                <FormattedValue value={interest || '-'} suffix="%" isLoading={isLoading}/>
                            </li>
                            <li>
                                <b>Estimated Interest Earned to Date in USD</b>
                                <FormattedValue value={props?.totalAccumulatedInterest || '-'} isCurrency isLoading={isLoading}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
