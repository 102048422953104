import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { Logger } from 'helpers/logging';
import { addCommas } from 'helpers/number';
import { getThemedClassName, getThemedClassNameAlt } from 'helpers/theme';
import { useWallet } from 'hooks/useWallet';
import React, {
    useCallback, useEffect, useState
} from 'react';

import { ConnectButton } from '../../ConnectButton/ConnectButton';
import Styles from './styles.module.scss';

type HeaderProps = {
    hasExandedLogo?: boolean
    isDarkTheme: boolean
    hasDarkmodeBtn?: boolean;
    hasConnectButton?: boolean;
} & React.PropsWithChildren

export default function Header({
    hasExandedLogo = false,
    isDarkTheme,
    hasDarkmodeBtn = true,
    hasConnectButton = true,
}: HeaderProps) {
    const { updateSettings } = useAppSettings();
    const { contracts } = useWeb3Context();
    const [open, setOpen] = useState(false);
    const {
        tokenLabel,
        isConnected: isWalletConnected,
    } = useWallet();
    const { areValuesLoading, ethPriceInUsd } = useGlobalValues();

    async function addTokenFunction(airdrop_name: 'XSD' | 'BankX') {
        const tokenAddressBankX = contracts.bankxTokenContract.address;
        const tokenSymbolBankX = await contracts.bankxTokenContract.symbol();
        const tokenImageBankX = `${window.location.origin}/images/token/bankx.jpg`;
        const tokenAddressXSD = contracts.xsdTokenContract.address;
        const tokenSymbolXSD = await contracts.xsdTokenContract.symbol();
        const tokenImageXSD = `${window.location.origin}/images/token/xsd.jpg`;
        const tokenDecimals = 18;

        let tokenAddress;
        let tokenSymbol;
        let tokenImage;
        switch (airdrop_name) {
            case 'XSD':
                tokenAddress = tokenAddressXSD;
                tokenSymbol = tokenSymbolXSD;
                tokenImage = tokenImageXSD;
                break;
            case 'BankX':
                tokenAddress = tokenAddressBankX;
                tokenSymbol = tokenSymbolBankX;
                tokenImage = tokenImageBankX;
                break;
            default:
                Logger.error(`unknown token to add: ${airdrop_name}`);
        }

        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });

            if (wasAdded) {
                Logger.log('Token added!');
            } else {
                Logger.log('Token has not been added');
            }
        } catch (error) {
            Logger.log(error);
        }
    }

    const [darkModeBtnMargin, setDarkModeBtnMargin] = useState(8);

    const updateDarkModeBtnMargin = useCallback(() => {
        if (!isWalletConnected) {
            return;
        }

        const web3OnboardWidth =
      document
          ?.querySelector('onboard-v2')
          ?.shadowRoot?.querySelector('div>div')
          ?.getBoundingClientRect()?.width || 16;

        if (window?.innerWidth > 767) {
            setDarkModeBtnMargin(web3OnboardWidth - 18);
            return;
        }

        setDarkModeBtnMargin(web3OnboardWidth + 16);
    }, [isWalletConnected]);

    useEffect(() => {
        window.addEventListener('resize', updateDarkModeBtnMargin);
        return () => {
            window.removeEventListener('resize', updateDarkModeBtnMargin);
        };
    }, [updateDarkModeBtnMargin]);

    useEffect(() => {
        if (isWalletConnected) {
            setTimeout(updateDarkModeBtnMargin, 2000);
        }
    }, [isWalletConnected, updateDarkModeBtnMargin]);

    function toggleSidebar() {
        updateSettings((prev) => ({
            ...prev,
            isSidebarOpen: !prev.isSidebarOpen,
        }));
        setOpen(!open);
    }

    function toggleDarkTheme() {
        Logger.log({
            toggleDarkTheme,
        });
        updateSettings((prev) => ({
            ...prev,
            isDarkTheme: !prev.isDarkTheme,
        }));
    }

    function getImgUrl() {
        if (hasExandedLogo) {
            return 'images/icon/logo.png';
        }
        if (isDarkTheme) {
            return '/images/icon/x-logo-dark.png';
        } else {
            return '/images/icon/x-logo.jpg';
        }
    }

    function getButtons() {
        if (!isWalletConnected || !hasDarkmodeBtn) {
            return null;
        }

        const svgIcon = isDarkTheme
            ? '/images/icon/DarkModeButton.svg'
            : '/images/icon/LightModeButton.svg';
        const altText = isDarkTheme
            ? 'button to toggle light theme mode'
            : 'button to toggle dark theme mode';

        return (
            <>
                {false && <a className="mr-2 ehex-promo-button" href="https://ehex.bankx.io" target="_blank" rel="noopener noreferrer">
                    <button className="button-1 px-4">Buy eHex & Get Free Stablecoin</button>
                </a>}
                <img
                    src={svgIcon}
                    onClick={toggleDarkTheme}
                    className='header-dark-mode-toggle'
                    alt={altText}
                />
                <button
                    className='button-tab button-tab-sm button-tab-active'
                    onClick={() => addTokenFunction('BankX')}
                    style={{ borderRadius: 13 }}
                >
                    BANKX
                </button>
                <button
                    className='button-tab button-tab-sm button-tab-active'
                    onClick={() => addTokenFunction('XSD')}
                    style={{
                        marginRight: darkModeBtnMargin,
                        borderRadius: 13,
                    }}
                >
                    XSD
                </button>
            </>
        );
    }

    return (
        <div className={cx(getThemedClassName('header', isDarkTheme), { 'header-with-wallet': isWalletConnected })}>
            <div className='header-icon'>
                {!hasExandedLogo && (
                    <svg
                        viewBox='0 0 20 20'
                        onClick={() => toggleSidebar()}
                        fill='#545454'
                        width='26'
                        height='26'
                        className='menu-burer mr-3'
                    >
                        <path d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zm0 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'></path>
                    </svg>
                )}

                {/* <img src="/images/icon/menu.svg" className=" menu-burer" /> */}
                <a href='https://bankx.io' target='_blank' rel='noreferrer noopener'>
                    <img
                        {...(hasExandedLogo && {
                            className: 'expanded-logo',
                        })}
                        src={getImgUrl()}
                        alt='logo'
                    />
                </a>
                {
                    isWalletConnected && !areValuesLoading && (
                        <div className="p-2">
                            <span className={getThemedClassNameAlt(Styles.headerCurrencyLabel, isDarkTheme, Styles.headerCurrencyLabelDark)}>
                                { tokenLabel }:
                            </span>
                            <span className={cx(Styles.headerCurrencyUsdValue, 'ml-2')}>
                                ${ addCommas(String(ethPriceInUsd), 2)}
                            </span>
                        </div>
                    )
                }
            </div>
            <div className='header-button-wrapper'>
                {getButtons()}
                {hasConnectButton && <ConnectButton isHeaderButton={true} />}
            </div>
        </div>
    );
}
