
import { GearIcon } from 'assets/GearIcon';
import cx from 'classnames';
import {
    MAX_SLIPPAGE_VALUE,
    MIN_SLIPPAGE_VALUE,
    useAppSettings
} from 'context/AppSettingsProvider';
import { getValidInputValue } from 'helpers/number';
import { getThemedClassName } from 'helpers/theme';
import React, { useState } from 'react';

import ToggleSwitch from '../ToggleSwitch';
import Styles from './styles.module.sass';

interface SettingsProps {
    className?: string;
    isDisabled?: boolean;
    isSettingsVisible?: boolean;
    isSoloCard?: boolean;
    isThemeToggleHidden?: boolean;
    style?: Record<string, string>;
    title: string;
}

interface SettingsPanelProps extends SettingsProps {
    isOpen: boolean;
    onToggle(): void;
}

function SettingsPanel({
    isSoloCard = false,
    isThemeToggleHidden = false,
    isOpen,
    onToggle,
    style = {},
    title,
}: SettingsPanelProps): React.ReactElement | null {
    const {
        isDarkTheme,
        slippage,
        updateSettings,
    } = useAppSettings();

    const [inputValue, setInputValue] = useState(slippage.isAuto ? '' : slippage.value);

    function onThemeToggle(): void {
        updateSettings(prev => ({
            ...prev,
            isDarkTheme: !prev.isDarkTheme
        }));
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = getValidInputValue(e.currentTarget.value, {
            min: MIN_SLIPPAGE_VALUE,
            max: MAX_SLIPPAGE_VALUE
        });

        setInputValue(value);
        updateSettings(prev => ({
            ...prev,
            slippage: {
                isAuto: false,
                value,
            }
        }));
    }

    function handleAutoClick(): void {
        setInputValue('');
        updateSettings(prev => ({
            ...prev,
            slippage: {
                isAuto: true,
                value: String(MIN_SLIPPAGE_VALUE)
            }
        }));
    }

    if (!isOpen) {
        return null;
    }

    return  (
        <div className={cx(Styles.container, { [Styles.soloCard]: isSoloCard })} style={style}>
            <div>
                <div className="main-card-header">
                    <h3>{title}</h3>
                    <div className={cx(Styles.iconContainer, 'ml-2')}>
                        <div className={cx(Styles.iconSlippageMessage, 'mb-1')}>
                        Slippage: {slippage.value}%
                        </div>
                        <button
                            className={getThemedClassName('button-s', isDarkTheme && !isThemeToggleHidden)}
                            onClick={onToggle}
                        >
                            <GearIcon />
                        </button>
                    </div>
                </div>
                <div className={cx(Styles.text, 'mb-2')}>Slippage Tolerance</div>
                <div className="setting-buttons">
                    <button
                        onClick={handleAutoClick}
                        className={cx(Styles.text, 'setting-button', { 'setting-enabled': slippage.isAuto })}
                    >
                        Auto
                    </button>
                    <div className="setting-input-container">
                        <input
                            className={getThemedClassName('setting-dark', isDarkTheme)}
                            type="number"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder={String(MIN_SLIPPAGE_VALUE)}
                        />
                        <span className="setting-input-suffix">%</span>
                    </div>
                </div>
                {!isThemeToggleHidden && (
                    <div className={getThemedClassName('toogle-switch-wrapper', isDarkTheme)}>
                        <div className={cx(Styles.text, 'mb-2')}>Dark Mode</div>
                        <div>
                            <ToggleSwitch
                                id="newsletter"
                                onChange={onThemeToggle}
                                checked={isDarkTheme}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export function Settings(props: SettingsProps ): React.ReactElement {
    const {
        className = '',
        isThemeToggleHidden,
        isDisabled,
        isSettingsVisible = true,
        title,
    } =  props;

    const [isOpen, setIsOpen] = useState(false);
    const { isDarkTheme, slippage } = useAppSettings();

    function onToggle(): void {
        if (!isDisabled) {
            setIsOpen(prev => !prev);
        }
    }

    return  (
        <>
            <div className={cx('main-card-header', className)}>
                <h3>{title}</h3>
                {isSettingsVisible && (
                    <div className={cx(Styles.iconContainer, 'ml-2')}>
                        <div className={cx(Styles.iconSlippageMessage, 'mb-1')}>
                            Slippage: {slippage.value}%
                        </div>
                        <button
                            className={getThemedClassName('button-s', isDarkTheme && !isThemeToggleHidden)}
                            onClick={onToggle}
                        >
                            <GearIcon />
                        </button>
                    </div>
                )}
            </div>
            <SettingsPanel isOpen={isOpen} {...props} onToggle={onToggle}/>
        </>
    );
}
