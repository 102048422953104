import { Paths } from 'helpers/paths';
import { Arbitrage } from 'pages/Arbitrage/Arbitrage';
import BuyNFTPage from 'pages/BuyNFT';
import { Certificate } from 'pages/Certificate';
import { Collateral } from 'pages/Collateral';
import { ExchangePage } from 'pages/Exchange';
import { LiquidityPool } from 'pages/LiquidityPool';
import { Looping } from 'pages/Looping';
import { Mint } from 'pages/Mint';
import NFTPage from 'pages/NFT';
import { NFTLeaderboardPage } from 'pages/NftLeaderboardPage';
import PricingPage from 'pages/Pricing';
import { Redeem } from 'pages/Redeem';
import SellLiquidityPage from 'pages/SellLiquidity';
import SilverPage from 'pages/Silver';
import XSDPage from 'pages/XSD';
import React from 'react';
import {
    Navigate,
    Route,
    Routes as ReactRoutes,
} from 'react-router-dom';

export function Routes(): React.ReactElement {
    return(
        <ReactRoutes>
            <Route path={Paths.Looping} element={<Looping/>}/>
            <Route path={Paths.Arbitrage} element={<Arbitrage/>}/>
            <Route path={Paths.Certificate} element={<Certificate/>}/>
            <Route path={Paths.Collateral} element={<Collateral/>}/>
            <Route path={Paths.Swap} element={<ExchangePage/>}/>
            <Route path={Paths.Liquidity} element={<LiquidityPool/>}/>
            <Route path={Paths.Mint} element={<Mint/>}/>
            <Route path={Paths.Redeem} element={<Redeem/>}/>
            <Route path={Paths.Xsd} element={<XSDPage />} />
            <Route path={Paths.Silver} element={<SilverPage />} />
            <Route path={Paths.Nft} element={<NFTPage />} />
            <Route path={Paths.BuyNft} element={<BuyNFTPage />} />
            <Route path={Paths.Pricing} element={<PricingPage />} />
            <Route path={Paths.NftLeaderboard} element={<NFTLeaderboardPage/>} />
            <Route path={Paths.SellLiquidity} element={<SellLiquidityPage />} />
            <Route path="*" element={<Navigate to="/swap"/>}/>
        </ReactRoutes>
    );

}
