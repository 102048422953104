import { AddressMap } from './types';

export const EthMainnetAddresses: AddressMap = {
    BankXToken:           '0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a',
    XSDToken:             '0x75Cae30025A514b7AE069917E132Cc99762A0e16',
    BankXPool:            '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
    XSDPool:              '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    CollateralPool:       '0x06F508Bb8a358B3764000F50afE074d7E66Fa902',
    PIDController:        '0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7',
    RewardManager:        '0x2a0C55D66482e801abbaE0271FE1BF43B9D03D2c',
    RouterAddress:        '0xF441252DE7972B269cE954d82bE8b127a815Ecfb',
    Arbitrage:            '0x58421507d10A4c57a761E8AAd5382D5564A682F5',
    CertificateOfDeposit: '0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640',
    BankXNFT:             '0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17',
    NFTBonus:             '0xCa6D501Af96Fff556140CEd968C856BBa2d111CB',
};
// 11/30/2024 1231DT
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankX Pool:             0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Pool:               0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// Collateral Pool:        0x06F508Bb8a358B3764000F50afE074d7E66Fa902
// PID:                    0x5997a08fD19bB3E397b25d41c1c10D2F2E0299F7
// Reward Manager:         0x2a0C55D66482e801abbaE0271FE1BF43B9D03D2c
// Router:                 0xF441252DE7972B269cE954d82bE8b127a815Ecfb
// Arbitrage:              0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 10/04/2024 1524PDT
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankX Pool:             0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Pool:               0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// Collateral Pool:        0x86f427a5612525dc62Bd5939C8577c54A74D2746
// PID controller address: 0x7942418efD38dd2e0C6E13574C168B3d2E7C0A3E
// Reward Manager:         0x2a0C55D66482e801abbaE0271FE1BF43B9D03D2c
// Router:                 0xF441252DE7972B269cE954d82bE8b127a815Ecfb
// Arbitrage:              0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 02/18/2024 2020PST
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankX Pool:             0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Pool:               0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// Collateral Pool:        0xEA9da86Fc63bD2c558Ba6f354dfeCefE2710C454
// PID:                    0xAE5FC15af85396C074c9b63DbB84A1284F152217
// Reward Manager:         0x2a0C55D66482e801abbaE0271FE1BF43B9D03D2c
// Router:                 0xF441252DE7972B269cE954d82bE8b127a815Ecfb
// Arbitrage:              0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 02/18/2024 2020PST
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankX Pool:             0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Pool:               0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// Collateral Pool:        0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// PID:                    0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Reward Manager:         0xCb03B68076361089D58A899B0678462B62ae6D04
// Router:                 0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:              0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 01/06/2024 0942PST
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankX Pool:             0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD Pool:               0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// Collateral Pool:        0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// PID:                    0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
// Reward Manager:         0x93Abe713002526D4cE708f890139f19313012f95
// Router:                 0x59cA927Ae4c900dC8091515191E39B010bec1118
// Arbitrage:              0x58421507d10A4c57a761E8AAd5382D5564A682F5
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 07/10/2023 0759PDT
// BankxToken address:     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address:       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankXPool address:      0xaA8E05946a286dd58D126215BE5BFf1421A66F1E
// XSDPool address:        0x12b4718f053FcD358333D97d8aC9A54e43CcfDdc
// CollateralPool address: 0x9c117CA0A7b10e31a3Cf813aDee92603e6F2225C
// PID controller address: 0xadC56Ab9fA2508d56A1a814139B96694330021b5
// Reward Manager:         0x4CE9967cBeEec585a4B76f72043eB181B7513615
// Router address:         0xf355811f4c8117E73a9F0A1b5769c123b8b5E31A
// Arbitrage:              0xce5b4Cfac18321D586e78B0c8051517b6c62c712
// CD address:             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address:       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address:       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 05/19/2023 1041PDT
// BankxToken address     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankXPool address      0xaA8E05946a286dd58D126215BE5BFf1421A66F1E
// XSDPool address        0x12b4718f053FcD358333D97d8aC9A54e43CcfDdc
// CollateralPool address 0x1868de455eB102e084014ba2FE75F316480a3824
// PID controller address 0x802B293c1230ec2335c67B118E37614D51A1F221
// Reward Manager         0x4CE9967cBeEec585a4B76f72043eB181B7513615
// Router address         0xa8c6F22329B2654C71843e94050b8bda49F31164
// Arbitrage              0xce5b4Cfac18321D586e78B0c8051517b6c62c712
// CD address             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 05/09/2023 2254PDT
// BankxToken address     0x13e636cbfd6a7d33a8df7ebbf42f63adc9bb592a
// XSDToken address       0x75Cae30025A514b7AE069917E132Cc99762A0e16
// BankXPool address      0xaA8E05946a286dd58D126215BE5BFf1421A66F1E
// XSDPool address        0x12b4718f053FcD358333D97d8aC9A54e43CcfDdc
// CollateralPool address 0xfCD7c58863f14Fcc61Bd7B095507cEfea9810C57
// PID controller address 0x802B293c1230ec2335c67B118E37614D51A1F221
// Reward Manager         0x4CE9967cBeEec585a4B76f72043eB181B7513615
// Router address         0xa8c6F22329B2654C71843e94050b8bda49F31164
// Arbitrage              0xce5b4Cfac18321D586e78B0c8051517b6c62c712
// CD address             0xc74Ff6Db79D466087BfEe53980eeCB9A5f3c2640
// BankXNFT address       0xD6e2c209a1227F7918cf62acB26BBb213bAc4d17
// NFTBonus address       0xCa6D501Af96Fff556140CEd968C856BBa2d111CB

// 03/23/2023 1020PDT
// BankXToken address     0xf77EBf9Cb11F98688E49C6547A5a9749D3080E06
// XSDToken address       0x4503Df80b3EC33aDCA75E4f7126823486DA9b97d
// BankXPool address      0x5273A286aFb8ea0d13098B97050968c2CaB37d7b
// XSDPool address        0xeA7D948142865886FCfA006e85fD4CBbE1582568
// CollateralPool address 0x672541c038Ec2808619D0ee9F2009E5b5A7f685c
// PID controller address 0xC40C3e425DC5323b0651669a3e20B49DE1D74F74
// Reward Manager         0xf4945fa9479EAEC7dA5F711baB680d610cd30851
// Router address         0xC1D30D3e0BdE9232b4936B70B152DA899354B453
// Arbitrage:             0x73cD72ea1d09de5e85F9D5701b3AEE012A68C933
// CD address             0x522890031d2122C01568bd9B3C4b2db9bfb0C1a1
// BankXNFT address       0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// NFTBonus address       0x7bf3516E4758b6F72C0bC68F66cE108bFC37DCB8

// 03/22/2023 1125PDT
// BankXToken address     0xc418A3c4EACbd84baA5bd4f62C192F0EBD4B03Eb
// XSDToken address       0x954f1D6b25775325BD73C3cDe04231fEC84b0c47
// BankXPool address      0xC3B373C2e203e9907dA67fB7dE8BB7D541489803
// XSDPool address        0x73666bcF2bBCc131950068E406339d4304146d80
// CollateralPool address 0xB8277dD35d4818B9703867d0561eef952B4099Ca
// PID controller address 0x3c319A9144eE886d1d1E5ecEa694e9009a025a07
// Reward Manager         0xf91BBAE1219D0209D92A03119832F3c97Bc479f0
// Router address         0xE1f2f0b1Deb0EAd135f4d59F194B7949d66AD002
// Arbitrage:             0x478A9A0201b7B6d71D67827110c0f447ECF7f040
// CD Address             0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address       0x71Dd4c07f758bd25a0Ce982FCE345D937a29Cb1C
// NFTBonus address       0x42DC93f9f522e82e7EeE98BDC99fe7a3b3c992EC
