export enum TextInputName {
    Arbitrage = 'Arbitrage',
    Certificate = 'Certificate',
    Liquidity = 'Liquidity',
    Mint = 'Mint',
    NegativeCollateral = 'NegativeCollateral',
    NFT = 'NFT',
    PositiveCollateral = 'PositiveCollateral',
    Redeem = 'Redeem',
    SwapSource = 'SwapSource',
    SwapTarget = 'SwapTarget',
}
