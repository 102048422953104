import React from 'react';

export const LoopingSvg: React.ReactNode = (
    <svg
        id="Group_660"
        data-name="Group 660"
        xmlns="http://www.w3.org/2000/svg"
        width="153.226"
        height="155.71"
        viewBox="0 0 153.226 155.71"
    >
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Rectangle_300"
                    data-name="Rectangle 300"
                    width="153.226"
                    height="155.71"
                    fill="#1882c5"
                />
            </clipPath>
        </defs>
        <rect
            id="Rectangle_299"
            data-name="Rectangle 299"
            width="127.245"
            height="10.202"
            transform="translate(4.964 16.533)"
            fill="#1882c5"
        />
        <g id="Group_659" data-name="Group 659">
            <g
                id="Group_658"
                data-name="Group 658"
                clipPath="url(#clip-path)"
            >
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M.893,79.787V36.113a5.215,5.215,0,0,1,10.431,0V79.787a5.215,5.215,0,0,1-10.431,0"
                    transform="translate(-0.415 -14.364)"
                    fill="#1882c5"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M.963,195.406c0,.117,0,.043,0,0,1.709,1.554,3.419,2.864,5.128,4.419,1.728-1.433,3.456-2.986,5.183-4.419,0,.348,0,.817,0,1.165q.029,20.961.058,41.923a10.343,10.343,0,0,1-.42,3.6,5.314,5.314,0,0,1-5.382,3.354,5.126,5.126,0,0,1-2.728-1.19,5.336,5.336,0,0,1-1.675-2.484c-.073-.236-.1-.485-.186-.718a3.11,3.11,0,0,1-.04-.947Q.9,239.038.893,237.971q-.01-2.134-.018-4.268t-.012-4.268q0-2.135-.006-4.268t0-4.269q0-2.133,0-4.268t.01-4.268q.006-2.134.016-4.268t.02-4.268q.012-2.134.026-4.268"
                    transform="translate(-0.398 -90.84)"
                    fill="#1882c5"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M237.318,164.859V36.113a5.215,5.215,0,1,1,10.431,0V164.859a5.215,5.215,0,1,1-10.431,0"
                    transform="translate(-110.324 -14.364)"
                    fill="#1882c5"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M6.108,271.488H131.234a5.216,5.216,0,0,1,0,10.431H6.108a5.215,5.215,0,1,1,0-10.431"
                    transform="translate(-0.415 -126.209)"
                    fill="#1882c5"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M134.89,20.4,99.893.193a1.426,1.426,0,0,0-2.139,1.235V41.84a1.426,1.426,0,0,0,2.139,1.235l35-20.206a1.426,1.426,0,0,0,0-2.47"
                    transform="translate(-45.444 0)"
                    fill="#1882c5"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M247.322,132H206.911a1.426,1.426,0,0,0-1.235,2.139l20.205,35a1.426,1.426,0,0,0,2.47,0l20.206-35A1.426,1.426,0,0,0,247.322,132"
                    transform="translate(-95.524 -61.366)"
                    fill="#1882c5"
                />
            </g>
        </g>
    </svg>
);
