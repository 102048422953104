import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import { Contract } from './addresses';
import { formatDecimalToPercentage } from './number';
import { getDeadline } from './deadline';
import { systemCalculations } from './pool';
import { ChainIdsHex } from './chains';

interface RedeemLiquidityConfig {
    routerContract: ethers.Contract;
    provider: ethers.providers.Web3Provider;
    walletAddress: string | null;
    targetContractAddress: string;
    goToNextLoadingMessage: (message?: string) => void;
    pidContract: ethers.Contract;
    proxyContract: ethers.Contract;
    chain: string;
    blockDelay: number;
    rewardManager: ethers.Contract;
    priceCheckBlockDelay: number;
}

export async function redeemLiquidity(
    {
        routerContract,
        provider,
        walletAddress,
        targetContractAddress,
        goToNextLoadingMessage,
        pidContract,
        proxyContract,
        chain,
        blockDelay,
        rewardManager,
    }: RedeemLiquidityConfig
): Promise<void> {
    Logger.log('redeemLiquidity: ', targetContractAddress, walletAddress);
    let receipt: any;

    if (!walletAddress) {
        return;
    }

    const signer = provider.getSigner();

    // step 1: price check
    goToNextLoadingMessage(CustomLoadingMessage.SystemsCalculations);
    await systemCalculations(pidContract, proxyContract, signer, chain, blockDelay);

    // step 2: redeem
    goToNextLoadingMessage();
    switch(chain) {
        case ChainIdsHex.BinanceSmartChain:
            // https://ossllc.atlassian.net/browse/BAN-409
            Logger.log('redeemLiquidity: rewardManager.LiquidityRedemption', targetContractAddress, walletAddress);
            receipt = await rewardManager.connect(signer).LiquidityRedemption(targetContractAddress, walletAddress);
            break;
        default: {
            const deadline = await getDeadline(provider);
            Logger.log('redeemLiquidity: routerContract.userRedeemLiquidity', targetContractAddress, deadline);
            receipt = await routerContract.connect(signer).userRedeemLiquidity(targetContractAddress, deadline);
        }
    }
    Logger.log('redeemLiquidity: receipt', receipt);

    // step 3: wait
    goToNextLoadingMessage(CustomLoadingMessage.LiquidityWithdraw);
    await receipt.wait(1);
}

enum RewardTierDuration {
    Best = '1 week',
    Middle = '2 weeks',
    Worst = '3 weeks',
}

export function getVestingTime(deficit: string, amountRaised: string): string {
    const ratio = Number(amountRaised) / Number(deficit);

    if (Number.isNaN(ratio)) {
        return '-';
    }

    // best rewards if user contributes in the first of three buckets
    if (ratio < 1 / 3 || !Number.isFinite(ratio)) {
        return RewardTierDuration.Best;
    }

    // this means user is contributing in the final bucket
    if (ratio < 2 / 3) {
        return RewardTierDuration.Middle;
    }

    return RewardTierDuration.Worst;
}

export function getBankxInterest(targetContractAddress: string): number {
    switch(targetContractAddress) {
        case Contract.CollateralPool:
            return 0.07;
        case Contract.BankXPool:
            return 0.08;
        case Contract.XSDPool:
            return 0.09;
        default:
            return 0;
    }
}

export function getXSDInterest(deficit: string, amountRaised: string): number {
    const vestingTime = getVestingTime(deficit, amountRaised);

    switch (vestingTime) {
        case RewardTierDuration.Best:
            return 0.05;
        case RewardTierDuration.Middle:
            return 0.02;
        default:
            return 0;
    }
}

interface RewardsCTAConfig {
    vestingPeriod: string,
    xsdRoi: number,
    bankxRoi: number,
    token: string,
}
export function getRewardsCTA(config: RewardsCTAConfig): string {
    // BAN-344: DATA updates based on the chain,
    // 8 or 9 % in BankX tokens (whether it is XSD or the BankX Pool,
    // 2 or 5% in XSD and shows 2 weeks or 3 weeks.
    // If no XSD, then it will say:  Sell BNB to get your principle + 9% in BankX vesting in 3 weeks.
    const bankx = formatDecimalToPercentage(config.bankxRoi);
    const xsd = formatDecimalToPercentage(config.xsdRoi);
    const parts = [`Sell ${config.token} to get your principal + ${bankx} in BankX tokens`];

    if (parseInt(xsd) > 0) {
        parts.push(`, + ${xsd} in XSD`);
    }

    parts.push(` vesting in ${config.vestingPeriod}.`);

    return parts.join('');
}
export function getRoiCopy(bankxRoi: number): string {
    return formatDecimalToPercentage(1 + bankxRoi);
}
