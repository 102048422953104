import React, {
    createContext, useCallback, useContext, useState
} from 'react';

interface TextInputContext {
    clearInputs(): void;
    state: Record<string, string>;
    setValue(name: string, value: string): void;
    inputClearTs: number | null;
    useInputState(name: string): [any, (value: any) => void];
}

const Context = createContext<TextInputContext>({} as unknown as TextInputContext);

export function TextInputProvider({ children }: React.PropsWithChildren): React.ReactElement {
    const [state, setState] = useState<Record<string, any>>({});
    const [inputClearTs, setInputClearTs] = useState<number | null>(null);

    const setValue = useCallback((name: string, value: string): void => {
        setState(prev => {
            return {
                ...prev,
                [name]: value,
            };
        });
    }, []);

    const clearInputs = useCallback((): void => {
        setInputClearTs(Date.now());
    }, []);

    function useInputState(name: string): ReturnType<TextInputContext['useInputState']> {
        function update(value: string) {
            setValue(name, value);
        }

        return [state[name] || '', update];
    }

    const contextValue = {
        clearInputs,
        inputClearTs,
        state,
        setValue,
        useInputState,
    };

    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    );
}

export function useTextInputContext(): TextInputContext {
    return useContext(Context);
}
