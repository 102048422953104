import React from 'react';

export const CollateralSvg: React.ReactNode = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 16 16"
        width="16px"
        height="16px"
    >
        <path d="M 7.5 1 C 6.851563 1 6.300781 1.421875 6.09375 2 L 2.675781 2 L 0.00390625 8 L 0 8 L 0 8.003906 L -0.00390625 8.015625 L 0.00390625 8.015625 C 0.0117188 9.660156 1.355469 11 3 11 C 4.652344 11 6 9.652344 6 8 L 5.976563 8 L 3.765625 3 L 6.09375 3 C 6.246094 3.417969 6.578125 3.753906 7 3.90625 L 7 13 L 5 13 L 5 14 L 10 14 L 10 13 L 8 13 L 8 3.90625 C 8.421875 3.753906 8.753906 3.417969 8.90625 3 L 11.230469 3 L 9 8 L 9 8.003906 L 8.996094 8.015625 L 9 8.015625 C 9.011719 9.660156 10.355469 11 12 11 C 13.652344 11 15 9.652344 15 8 L 14.980469 8 L 12.328125 2 L 8.90625 2 C 8.699219 1.421875 8.148438 1 7.5 1 Z M 7.5 2 C 7.78125 2 8 2.21875 8 2.5 C 8 2.78125 7.78125 3 7.5 3 C 7.21875 3 7 2.78125 7 2.5 C 7 2.21875 7.21875 2 7.5 2 Z M 3 3.734375 L 4.886719 8 L 1.097656 8 Z M 12 3.734375 L 13.886719 8 L 10.097656 8 Z M 1.28125 9 L 4.71875 9 C 4.375 9.597656 3.742188 10 3 10 C 2.257813 10 1.625 9.597656 1.28125 9 Z M 10.277344 9 L 13.722656 9 C 13.378906 9.597656 12.742188 10 12 10 C 11.257813 10 10.621094 9.597656 10.277344 9 Z" />
    </svg>
);
