import PropTypes from 'prop-types';
import React from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function. The props name, small, disabled
and optionLabels are optional.
Usage: <ToggleSwitch id="id" checked={value} onChange={checked => setValue(checked)}} />
*/

export function ToggleSwitch ({
    id,
    name,
    checked,
    onChange,
    optionLabels,
    small,
    disabled
}: any): React.ReactElement {
    function handleKeyPress(e: any): void {
        if (e.keyCode !== 32) {
            return;
        }

        e.preventDefault();
        onChange(!checked);
    }

    return (
        <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
            <input
                type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            />
            {/* */}
            {id ? (
                <label
                    className={'toggle-switch-label' + (checked ? ' toggle-switch-label-checked' : '')}
                    tabIndex={disabled ? -1 : 1}
                    onKeyDown={(e) => handleKeyPress(e)}
                    htmlFor={id}
                >
                    <span
                        className={
                            disabled
                                ? 'toggle-switch-inner toggle-switch-disabled'
                                : 'toggle-switch-inner'
                        }
                        data-yes={optionLabels[0]}
                        data-no={optionLabels[1]}
                        tabIndex={-1}
                    />
                    <span
                        className={`
            ${
                disabled
                    ? 'toggle-switch-switch toggle-switch-disabled'
                    : 'toggle-switch-switch'
                }
            ${
                checked ? 'toggle-checked' : ''
                }

            `}
                        tabIndex={-1}
                    />
                </label>
            ) : null}
        </div>
    );
}

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
    optionLabels: ['On', 'Off']
};

ToggleSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    optionLabels: PropTypes.array,
    small: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ToggleSwitch;
