import React from 'react';

export const SwapSvg: React.ReactNode = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 16 16"
        width="16px"
        height="16px"
    >
        <path d="M 7.5 1.035156 C 3.917969 1.035156 1 3.941406 1 7.515625 L 1 8 L 2 8 L 2 7.515625 C 2 4.484375 4.457031 2.035156 7.5 2.035156 C 9.140625 2.035156 10.597656 2.757813 11.605469 3.886719 L 10.464844 5.023438 L 14 5.023438 L 14 1.5 L 12.3125 3.179688 C 11.125 1.867188 9.410156 1.035156 7.5 1.035156 Z M 7.011719 4 L 7.011719 5 L 6.5 5 C 5.675781 5 5 5.675781 5 6.5 C 5 7.324219 5.675781 8 6.5 8 L 8.5 8 C 8.78125 8 9 8.21875 9 8.5 C 9 8.78125 8.78125 9 8.5 9 L 5 9 L 5 10 L 7.011719 10 L 7.011719 11 L 8.011719 11 L 8.011719 10 L 8.5 10 C 9.324219 10 10 9.324219 10 8.5 C 10 7.675781 9.324219 7 8.5 7 L 6.5 7 C 6.21875 7 6 6.78125 6 6.5 C 6 6.21875 6.21875 6 6.5 6 L 9 6 L 9 5 L 8.011719 5 L 8.011719 4 Z M 13 7 L 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 5.859375 13 4.402344 12.277344 3.394531 11.140625 L 4.535156 10 L 1 10 L 1 13.535156 L 2.6875 11.847656 C 3.878906 13.164063 5.589844 14 7.5 14 C 11.085938 14 14 11.085938 14 7.5 L 14 7 Z" />
    </svg>
);
