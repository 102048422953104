import React from 'react';

type LoadingProps = {
    size?: string;
    color?: string;
};

export function Loading({
    size = '20px',
    color = '#ffffff',
}: LoadingProps): React.ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.0"
            width={size}
            height={size}
            viewBox="0 0 128 128"
            xmlSpace="preserve"
        >
            <g>
                <circle cx="16" cy="64" r="16" fill={color} />
                <circle
                    cx="16"
                    cy="64"
                    r="14.344"
                    fill={color}
                    transform="rotate(45 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="12.531"
                    fill={color}
                    transform="rotate(90 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="10.75"
                    fill={color}
                    transform="rotate(135 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="10.063"
                    fill={color}
                    transform="rotate(180 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="8.063"
                    fill={color}
                    transform="rotate(225 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="6.438"
                    fill={color}
                    transform="rotate(270 64 64)"
                />
                <circle
                    cx="16"
                    cy="64"
                    r="5.375"
                    fill={color}
                    transform="rotate(315 64 64)"
                />
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
                    calcMode="discrete"
                    dur="720ms"
                    repeatCount="indefinite"
                ></animateTransform>
            </g>
        </svg>
    );
}
