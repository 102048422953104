import { useWeb3Context } from 'context/Web3Provider';
import { Logger } from 'helpers/logging';
import {
    getDefaultRewards,
    getRewards,
    Rewards,
} from 'helpers/rewards';
import {
    getWalletBankXBalance,
    getXSDBalance,
} from 'helpers/wallet';
import {
    debounce,
    map,
    reduce,
    size,
    some,
} from 'lodash';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useWallet } from './useWallet';

interface WalletValues{
    address: string;
    bankx: string;
    collateralVestingDate: string;
    balance: string;
    maxButtonBalance: string;
    isCollateralVested: boolean;
    weth: string;
    xsd: string;
    rewards: Rewards;
    token: string;
    isLoading: boolean;
    tokenLabel: string;
}

interface WalletFunctions {
    update(key: keyof WalletValues): void;
    updateBalances(): void;
}

const defaultState: WalletValues = {
    address: '-',
    bankx: '-',
    collateralVestingDate: '-',
    balance: '-',
    maxButtonBalance: '-',
    isCollateralVested: false,
    weth: '-',
    xsd: '-',
    rewards: getDefaultRewards(),
    token: '-',
    isLoading: true,
    tokenLabel: '-',
};

export function useGetWalletValues(): WalletValues & WalletFunctions {
    const [state, setState] = useState(defaultState);
    const { contracts, logContracts } = useWeb3Context();
    const {
        address: walletAddress,
        balance,
        isConnected,
        token,
        updateBalances,
        tokenLabel,
    } = useWallet();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propToFunctionMap = useMemo((): Record<string, any> => {
        const {
            bankxTokenContract,
            rewardsContract,
            bankxPoolContract,
            collateralPoolContract,
            xsdPoolContract,
            xsdTokenContract,
        } = contracts;

        // https://ossllc.atlassian.net/browse/BAN-498
        // this is the amount of eth (or native token) to apply when clicking on the max button
        const MAX_BUTTON_MULTIPLIER = 0.98;

        if (walletAddress) {
            logContracts('useGetWalletValues');

            Logger.log('useGetWalletValues: addresses');
            return {
                bankx: () => getWalletBankXBalance(walletAddress, bankxTokenContract),
                balance: () => Promise.resolve(balance),
                maxButtonBalance: () => Promise.resolve(Number(balance) * MAX_BUTTON_MULTIPLIER),
                xsd: () => getXSDBalance(walletAddress, xsdTokenContract),
                rewards: () => getRewards({
                    rewardsContract,
                    xsdPoolContract,
                    bankxPoolContract,
                    collateralPoolContract,
                    walletAddress,
                }),
            };
        }
        return {};
    }, [
        balance,
        walletAddress,
        contracts,
        logContracts,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refreshWallet = useMemo(() => debounce((propsToFunctionMap: Record<string, any>): void => {
        Promise.resolve().then((): Promise<string[][]> => {
            const valuesAsPromises = map(propsToFunctionMap, (promise, key): Promise<string[]> => {
                return promise().then((value: string): string[] => {
                    return [key, value];
                });
            });

            return Promise.all(valuesAsPromises);
        })
            .then((results: string[][]): void => {
                const walletValues = reduce(results, (resultMap, [key, value]) => {
                    return {
                        ...resultMap,
                        [key as string]: value
                    };
                }, { isLoading: false }) as WalletValues;

                walletValues.isCollateralVested = some(walletValues.rewards.collateral, 'isVested');
                walletValues.isLoading = walletValues.balance === '-';

                Logger.log('walletValues: state', walletValues);
                setState(walletValues);
            })
            .catch((error): void => {
                Logger.error(error);
                setState({ ...defaultState });
            })
            .finally((): void => {
                setState((prev) => ({
                    ...prev,
                    isLoading: false
                }));
            });
    }, 500, {
        leading: false,
        trailing: true,
    }), []);

    useEffect(() => {
        if (!isConnected) {
            return;
        }

        setState((prev) => ({
            ...prev,
            isLoading: true
        }));

        if (!size(propToFunctionMap)) {
            setState({ ...defaultState });
            return;
        }

        refreshWallet(propToFunctionMap);
    }, [
        propToFunctionMap,
        isConnected,
        refreshWallet
    ]);

    // use this function to update a property
    const update = useCallback(async(key: keyof WalletValues): Promise<void> => {
        const updater = propToFunctionMap?.[key];

        if (updater) {
            const value = await updater();
            setState(prev => ({
                ...prev,
                [key]: value
            }));
        }

    }, [propToFunctionMap]);

    return {
        ...state,
        address: walletAddress as string,
        token,
        update,
        updateBalances,
        tokenLabel,
    };
}
