import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';

import { toDecimalString } from './number';

export async function getWalletWethBalance(walletAddress: string, wethContract: ethers.Contract): Promise<string> {
    Logger.log('getWalletWethBalance');
    const balance = await wethContract.balanceOf(walletAddress);
    const formatted = toDecimalString(balance, 18);

    Logger.log('weth balance:', balance, formatted);
    return formatted;
}

export async function getWalletBankXBalance(walletAddress: string, bankxTokenContract: ethers.Contract): Promise<string> {
    Logger.log('getWalletBankXBalance');
    const balance = await bankxTokenContract.balanceOf(walletAddress);
    const formatted = toDecimalString(balance, 18);

    Logger.log('bankx balance:', balance, formatted);
    return formatted;
}

export async function getXSDBalance(walletAddress: string, xsdTokenContract: ethers.Contract): Promise<string> {
    Logger.log('getXSDBalance');
    const balance = await xsdTokenContract.balanceOf(walletAddress);
    const formatted = toDecimalString(balance, 18);

    Logger.log('xsd balance:', balance, formatted);
    return formatted;
}

export function isVested(date: string): boolean {
    if (date === '-') {
        return false;
    }

    const ts = Number(new Date(date));

    return Date.now() >= ts;
}
