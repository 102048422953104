import { useConnectWallet } from '@web3-onboard/react';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import ReferralBanner from 'components/ReferralBanner';
import ReferralFooter from 'components/ReferralFooter';
import { SwapBox } from 'components/SwapBox';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { TokenLabels } from 'helpers/labels';
import useOnScreen from 'hooks/useOnScreen';
import useRecursiveTimeout from 'hooks/useRecursiveTimeout';
import { CurrentPrices } from 'pages/Silver';
import React, {
    useCallback, useRef, useState
} from 'react';

function XSDPage() {
    const {
        silverPrice,
        xsdPrice,
        priceDifference: priceDifferenceOutput,
    } = useGlobalValues();
    const [{ wallet }, connect] = useConnectWallet();
    const userAddress = wallet?.accounts[0].address;

    // video autoplay
    const [playing, setPlaying] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);
    const playVideo = useCallback(() => {
        if (isVisible && !playing) {
            setPlaying(1);
        }
    }, [
        setPlaying,
        isVisible,
        playing
    ]);
    useRecursiveTimeout(playVideo, 100);

    return (
        <DashboardLayout
            banner={<ReferralBanner route='xsd' connect={connect} />}
            hasSidebar={false}
            hasExandedLogo
            isDarkThemeOverride={false}
            hasDarkmodeBtn={false}
        >
            <link rel='stylesheet' href='/css/landing.css' />
            <div className='landing_container'>
                <h1>Have you lost money with stablecoins?</h1>
                <h2 className='blueText'>
          Or just don&apos;t know the right one to choose?
                </h2>
                <br />
                <br />
                <h4>STABLECOINS ON THE MARKET HAVE LOTS OF PROBLEMS:</h4>
                <ul>
                    <li>
                        <u>Terra Luna</u>, with <u>no collateral</u>, lost $60B as it{' '}
                        <b>went to ZERO</b>
                        <u>.</u>
                    </li>
                    <li>
                        <u>Silicon Valley Bank</u>, holding collateral for USDC,{' '}
                        <b>went bankrupt</b>
                        <u>.</u>
                    </li>
                    <li>
                        <u>USDC</u> has been estimated to earn{' '}
                        <b>$1.6B a year using YOUR money</b>
                        <u>.</u>
                    </li>
                    <li>
            Centralized stablecoins, like <u>USDT</u>, <b>can be SHUT DOWN</b>
                        <u>.</u>
                    </li>
                    <li>
            Other collateral-backed stablecoins like <u>DAI</u>{' '}
                        <b>can be LIQUIDATED</b>
                        <u>.</u>
                    </li>
                    <li>
                        <u>USD stablecoins</u> face{' '}
                        <b>tremendous LOSS in purchasing power</b> from inflation.
                    </li>
                </ul>
                <br />
                <br />
                <div
                    style={{
                        maxWidth: 655,
                        width: '100%',
                    }}
                >
                    <h5 className='bankXis'>BankX is...</h5>
                </div>
                <ul className='noBullet'>
                    <li>
                        <u>Silver-Pegged</u> to hedge against <b>inflation</b>.
                    </li>
                    <li>
                        <u>Crypto-Backed</u> so there is value you can{' '}
                        <b>redeem anytime.</b>
                    </li>
                    <li>
            A <u>Blockchain-controlled collateral</u> system{' '}
                        <b>only accessed by you.</b>
                    </li>
                    <li>
            Designed so the <u>collateral</u> backing it is{' '}
                        <b>never liquidated.</b>
                    </li>
                    <li>
                        <u>Fully decentralized</u> so it <b>cannot be shut down.</b>
                    </li>
                    <li>
            A <u>stablecoin</u> where{' '}
                        <b>YOU earn the interest for minting it.</b>
                    </li>
                </ul>
                <br />
                <br />
                <h3>
                    <b>
            Whenever the price of the XSD stablecoin is below the price of 1
            gram of silver, you are buying it at a discount.
                    </b>
                </h3>
                <br />
                <div ref={ref}>
                    <iframe
                        width='560'
                        height='315'
                        src={`https://www.youtube.com/embed/LHl6I1zesyM?rel=0&amp;autoplay=${playing}&mute=1`}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        className='youtubeVideo'
                    />
                </div>
                <br />
                <br />
                <h5>It is easy to do and takes 2 steps:</h5>
                <ol>
                    <li>
            Connect your METAMASK wallet on this web page. Don’t have a
            METAMASK? Click here: <a href='https://metamask.io/'>metamask.io</a>
                    </li>
                    <li>
            Use the SWAP box below to trade for the XSD stablecoin.
                    </li>
                </ol>

                <CurrentPrices
                    {...{
                        silverPrice,
                        xsdPrice,
                        priceDifferenceOutput,
                        userAddress
                    }}
                />
                <SwapBox
                    disableDarkTheme={true}
                    forcedSourceToken={TokenLabels.Erc}
                    forcedTargetToken={TokenLabels.Xsd}
                    hasLandingPageRedirect
                    withLiquidityPoolInfo={false}
                />
                <br />
                <br />
                <h3>
          That is it!
                    <br />
          You now own the best designed
                    <br />
          and decentralized stablecoin on the market.
                </h3>
                <ReferralFooter userAddress={userAddress} route='xsd' />
                <button onClick={() => {
                    window.open('https://docs.bankx.io/', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Still Have More Questions?
                </button>
                <button onClick={() => {
                    window.open('https://t.me/BankXio', '_blank', 'noopener noreferrer');
                    return;
                }} className='button-1' style={{
                    marginTop: 20,
                    maxInlineSize: 'fit-content',
                    padding: '0px 20px'
                }}>
                    Join The Conversation
                </button>
            </div>
        </DashboardLayout>
    );
}

export default XSDPage;
