import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { getThemedClassName } from 'helpers/theme';
import { useWallet } from 'hooks/useWallet';
import React from 'react';
import { useEffect } from 'react';

import Header from './Header/Header';
import { Sidebar } from './Sidebar';

type DashboardLayoutProps = {
    hasSidebar?: boolean;
    hasExandedLogo?: boolean;
    isDarkThemeOverride?: boolean;
    banner?: React.ReactNode;
    hasDarkmodeBtn?: boolean;
    hasConnectButton?: boolean;
} & React.PropsWithChildren;

// eslint-disable-next-line react/prop-types
export function DashboardLayout({
    children,
    hasSidebar = true,
    hasExandedLogo = false,
    isDarkThemeOverride,
    banner,
    hasDarkmodeBtn = true,
    hasConnectButton = true,
}: DashboardLayoutProps) {
    const { isConnected } = useWallet();
    const { isDarkTheme } = useAppSettings();
    const useDarkTheme = isDarkThemeOverride ?? isDarkTheme;

    useEffect(() => {
        const $body = document.getElementById('body');
        if (useDarkTheme) {
      // eslint-disable-next-line
      $body?.classList.add('body-dark')
      // eslint-disable-next-line
      $body?.classList.remove('body')
        } else {
      // eslint-disable-next-line
      $body?.classList.add('body')
      // eslint-disable-next-line
      $body?.classList.remove('body-dark')
        }
    }, [useDarkTheme]);

    return (
        <div>
            {useDarkTheme && <link rel='stylesheet' href='/css/dark.min.css' />}

            <div className={cx('page-wrapper', { 'page-wrapper-with-wallet': isConnected })}>
                <div className='page-box'>
                    <Header
                        hasExandedLogo={hasExandedLogo}
                        isDarkTheme={useDarkTheme}
                        hasDarkmodeBtn={hasDarkmodeBtn}
                        hasConnectButton={hasConnectButton}
                    />
                    {banner}
                    <div className={cx(getThemedClassName('page-section', useDarkTheme), { 'page-section-with-wallet': isConnected })}>
                        {hasSidebar && <Sidebar />}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
