import { AddressMap } from './types';

export const FantomAddresses: AddressMap = {
    BankXToken:           '0x2147F5c02c2869E8C2d8F86471d3d7500355d698',
    XSDToken:             '0x93Abe713002526D4cE708f890139f19313012f95',
    BankXPool:            '0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683',
    XSDPool:              '0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2',
    CollateralPool:       '0xcc2373070B5012Cc03675a0e063E8eD69FdCAf4F',
    PIDController:        '0x41e9f4c0E1882B69834Af8f01b951384EbE0EbCD',
    RewardManager:        '0xAA3216155eA830ddE783532e7d1a886Ea1c09Aa2',
    RouterAddress:        '0x722258DBF683c76D616A1a215a9600a565085F23',
    Arbitrage:            '0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C',
    CertificateOfDeposit: '0xc79461C653bCd0137D062ec87B9b821f7c9bCc26',
    BankXNFT:             '0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C',
    NFTBonus:             '0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d',
    XAGUSDPriceConsumer:  '0xeeA52F6587F788cc12d0b5a28c48e61866c076F0',
    Proxy:                '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
};
// 11/30/2024 1231DT
// BankX Token address:     0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:  0x93Abe713002526D4cE708f890139f19313012f95
// BankX pool address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD pool address:        0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool:         0xcc2373070B5012Cc03675a0e063E8eD69FdCAf4F
// PID:                     0x41e9f4c0E1882B69834Af8f01b951384EbE0EbCD
// Reward Manager address:  0xAA3216155eA830ddE783532e7d1a886Ea1c09Aa2
// Router address:          0x722258DBF683c76D616A1a215a9600a565085F23
// Arbitrage address:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:              0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:        0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:        0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d

// 10/07/2024 0811PDT
// BankX Token address:     0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:  0x93Abe713002526D4cE708f890139f19313012f95
// BankX pool address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD pool address:        0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool address: 0x4C1257f4ED1797bC91e45c855875128e723b61a9
// PID Controller address:  0x821327C122C4AA2314393785ABf5Af7Fb04F7A58
// Reward Manager address:  0xAA3216155eA830ddE783532e7d1a886Ea1c09Aa2
// Router address:          0x722258DBF683c76D616A1a215a9600a565085F23
// Arbitrage address:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:              0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:        0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:        0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d

// 10/04/2024 1524PDT
// BankX Token address:     0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:  0x93Abe713002526D4cE708f890139f19313012f95
// BankX pool address:      0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSD pool address:        0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool address: 0x4C1257f4ED1797bC91e45c855875128e723b61a9
// PID Controller address:  0x3f83f15521643De6aEFe7A16571332180bf09cD1
// Reward Manager address:  0xAA3216155eA830ddE783532e7d1a886Ea1c09Aa2
// Router address:          0x722258DBF683c76D616A1a215a9600a565085F23
// Arbitrage address:       0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:              0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:        0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:        0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d

// 8/26/2024 1944 PDT
// BankX Token address:       0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankXFTM pool address:     0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSDFTM pool address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool address:   0x06c222c5C417D4106C9b55458820CF8ccA86511e
// PID Controller address:    0x7608aa0791B06A57a3BCC2860176357575c68Cf5
// Reward Manager address:    0xAA3216155eA830ddE783532e7d1a886Ea1c09Aa2
// Router address:            0x722258DBF683c76D616A1a215a9600a565085F23
// Arbitrage address:         0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:                0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:          0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:          0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d

// 20240625 1424PDT
// BankX Token address:       0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankXFTM pool address:     0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSDFTM pool address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool address:   0x45B07536b320297eBf525a87373A13dAf10b17f3
// PID Controller address:    0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Reward Manager address:    0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Router address:            0xABdAF10CCFe2bC9C0CC086671F70b2041b6FA076
// Arbitrage address:         0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:                0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:          0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:          0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d
// Pyth XAG USD price oracle: 0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// Proxy (pyth verification): 0xff1a0f4744e8582DF1aE09D5611b887B6a12925C
// Claim App:                 0xcE1664AdA6F272D7B8886D2277E6Ea5FF4035EC7
// Chainlink ETH USD oracle:  0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e

// 20240612 2102PDT
// BankX Token address:       0x2147F5c02c2869E8C2d8F86471d3d7500355d698
// XSD StableCoin address:    0x93Abe713002526D4cE708f890139f19313012f95
// BankXFTM pool address:     0x53f51fcDf06946AafE25F14d2f1C9B66E71Ca683
// XSDFTM pool address:       0xaB1c27a3B78d9afCDe9963780af4Ff48D6b816A2
// Collateral Pool address:   0x45B07536b320297eBf525a87373A13dAf10b17f3
// PID Controller address:    0x58421507d10A4c57a761E8AAd5382D5564A682F5
// Reward Manager address:    0x1ff77D8e8e011bCF505cd4C6C110b53969FB5E84
// Router address:            0x82B403e35D393174f33Ac8c72147D2BB3dC5Cc06
// Arbitrage address:         0xE273a6ca1BAc1a86568647BB67aAb4d23bb3425C
// CD address:                0xc79461C653bCd0137D062ec87B9b821f7c9bCc26
// BankXNFT address:          0xBb1Fb7ed114BCeFb327c732aA9D4943658d2b85C
// NFTBonus address:          0x74f04Dd3eC432dECB3f7E3b4E64ef499efcE8D4d
// Pyth XAG USD price oracle: 0xeeA52F6587F788cc12d0b5a28c48e61866c076F0
// Chainlink ETH USD oracle:  0xAbAc1C04408Cb6509BC9340e6b033c17F161Ef9e
