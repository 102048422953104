import { CopyIcon, CopyText } from 'components/CopyText';
import { formatToYYYYMMDD } from 'helpers/date';
import { formatWalletAddress } from 'helpers/formatting';
import { useGetReferralLink, useReferrals } from 'hooks/useReferrals';
import React from 'react';

type ReferralFooterProps = {
    userAddress?: string | null,
    copyType?: 'NFT' | 'DEFAULT',
    route: string;
}

export default function ReferralFooter({
    userAddress,
    copyType = 'DEFAULT',
    route
}: ReferralFooterProps): React.ReactElement {
    const { link: referralLink } = useGetReferralLink(route);
    const { referrals, xsdEarned } = useReferrals();

    // Temporarily removing Referral Footer from XSD & Silver pages
    if (route === 'xsd' || route === 'silver') {
        return <></>;
    }

    return (
        <div className="referralFooter">
            <h4>Referral Program</h4>
            {copyType === 'DEFAULT' && (
                <>
                    <p>
                For each person that you refer that clicks on your referral link to
                purchase XSD, you will receive 10% paid in XSD.
                    </p>
                    <p>
                You will receive 10% of the amount of XSD you purchased in XSD for
                clicking on someone’s referral link.
                    </p>
                    <p>
                Once we reach the goal of $4M in our XSD liquidity pool, the referral
                program will end and you will be able to claim your XSD using our claim
              app. Join our telegram or discord for announcements for that.
                    </p>
                </>
            )}
            {copyType === 'NFT' && (
                <>
                    <p>
                        Instantly earn 10% of every NFT purchase that you refer.  Connect your wallet.  Get your unique referral link and share it everywhere.  When someone clicks on it, connects their wallet and makes a purchase, you will instantly be paid 10% in ETH or BNB depending on which chain the purchase occurred.  <b>At the time of purchase, the NFT smart contract will immediately send your commission at the time of every sale.</b>  The commission will be sent to the wallet address that you have connected here.  Your referral link is made from your wallet address.  Any questions, go to our Telegram Support group:  @BankXSupport
                    </p>
                    <br />
                    <a className='button-1' href="/#/nft-leaderboard" target='_blank' rel='noreferrer noopener'>
                        NFT Leaderboard
                    </a>
                </>
            )
            }
            {userAddress && (
                <div className="referralFooterDetails">
                    <p><b>Your wallet:</b> {userAddress}</p>
                    <p>
                        <b>Your referral link:</b>{' '}
                        <CopyText text={referralLink}>
                            {referralLink} <CopyIcon />
                        </CopyText>
                    </p>
                    {
                        copyType === 'DEFAULT' && <>
                            <p><b>Total XSD Earned: {xsdEarned.toFixed(2)}</b></p>
                            <p><b>Transactions:</b></p>
                            <table className="transactionsTable">
                                <thead>
                                    <tr>
                                        <th>Referred Wallet</th>
                                        <th>Date</th>
                                        <th>XSD Purchased</th>
                                        <th>XSD You Earned</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        referrals.map((referral, key): React.ReactElement => {
                                            return (
                                                <tr key={key}>
                                                    <td>{formatWalletAddress(referral.address)}</td>
                                                    <td>{formatToYYYYMMDD(referral.date)}</td>
                                                    <td>{referral.value.toFixed(2)}</td>
                                                    <td>{referral.reward.toFixed(2)}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            )}
        </div>
    );
}
