import { getSacAppBaseApiUrl } from 'helpers/api';
import { forEach } from 'lodash';
import { useEffect, useState } from 'react';

import { useWallet } from './useWallet';

export interface LeaderboardEntry {
    address: string;
    amount: number;
    isUser?: boolean;
}

export interface NftLeaderboard {
    // TODO: api currently does not limit to 5
    currentWeekTop5: LeaderboardEntry[];
    // TODO: api currently does not limit to 5
    prevWeekTop5: LeaderboardEntry[];
    overall: LeaderboardEntry[];
}

export const defaultState: NftLeaderboard = {
    currentWeekTop5: [],
    prevWeekTop5: [],
    overall: [],
};

function setUserFlag(userAddress: string | null): (data: NftLeaderboard) => NftLeaderboard {
    return function(leaderboardData: NftLeaderboard): NftLeaderboard {
        if (!userAddress) {
            return leaderboardData;
        }

        const address = userAddress.toLowerCase();
        forEach(leaderboardData, (entries): void => {
            forEach(entries, (entry): void => {
                entry.isUser = entry.address.toLowerCase() === address;
            });
        });

        return leaderboardData;
    };
}

async function _fetchNftLeaderboard(chain: string): Promise<NftLeaderboard> {
    const url = getSacAppBaseApiUrl();
    const qsp = new URLSearchParams({
        chain,
    });
    const response = await fetch(`${url}/leaderboard/nft?${qsp}`);
    return response.json();
}

async function _fetchNftLeaderboardSampleData(): Promise<NftLeaderboard> {
    return {
        currentWeekTop5: [
            {
                amount: 7,
                address: 'These are all test data',
            },
            {
                amount: 6,
                address: '0xb347e5bab17f7bb0ff291d09123e2b9d626ec8bb'
            },
            {
                amount: 4,
                address: '0xe5c452Cb1CE3c175BA1cEb534450C9612d3aB3cf',
            }
        ],
        prevWeekTop5: [{
            amount: 2,
            address: '0xc347e5bab17f7bb0ff291d09123e2b9d626ec8bb'
        }],
        overall: [
            {
                amount: 7,
                address: 'These are all test data',
            },
            {
                amount: 6,
                address: '0xb347e5bab17f7bb0ff291d09123e2b9d626ec8bb'
            },
            {
                amount: 4,
                address: '0xe5c452Cb1CE3c175BA1cEb534450C9612d3aB3cf',
            },
            {
                amount: 2,
                address: '0xc347e5bab17f7bb0ff291d09123e2b9d626ec8bb'
            }
        ]
    };
}

async function fetchNftLeaderboard(chain: string): Promise<NftLeaderboard> {
    if (window.location.hostname === 'localhost') {
        return _fetchNftLeaderboardSampleData();
    }

    return _fetchNftLeaderboard(chain);
}

export function useGetNftLeaderboard(): NftLeaderboard {
    const [state, setState] = useState(defaultState);
    const { chain, address } = useWallet();

    useEffect((): void => {
        if (chain) {
            fetchNftLeaderboard(chain).then(setUserFlag(address)).then(setState);
        }
    }, [address, chain]);

    return state;
}
