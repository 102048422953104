import { Loading } from 'assets/Loading';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { SwapBox } from 'components/SwapBox';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import React  from 'react';

export function ExchangePage(): React.ReactElement {
    const { getThemedClass: tcx, isDarkTheme } = useAppSettings();
    const { areValuesLoading, topCards } = useGlobalValues();

    const color = isDarkTheme ? '#fff' : '#1882c4';

    return (
        <DashboardLayout>
            <div className={tcx('content-wrapper')}>
                <TopCards />
                { areValuesLoading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20%',
                    }}>
                        <Loading color={color} size='100px' />
                    </div>
                ) : (
                    <SwapBox defaultSwapAction={topCards.differential.action}/>
                )}
            </div>
        </DashboardLayout>
    );
}
