import React from 'react';

import Styles from './styles.module.sass';

export function SuccessIcon(): React.ReactElement {
    return (
        <div className={Styles.successAnimation}>
            <svg className={Styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className={Styles.checkmarkCircle}
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                />
                <path className={Styles.checkmarkCheck}
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
            </svg>
        </div>
    );
}
