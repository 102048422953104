import { getSacAppBaseApiUrl } from 'helpers/api';

export async function saveReferrer(userAddress: string, referrerAddress: string): Promise<boolean> {
    const url = getSacAppBaseApiUrl();
    const body = {
        referrer_address: referrerAddress,
        recipient_address: userAddress,
    };

    const response = await fetch(`${url}/referral`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(body)
    });

    return response.ok;
}
