import React from 'react';

export const PricingSvg: React.ReactNode = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17.1'
        height='17.1'
        viewBox='0 0 17.1 17.1'
    >
        <path
            id='Path_2389'
            data-name='Path 2389'
            d='M98.556,28a.557.557,0,0,0-.557.557V44.142a.557.557,0,0,0,.557.557h15.587a.557.557,0,1,0,0-1.113H99.112V28.556A.557.557,0,0,0,98.556,28Zm11.133,4.268a.6.6,0,0,0-.586.586.579.579,0,0,0,.586.528h1.571l-4.935,4.639s-1.215-1.06-1.774-1.479-.29-.209-.464-.2a.562.562,0,0,0-.336.145l-3.711,3.34a.557.557,0,1,0,.742.829l3.357-3.021,1.786,1.49a.581.581,0,0,0,.806.041l5.369-5.045v1.67a.557.557,0,1,0,1.113,0V32.823a.578.578,0,0,0-.557-.557h-2.969Z'
            transform='translate(-97.799 -27.799)'
            fill='#01303c'
            stroke='#01303c'
            strokeWidth='0.4'
        />
    </svg>
);
