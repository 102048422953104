import 'swiper/swiper-bundle.min.css';
import './styles.sass';

import { Routes } from 'components/Routes';
import { TextInputProvider } from 'components/TextInput';
import { AppSettingsProvider } from 'context/AppSettingsProvider';
import { ContractValuesProvider } from 'context/ContractValuesProvider';
import { ModalProvider } from 'context/ModalContext';
import { NftLeaderboardProvider } from 'context/NftLeaderboardProvider';
import { ReferralsProvider } from 'context/ReferralsProvider';
import { Web3Provider } from 'context/Web3Provider';
import { initOnboard } from 'helpers/web3';
import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';

initOnboard({ autoConnect: true });

export function App() {

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            setTimeout(function removeIframeBlockingMouseEvents() {
                // TODO:
                const iframes = document.querySelectorAll('iframe');
                if (iframes[0]) {
                // eslint-disable-next-line
                iframes[0].parentNode?.removeChild(iframes[0]);
                }
            }, 3000);
        }
    }, []);

    return (
        <AppSettingsProvider>
            <Web3Provider>
                <ContractValuesProvider>
                    <ModalProvider>
                        <TextInputProvider>
                            <HashRouter>
                                <ReferralsProvider>
                                    <NftLeaderboardProvider>
                                        <Routes/>
                                    </NftLeaderboardProvider>
                                </ReferralsProvider>
                            </HashRouter>
                        </TextInputProvider>
                    </ModalProvider>
                </ContractValuesProvider>
            </Web3Provider>
        </AppSettingsProvider>
    );
}
