import cx from 'classnames';
import { HexAddress } from 'components/HexAddress';
import { LeaderboardEntry, useNftLeaderboard } from 'context/NftLeaderboardProvider';
import React from 'react';

import Styles from './styles.module.scss';

interface LeaderboardListProps {
    entries: LeaderboardEntry[];
    limit?: number;
}

function EmptyRow(): React.ReactElement {
    return (
        <tr>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
    );
}

function LeaderboardList({ entries, limit }: LeaderboardListProps): React.ReactElement {
    const l = limit ?? entries.length;
    const showEmptyRow = entries.length === 0;

    return (
        <table className={Styles.list}>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Referrer Address</th>
                    <th>NFTs Referred</th>
                </tr>
            </thead>
            <tbody></tbody>
            {entries.slice(0, l).map((entry, index): React.ReactElement => {
                const c = cx({ [Styles.user]: entry.isUser });
                const rank = index + 1;

                return (
                    <tr key={index} className={c}>
                        <td>{rank}</td>
                        <td><HexAddress address={entry.address} /></td>
                        <td>{entry.amount}</td>
                    </tr>
                );
            })}
            {showEmptyRow && <EmptyRow />}
        </table>
    );
}

export function NftLeaderboard(): React.ReactElement {
    const data = useNftLeaderboard();

    return (
        <div className={cx(Styles.leaderboard, 'p-4')}>
            <div className={Styles.title}>
                NFT Referrals Leaderboard
            </div>
            <section className={Styles.leaderboardSection}>
                <div className={cx(Styles.listTitle, 'pb-2')}>Current Week</div>
                <LeaderboardList entries={data.currentWeekTop5} limit={5}/>
            </section>
            <section className={Styles.leaderboardSection}>
                <div className={cx(Styles.listTitle, 'pb-2')}>Overall</div>
                <LeaderboardList entries={data.overall} limit={5}/>
            </section>
        </div>
    );
}
