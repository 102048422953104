import cx from 'classnames';
import { useAppSettings } from 'context/AppSettingsProvider';
import { getThemedClassName } from 'helpers/theme';
import React from 'react';

import Styles from './RewardsGroup.module.sass';

interface RewardsGroupProps {
    callToAction: React.ReactNode;
    rewardsInfo: React.ReactNode;
    summary: React.ReactNode;
    withdraw: React.ReactNode;
    theme?: 'default' | 'wide-withdraw';
}

export function RewardsGroup(props: React.PropsWithChildren<RewardsGroupProps>): React.ReactElement {
    const { isDarkTheme } = useAppSettings();
    const themeClassName = {
        [Styles.extended]: props.theme === 'wide-withdraw',
    };

    return (
        <div className={cx(getThemedClassName('main-card', isDarkTheme), getThemedClassName('card-bg', isDarkTheme))}>
            <div className={cx(Styles.topRow, 'row')}>
                <div className={cx(Styles.callToAction, themeClassName)}>
                    <div className={cx(getThemedClassName('white-card', isDarkTheme), 'wc-1')}>
                        {props.callToAction}
                    </div>
                </div>
                <div className={cx(Styles.withdraw, themeClassName)}>
                    <div className={cx(Styles.withdrawContent, getThemedClassName('white-card', isDarkTheme), 'wc-2')}>
                        {props.withdraw}
                    </div>
                </div>
            </div>
            <div className={cx(Styles.bottomRow, 'row', getThemedClassName('row-status', isDarkTheme), themeClassName)}>
                <div className={Styles.left}>
                    {props.summary}
                </div>
                <div className={Styles.right}>
                    {props.rewardsInfo}
                </div>
            </div>
        </div>
    );
}
