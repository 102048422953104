import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';

import {
    mapToBigNumber,
    toBigIntString,
    toDecimalString,
    truncateToPrecision,
} from './number';
import { SHOULD_USE_PRICE_CHECK, systemCalculations } from './pool';
import { getDeadline } from './deadline';
import { isProd } from './env';
import { getFractionalMintRequirements } from './minting';

/**
 * Redemption is a 2-step process: calculate -> execute
 */
/**
 * This function is part 1 of the redemption process. It set per user variables for the collectRedemption function.
 * redeemOneToOne -> currently gives user interest(BankX) -> does not get wrapped ether back
 * fix may be required on backend -> it should not return bankx with this function but it currently does
 */
export async function redeemXSD(
    collateralPercentage: number | string,
    xsdAmount: number | string,
    expectedEth: string,
    expectedBankx: string,
    collateralPoolContract: ethers.Contract,
    xsdTokenContract: ethers.Contract,
    pidContract: ethers.Contract,
    walletProvider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
    chain: string,
    proxyContract: ethers.Contract,
    priceCheckBlockDelay: number,
    getUpdatedAmounts:(expectedXsd: string, bankxPriceInUSD: string, updatedNeededBankx: string, updatedNeededEth: string) => ReturnType<typeof calculateRedemptionValues>,
): Promise<void> {
    // conversion rate: 1 xsd for 1 dollar of collateral (bankx)
    const ratio = Number(collateralPercentage);
    const deadline = await getDeadline(walletProvider);
    const decimals = 18;

    const signer = walletProvider.getSigner();

    // these are the original amounts
    const xsd = toBigIntString(xsdAmount, decimals);
    let eth = toBigIntString(expectedEth, decimals);
    let bankx = toBigIntString(expectedBankx, decimals);
    Logger.log('redeemXSD xsdAmount:', xsdAmount, xsd);
    Logger.log('redeemXSD expected eth:', expectedEth, eth);
    Logger.log('redeemXSD expected bankx:', expectedBankx, bankx);

    // 1st approval - price check
    goToNextLoadingMessage(CustomLoadingMessage.PriceCheck);
    const { bankx: updatedBankxPrice } = await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay, SHOULD_USE_PRICE_CHECK);

    // 2nd approval - approve amount
    goToNextLoadingMessage(CustomLoadingMessage.AwaitingXSDApproval);
    const xsdReceipt = await xsdTokenContract.connect(signer).approve(collateralPoolContract.address, xsd);
    Logger.log('redeemXSD xsdReceipt:', xsdReceipt);

    // 3rd approval - this step arms the collect redemption method
    goToNextLoadingMessage(CustomLoadingMessage.ConfirmSendingXSD);
    let collateralReceipt = null;
    if (ratio === 1) {
        collateralReceipt = await collateralPoolContract.connect(signer).redeem1t1XSD(xsd, eth, deadline);
    } else if (ratio === 0) {
        collateralReceipt = await collateralPoolContract.connect(signer).redeemAlgorithmicXSD(xsd, bankx, deadline);
    } else {
        // these are the updated amounts after the priceCheck
        const { neededBankx: updatedNeededBankx, neededEth: updatedNeededEth } = await getFractionalMintRequirements(pidContract);
        const { bankx: updatedBankxAmount, eth: updatedEthAmount } = getUpdatedAmounts(xsdAmount as string, updatedBankxPrice, updatedNeededBankx, updatedNeededEth);
        bankx = toBigIntString(updatedBankxAmount, decimals);
        eth = toBigIntString(updatedEthAmount, decimals);
        Logger.log('redeemXSD expected eth after priceCheck:', updatedEthAmount, eth);
        Logger.log('redeemXSD expected bankx after priceCheck:', updatedBankxAmount, bankx);

        collateralReceipt = await collateralPoolContract.connect(signer).redeemFractionalXSD(xsd, bankx, eth, deadline);
    }

    await collateralReceipt.wait(2);
    Logger.log('redeemXSD collateralReceipt', collateralReceipt);
}

/**
 * This function is part 2 of the redemption process. It executes the values set in the 1st part of the process.
 * collectRedemption - collateralPool.collectRedemption() checks if owed any collateral
 * this function should be called after redeemOneToOne
 * (shared by all 3 types of redeem functions)
 */
export async function collectRedemption(
    collateralPoolContract: ethers.Contract,
    pidContract: ethers.Contract,
    walletProvider: ethers.providers.Web3Provider,
    goToNextLoadingMessage: (message?: string) => void,
    chain: string,
    proxyContract: ethers.Contract,
    priceCheckBlockDelay: number,
) {
    Logger.log('collectRedemption invoked');
    const signer = walletProvider.getSigner();
    goToNextLoadingMessage(CustomLoadingMessage.PriceCheck);
    await systemCalculations(pidContract, proxyContract, signer, chain, priceCheckBlockDelay, SHOULD_USE_PRICE_CHECK);

    // conversion rate: 1 xsd for 1 dollar of collateral (bankx)
    // approval
    goToNextLoadingMessage();
    const receipt = await collateralPoolContract.connect(signer).collectRedemption();
    Logger.log('collectRedemption receipt', receipt);

    // redemption
    goToNextLoadingMessage(CustomLoadingMessage.CollectingRedemption);
    await receipt.wait(1);
}
/*
    algorithmic returns only bankx
    fractional returns eth and bankx

    collateral ratio
    redeem1t1 - all eth, no bankx, bankx interest
    redeemAlgorithmicXSD - all bankx, bankx interest
    redeemFractionalXSD - eth and bankx, bankx interest

    1t1 is here
    https://github.com/Lance-Parker/BankX/blob/main/contracts/XSD/Pools/CollateralPoolLibrary.sol

    fractional and algorithmic
    https://github.com/Lance-Parker/BankX/blob/main/contracts/XSD/Pools/CollateralPool.sol

    function calcRedeem1t1XSD(uint256 col_price_usd, uint256 XSD_amount) public pure returns (uint256) {
    col_price_usd is eth price in usd
        return XSD_amount.mul(1e6).div(col_price_usd);
    }
*/

export async function checkIfUserHasPendingRedemption(collateralPoolContract: ethers.Contract, walletAddress: string): Promise<boolean> {
    const [pendingRedemption, pendingBankxRedemption] = await Promise.all([
        collateralPoolContract?.redeemCollateralBalances(walletAddress),
        collateralPoolContract?.redeemBankXBalances(walletAddress),
    ]);

    const formatted = toDecimalString(pendingRedemption, 18);
    const formattedBankx = toDecimalString(pendingBankxRedemption, 18);

    Logger.log('checkIfUserHasPendingRedemption', { formatted, pendingRedemption, formattedBankx, pendingBankxRedemption });
    return formatted !== '0.0' || formattedBankx !== '0.0';
}
interface RedemptionValues {
    bankx: string;
    eth: string;
    interest: string;
}
interface RedemptionValuesConfig {
    accumulatedInterest: string;
    bankxPriceInUSD: string;
    collateralRatio: string;
    ethPriceInUSD: string;
    silverPricePerGram: string;
    xsdToRedeem: string;
    xsdAvailable: string;
    neededBankx: string;
    neededEth: string;
}

function calculateInterest(config: RedemptionValuesConfig): string {
    const decimals = 18;

    const converted = mapToBigNumber({
        ...config,
        one:'1',
    }, decimals);

    const {
        accumulatedInterest,
        bankxPriceInUSD,
        one,
        xsdAvailable,
        xsdToRedeem,
    } = converted;

    const usd = accumulatedInterest.mul(xsdToRedeem).div(xsdAvailable);
    const bankx = usd.mul(one).div(bankxPriceInUSD);
    const result = formatUnits(bankx, decimals);

    Logger.log('calculateInterest:', 'conerted:', converted, 'config:', config, 'usd:', accumulatedInterest, 'bankx:', result);

    return result;
}

function calculate1t1(config: RedemptionValuesConfig): RedemptionValues {
    const decimals = 18;
    const {
        ethPriceInUSD,
        silverPricePerGram,
        xsdToRedeem,
    } = mapToBigNumber(config, decimals);

    // xsd * silver / ethUSD
    const collateralAmount = xsdToRedeem.mul(silverPricePerGram).div(ethPriceInUSD).or('0');

    return {
        bankx: '0',
        eth: formatUnits(collateralAmount, decimals),
        interest: calculateInterest(config),
    };
}
function calculateAlgorithmic(config: RedemptionValuesConfig): RedemptionValues {
    const decimals = 18;
    const {
        xsdToRedeem,
        bankxPriceInUSD: bankx,
        silverPricePerGram: silver,
    } = mapToBigNumber(config, decimals);

    const collateralAmount = silver.mul(xsdToRedeem).div(bankx).or('0');

    return {
        bankx: formatUnits(collateralAmount, decimals),
        eth: '0',
        interest: calculateInterest(config),
    };
}
function calculateFractional(config: RedemptionValuesConfig): RedemptionValues {
    const bankxRequired = Number(config.xsdToRedeem) * Number(config.neededBankx);
    const ethRequired = Number(config.xsdToRedeem) * Number(config.neededEth);

    return {
        bankx: truncateToPrecision(bankxRequired, 4),
        eth: truncateToPrecision(ethRequired, 4),
        interest: calculateInterest(config),
    };
}

export function calculateRedemptionValues(config: RedemptionValuesConfig): RedemptionValues {
    Logger.log('calculateRedemptionValues: config', config);
    try {
        if (!config.xsdToRedeem) {
            throw new Error();
        }
        let result: RedemptionValues;

        const ratio = Number(config.collateralRatio);

        if (ratio === 1) {
            result = calculate1t1(config);
        } else if (ratio === 0) {
            result = calculateAlgorithmic(config);
        } else {
            result = calculateFractional(config);
        }

        Logger.log('calculateRedemptionValues: result', result);

        return result;
    } catch {

        return {
            bankx: '-',
            eth: '-',
            interest: '-',
        };
    }

}

// https://ossllc.atlassian.net/browse/BAN-467
// redeem is available if the price of XSD is at most 5% above the price of silver
// XSD <= silver * 1.05
export function checkIfRedeemIsDisabled(silverPrice: number, xsdPrice: string): boolean {
    const isEnabled = Number(xsdPrice) <= silverPrice * 1.05;

    const isProduction = isProd();
    Logger.log('checkIfRedeemIsDisabled:', {
        silverPrice,
        xsdPrice,
        isEnabled,
        isProduction
    });

    // only disable in production
    return !isEnabled && isProduction;
}
