import {
    defaultState,
    NftLeaderboard,
    useGetNftLeaderboard,
} from 'hooks/useGetNftLeaderboard';
import React, { createContext, useContext } from 'react';

// for convenience
export type { LeaderboardEntry } from 'hooks/useGetNftLeaderboard';

const Context = createContext<NftLeaderboard>(defaultState);

export function NftLeaderboardProvider({ children }: React.PropsWithChildren): React.ReactElement {
    const value = useGetNftLeaderboard();

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    );
}

export function useNftLeaderboard(): NftLeaderboard {
    return useContext(Context);
}
