import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';

import { toDecimalString } from './number';
import { chainsCallbacksWithPriceUpdateData } from './pyth';
import { ChainIdsHex } from './chains';
import { wait } from './wait';
import { getTokenPrices, TokenPrices } from './pid';

// these are the actions a user can perform under certain conditions
export enum CollateralAction {
    Add = 'ADD',
    Buy = 'BUY',
}

// these are statuses to display when user cannot perform any action in collateral page. do not combine with CollateralAction
export enum CollateralStatus {
    FullyBacked = '100%',
}

export async function refreshCollateralRatio(xsdContract: ethers.Contract, provider: ethers.providers.Web3Provider): Promise<void> {
    const signer = provider.getSigner();
    const xsdReceipt = await xsdContract.connect(signer).refreshCollateralRatio();
    Logger.log('refreshCollateralRatio xsdReceipt', xsdReceipt);
    await xsdReceipt.wait(1);
}

export async function getXSDTotalSupply(xsdContract: ethers.Contract): Promise<string> {
    const decimals = 18;
    const totalSupply = await xsdContract.totalSupply();
    const formatted = toDecimalString(totalSupply, decimals);

    Logger.log('getXSDTotalSupply:', formatted, totalSupply);

    return formatted;
}

export async function getBankxCollateralBalance(bankxPoolContract: ethers.Contract): Promise<string> {
    const amountPaid = await bankxPoolContract.collatDollarBalance();
    const result = toDecimalString(amountPaid, 18);

    Logger.log('bankx collateral balance', result, amountPaid );
    return result;
}

export async function getXSDCollateralBalance(xsdPoolContract: ethers.Contract): Promise<string> {
    const amountPaid = await xsdPoolContract.collatDollarBalance();
    const result = toDecimalString(amountPaid, 18);

    Logger.log('xsd collateral balance', result, amountPaid);
    return result;
}

export async function getCollateralPoolBalance(collateralPoolContract: ethers.Contract): Promise<string> {
    const amountPaid = await collateralPoolContract.collatDollarBalance();
    const result = toDecimalString(amountPaid, 18);

    Logger.log('collateral pool balance', result, amountPaid );
    return result;
}

export async function getCollateralBackedXSDInCirculation(collateralPoolContract: ethers.Contract): Promise<string> {
    const xsd = await collateralPoolContract.collat_XSD();
    const result = toDecimalString(xsd, 18);

    Logger.log('getCollateralBackedXSDInCirculation', xsd, result);

    return result;
}

export function getSystemCollateralPercentage(collateralPoolBalance: string, collateralBackedXSD: string, silverPrice: number): number {
    const collateralPoolBalanceInUSD = Number(collateralPoolBalance);
    const collateralBackedXSDAsUSD = Number(collateralBackedXSD) * silverPrice;

    const diff = collateralPoolBalanceInUSD - collateralBackedXSDAsUSD;
    const result = diff / collateralPoolBalanceInUSD * 100;

    Logger.log('system collateral [poolbalance, backedXSD, diff, percentage]', collateralPoolBalanceInUSD, collateralBackedXSDAsUSD, diff, result);

    return Number(result.toFixed(2));
}

export function getCollateralAction(deficit: number, availableExcessCollatDV: number): string {
    Logger.log('getCollateralAction: [deficit, excessCollat]', deficit, availableExcessCollatDV);

    if (Number.isNaN(deficit) || Number.isNaN(getAvailableExcessCollatDV)) {
        return '-';
    }

    if (deficit > 0) {
        return CollateralAction.Add;
    }

    if (availableExcessCollatDV > 0) {
        return CollateralAction.Buy;
    }

    return CollateralStatus.FullyBacked;
}

export async function getAvailableExcessCollatDV(collateralPool: ethers.Contract): Promise<string> {
    const excess = await collateralPool.availableExcessCollatDV();
    const result = toDecimalString(excess, 18);

    Logger.log('availableExcessCollatDV ', result);
    return result;
}

const chainsWith30SecBlockDelay = new Set([
    ChainIdsHex.ArbitrumOne,
    ChainIdsHex.Avalanche,
    ChainIdsHex.Fantom,
]);

export const SHOULD_USE_PRICE_CHECK = true;

export async function systemCalculations(
    pidContract: ethers.Contract,
    proxyContract: ethers.Contract,
    signer: ethers.providers.JsonRpcSigner,
    chain: string,
    blockWait: number,
    usePriceUpdate: boolean = false, // use priceCheck instead of systemCalculations
): Promise<TokenPrices> {
    const isRouterInteraction = false;
    // https://ossllc.atlassian.net/browse/BAN-427 - priceCheck has been replaced with systemCalculations to resolve a multitude of issues
    let receipt;

    if (usePriceUpdate) {
        Logger.log('priceCheck - execute');
        receipt = await chainsCallbacksWithPriceUpdateData(
            chain,
            proxyContract,
            (priceUpdateData, updateFee) => pidContract.connect(signer).priceCheck(priceUpdateData, { value: updateFee }),
            () => pidContract.connect(signer).priceCheck(),
            isRouterInteraction,
        );
    } else {
        Logger.log('systemCalculations - execute');
        receipt = await chainsCallbacksWithPriceUpdateData(
            chain,
            proxyContract,
            (priceUpdateData, updateFee) => pidContract.connect(signer).systemCalculations(priceUpdateData, { value: updateFee }),
            () => pidContract.connect(signer).systemCalculations(),
            isRouterInteraction,
        );
    }

    Logger.log(`systemCalculations/priceCheck - waiting ${blockWait} blocks`);
    await receipt.wait(blockWait);

    // https://ossllc.atlassian.net/browse/BAN-423 - additional 30seconds on top of block delay for arbitrum only
    // https://ossllc.atlassian.net/browse/BAN-444 - additional 30seconds on top of block delay for fantom and avalanche
    if (chainsWith30SecBlockDelay.has(chain)) {
        await wait(30000);
    }

    return getTokenPrices(pidContract);
}
