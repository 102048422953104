import { CopyIcon, CopyText } from 'components/CopyText';
import { useGetReferralLink } from 'hooks/useReferrals';
import { useWallet } from 'hooks/useWallet';
import React from 'react';

type ReferralBannerProps = {
    connect?: (options?: any) => Promise<void>
    route: string
}

export default function ReferralBanner({ connect, route }: ReferralBannerProps) {
    const referral = useGetReferralLink(route);
    const { address: userAddress } = useWallet();

    function connectWallet(): void {
    connect?.();
    }

    // Temporarily removing Referral Banner from XSD & Silver pages
    if (route === 'xsd' || route === 'silver') {
        return null;
    }

    return (
        <a
            className={`referral_banner ${userAddress ? 'is_disabled' : ''}`}
            {...(!userAddress && { onClick: connectWallet })}
        >
            {userAddress ? (
                <CopyText text={referral.link}>
            Your Referral Link: {referral.getText(10)} <CopyIcon />
                    <br />
            See below for details on the referral program
                </CopyText>
            ) : (
                <>
            Connect your wallet to get your referral link to earn stablecoin.
                    <br />
                    {route === 'buy-nft'
                        ? 'Get 10% of every NFT purchase that you refer!'
                        : 'Get 10% in XSD for all referrals with bonuses!'}
                </>
            )}
        </a>
    );
}
