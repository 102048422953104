import cx from 'classnames';
import { ConnectButton } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { Label } from 'components/Generics';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { MintInfo } from 'components/MintInfo';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { isValidInput } from 'helpers/input';
import { Logger } from 'helpers/logging';
import { truncateDecimalValue } from 'helpers/number';
import {
    calculateRedemptionValues,
    checkIfUserHasPendingRedemption,
    collectRedemption,
    redeemXSD,
} from 'helpers/redeem';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import { debounce, every } from 'lodash';
import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

enum RedeemAction {
    Continue = 'Complete Pending Redemption',
    Initiate = 'Redeem',
}

export function Redeem(): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();
    const { address: walletAddress, priceCheckBlockDelay } = useWallet();

    const { useInputState } = useTextInputContext();
    const [amountToRedeem, setAmountToRedeem] = useInputState(TextInputName.Redeem);

    const {
        bankXTokenPrice,
        chain,
        collateralPercentage,
        ethPriceInUsd,
        mintInfo,
        silverPrice,
        walletValues,
        isRedeemDisabled,
    } = useGlobalValues();
    const neededBankx = mintInfo.neededBankx || '0';
    const neededEth = mintInfo.neededEth || '0';

    const { contracts, getProvider } = useWeb3Context();
    const {
        collateralPoolContract,
        pidContract,
        xsdTokenContract,
        proxyContract,
    } = contracts;
    const walletProvider = getProvider();

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    const hasValidInput = isValidInput(amountToRedeem);
    const isLimitExceeded = Number(amountToRedeem) > Number(mintInfo.totalMinted);
    const isDisabled = !hasValidInput || isLimitExceeded;

    const [expected, setExpected] = useState({
        bankx: '',
        eth: '',
        interest: '',
    });

    const [hasPendingRedemption, setHasPendingRedemption] = useState(false);

    const checkForPendingRedemption = useMemo(() => debounce((collateralPoolContract, walletAddress): void => {
        if (walletAddress) {
            checkIfUserHasPendingRedemption(collateralPoolContract, walletAddress)
                .then((hasPending): void => setHasPendingRedemption(hasPending));
        }
    }, 800, {
        leading: false,
        trailing: true
    }), []);

    const config = {
        accumulatedInterest: mintInfo.totalAccumulatedInterest as string,
        bankxPriceInUSD: bankXTokenPrice,
        collateralRatio: collateralPercentage,
        ethPriceInUSD: ethPriceInUsd,
        silverPricePerGram: silverPrice,
        xsdToRedeem: null,
        xsdAvailable: mintInfo.totalMinted as string,
    };
    const configRef = useRef(config);
    configRef.current = config;

    const calculateExpectedOutput = useMemo(() =>
        debounce(
            (xsdAmount: string, neededBankx: string, neededEth: string): void => {
                const toRedeem = Math.min(Number(xsdAmount), Number(configRef.current.xsdAvailable));
                const c = {
                    ...configRef.current,
                    xsdToRedeem: truncateDecimalValue(toRedeem, 5),
                    neededBankx,
                    neededEth,
                };
                if (!every(c)) {
                    return;
                }

                const result = calculateRedemptionValues(c);
                setExpected(result);
            },
            200,
            {
                leading: false,
                trailing: true
            }), [setExpected]);

    async function handleRedeemClick(): Promise<void> {
        if (isRedeemDisabled) {
            return;
        }

        async function executePendingRedemption(): Promise<void> {
            Logger.log('redeem: executePendingRedemption');
            await collectRedemption(
                collateralPoolContract,
                pidContract,
                walletProvider,
                goToNextLoadingMessage,
                chain,
                proxyContract,
                priceCheckBlockDelay,
            );

            await checkForPendingRedemption(collateralPoolContract, walletAddress);
        }

        async function initiateNewRedemption(): Promise<void> {
            if (!amountToRedeem) {
                return;
            }

            function getUpdatedAmounts(xsdToRedeem: string, bankxPriceInUSD: string, neededBankx: string, neededEth: string): ReturnType<typeof calculateRedemptionValues> {
                return calculateRedemptionValues({
                    ...configRef.current,
                    xsdToRedeem,
                    neededBankx,
                    neededEth,
                    bankxPriceInUSD,
                });
            }

            Logger.log('redeem: initiateNewRedemption');
            await redeemXSD(
                collateralPercentage,
                amountToRedeem,
                expected.eth,
                expected.bankx,
                collateralPoolContract,
                xsdTokenContract,
                pidContract,
                walletProvider,
                goToNextLoadingMessage,
                chain,
                proxyContract,
                priceCheckBlockDelay,
                getUpdatedAmounts,
            );
        }

        try {
            if (hasPendingRedemption) {
                Logger.log('redeem: hasPendingRedemption - bypassing redeem flow');
                // 3 steps: price check -> collect -> wait
                resetLoadingMessages(3);
                await executePendingRedemption();
            } else {
                Logger.log('redeem: no pending redemption - no bypassing');
                // 6 steps: price check -> approve -> redeem -> price check -> collect -> wait
                // 3 steps for redeem func + 3 steps from collect redemption
                resetLoadingMessages(6);
                await initiateNewRedemption();
                await executePendingRedemption();
            }
        } catch(e) {
            await checkForPendingRedemption(collateralPoolContract, walletAddress);
            Logger.error(e);
            throw e;
        }
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const xsd = e.target.value;
        setAmountToRedeem(xsd);
        calculateExpectedOutput(xsd, neededBankx, neededEth);
    }

    function handleMaxBtnClick() {
        const max = truncateDecimalValue(mintInfo.totalMinted as string, 5);
        setAmountToRedeem(max);
        calculateExpectedOutput(max, neededBankx, neededEth);
    }

    useEffect((): void => checkForPendingRedemption(collateralPoolContract, walletAddress), [
        checkForPendingRedemption,
        collateralPoolContract,
        walletAddress
    ]);

    const buttonText = hasPendingRedemption
        ? RedeemAction.Continue
        : RedeemAction.Initiate;

    return (
        <DashboardLayout>
            <div className={cx(tcx('content-wrapper'), tcx('redeem-page'))}>
                <TopCards />
                <div className={cx(tcx('main-card'), tcx('card-bg'), { 'disable-overlay': isRedeemDisabled })}>
                    <div className="row">
                        <div className="col-md-6 curved-transition-container">
                            <div className={cx(tcx('white-card'), 'curved-transition')}>
                                <Settings title="Redeem" isSettingsVisible={false}/>
                                <div className="wc-body">
                                    <Label className="mb-2" text="If XSD is above the price of 1 gram of Silver, MINT and SELL it instead for a profit."/>
                                    <div className="form-group with-max-btn">
                                        <TextInput
                                            className={cx(tcx('form-control'), 'with-max-btn')}
                                            onChange={handleInputChange}
                                            name={TextInputName.Redeem}
                                            placeholder="Redeem"
                                            type="number"
                                            value={amountToRedeem}
                                            disabled={isLoading}
                                        />
                                        <button disabled={!walletValues.xsd} onClick={handleMaxBtnClick} className="button-1 max-btn">MAX</button>
                                    </div>
                                    <div
                                        className={tcx('side-info-box2')}
                                        style={{
                                            marginBottom: '0',
                                            marginTop: '0'
                                        }}
                                    >
                                        <ul>
                                            <li>
                                                <label>Total XSD Minted:</label>
                                                <FormattedValue value={mintInfo.totalMinted} isLoading={isLoading} precision={4}/>
                                            </li>
                                            <li>
                                                <label>XSD Available:</label>
                                                <FormattedValue value={walletValues.xsd} isLoading={isLoading} precision={4}/>
                                            </li>
                                            <li>
                                                <Label className="mb-2" text="*You can only redeem the amount of XSD you have minted."/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MintInfo cardClassName='pb-1' listClassName='pb-2' {...mintInfo} />
                    </div>
                    <div className={cx('row', tcx('row-status'))}>
                        <div className="col-md-6">
                            <div className={cx(tcx('si-list'), 'bg-7')}>
                                <ul className="mr-2 ul-fix">
                                    <li style={{ marginBottom: '5px' }}>
                                        <label>Returns to you:</label>
                                    </li>
                                    <li>
                                        <label>{walletValues.tokenLabel}:</label>
                                        <FormattedValue value={expected.eth} precision={4}/>
                                    </li>
                                    <li>
                                        <label>BankX Tokens:</label>
                                        <FormattedValue value={expected.bankx} precision={4}/>
                                    </li>
                                    <li>
                                        <label>Interest Earned in BankX Tokens:</label>
                                        <FormattedValue value={expected.interest} precision={4}/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={cx(tcx('bu-wrap'))}>
                                <b>NOTE:</b>
                                <p>
                                    To be paid the interest earned on the XSD you mint,
                                    you must redeem from the same wallet address as you
                                    minted. Otherwise, we have no way of knowing it is you.
                                </p>
                                {/* This still needs to be worked out */}
                                <ConnectButton
                                    disabled={isDisabled && !hasPendingRedemption && isRedeemDisabled}
                                    className={tcx('button-1')}
                                    onClick={handleRedeemClick}
                                    onDone={resetLoadingMessages}
                                    customLoadingMessage={customLoadingMessage}
                                    limitMessage="Exceeded Minted Balance"
                                    isLimitExceeded={isLimitExceeded && !hasPendingRedemption}
                                >
                                    {buttonText}
                                </ConnectButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
