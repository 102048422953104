import React from 'react';

import Styles from './styles.module.scss';

interface HexAddressProps {
    address: string;
    endLength?: number;
}

export function HexAddress({
    address,
    endLength = 7,
}: HexAddressProps): React.ReactElement {
    const start = address.slice(0, 0 - endLength);
    const end = address.slice(0 - endLength);

    return (
        <span className={Styles.wrapper}>
            <span className={Styles.overflow}>{start}</span>
            <span>{end}</span>
        </span>
    );
}
