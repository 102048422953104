import { format } from 'date-fns';
import { ethers } from 'ethers';

import { toDecimalString } from './number';

export function formatDate(date: Date): string {
    return format(date, 'MMMM d, yyyy');
}

export function formatEpochDate(epoch: number): string {
    const date = new Date(epoch * 1000);

    return formatDate(date);
}

interface FormatBigNumberDateConfig {
    adjustmentInSeconds: number;
}

export function formatBigNumberDate(value: ethers.BigNumber, config?: FormatBigNumberDateConfig): string {
    const epoch = Number(toDecimalString(value, 0));

    if (epoch === 0) {
        return '-';
    }

    const adjustment = config?.adjustmentInSeconds || 0;

    return formatEpochDate(epoch + adjustment);
}

export function daysToDate(endDate: Date): number {
    const currentDay = new Date();
    const diffTimestamp = Math.abs(endDate.getTime() - currentDay.getTime());

    return Math.ceil(diffTimestamp / (1000 * 3600 * 24));
}

export function formatToYYYYMMDD(date: string): string {
    return format(new Date(date), 'yyyy-MM-dd');
}
