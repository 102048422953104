import cx from 'classnames';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { isProd } from 'helpers/env';
import { CollateralAction } from 'helpers/pool';
import React from 'react';

import { NegativeCollateral } from './components/NegativeCollateral';
import { PositiveCollateral } from './components/PositiveCollateral';

const actionsArray: string[] = Object.values(CollateralAction);

export function Collateral(): React.ReactElement {
    const { getThemedClass: tcx } = useAppSettings();
    const { collateralAction } = useGlobalValues();
    const isNegativeCollateral = collateralAction !== CollateralAction.Buy;
    const isDisabledOverride = isProd();
    const isDisabled = isDisabledOverride || !actionsArray.includes(collateralAction);

    return (
        <DashboardLayout>
            <div className={cx('collateral-page', tcx('content-wrapper'), { [tcx('redeem-page')]: isNegativeCollateral })}>
                <TopCards />
                <div className={cx({ 'disable-overlay': isDisabled })}>
                    { isNegativeCollateral ? <NegativeCollateral disabled={isDisabled}/> : <PositiveCollateral disabled={isDisabled}/> }
                </div>
            </div>
        </DashboardLayout>
    );
}
