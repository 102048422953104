import { ethers } from 'ethers';
import { Logger } from './logging';

export async function getDeadline(
    provider: ethers.providers.Web3Provider,
): Promise<number> {
    // add 30-minute timeout to latest block
    const TIMEOUT_IN_SECONDS = 1_800;

    const latest = await provider.getBlock('latest');
    const deadline = latest.timestamp + TIMEOUT_IN_SECONDS;
    Logger.log('Deadline', deadline);

    return deadline;
}
