import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
    createContext, useCallback, useContext, useEffect, useRef, useState
} from 'react';
import { v4 as generateUUID } from 'uuid';

import Styles from './styles.module.sass';

interface CopyTextContext {
    register(callback: () => void): string;
    unregister(id: string): void;
}
const Context = createContext<CopyTextContext>({
    register: () => '',
    unregister: () => undefined,
});

type CopyTextProps = React.PropsWithChildren<{
    text: string;
    isWrapped?: boolean
}>;

export function CopyText({
    children, text, isWrapped = true
}: CopyTextProps): React.ReactElement {
    const mapRef = useRef(new Map());

    function handleClick(): void {
        window.navigator.clipboard.writeText(text);
        mapRef.current.forEach(cb => cb());
    }

    const register = useCallback((callback: () => void): string => {
        const uuid = generateUUID();
        mapRef.current.set(uuid, callback);
        return uuid;
    }, []);

    const unregister = useCallback((id: string): void => {
        mapRef.current.delete(id);
    }, []);

    return (
        <Context.Provider value={{
            register,
            unregister
        }}>
            {
                isWrapped ? (
                    <div className={Styles.link} onClick={handleClick}>{children}</div>
                ) : (<>{children}</>)
            }
        </Context.Provider>
    );
}

export function CopyIcon(): React.ReactElement {
    const [isCopiedState, setIsCopiedState] = useState(false);
    const { register, unregister } = useContext(Context);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect((): (() => void) => {
        function callback(): void {
            setIsCopiedState(true);
            clearTimeout(timeoutRef.current as NodeJS.Timeout);
            timeoutRef.current = setTimeout((): void => {
                setIsCopiedState(false);
            }, 2250);
        }

        const id = register(callback);
        return (): void => {
            unregister(id);
        };
    }, [
        register,
        unregister,
        setIsCopiedState
    ]);

    if (isCopiedState) {
        return <FontAwesomeIcon color={'#1a7f37'} icon={faCheck}/>;
    }

    return  <FontAwesomeIcon color={'#1975b8'} icon={faLink} size="sm"/>;
}
