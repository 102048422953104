import { ethers } from 'ethers';
import { Logger } from 'helpers/logging';

import { toDecimalString } from './number';

enum BankxInflation {
    Positive = 'Inflationary',
    Negative = 'Deflationary',
    Zero = 'Zero',
}

export async function getBankxInflation(bankxContract: ethers.Contract): Promise<string> {
    const [rawTotal, rawGenesis] = await Promise.all([
        bankxContract.totalSupply(),
        bankxContract.genesis_supply(),
        null
    ]);

    const total = Number(toDecimalString(rawTotal, 18));
    const genesis = Number(toDecimalString(rawGenesis, 18));

    const diff = total - genesis;

    // https://ossllc.atlassian.net/browse/BAN-135
    // negative (below .1%) - Deflationary
    // positive (above the .1%) - Inflationary
    // within .1% we could say - Zero

    Logger.log('getBankxInflation', total, genesis, diff);

    if (diff > 0) {
        return BankxInflation.Positive;
    }

    if ( diff < 0) {
        return BankxInflation.Negative;
    }

    return BankxInflation.Zero;
}
