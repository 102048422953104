import { useConnectWallet } from '@web3-onboard/react';
import { playCoinSound } from 'assets/CoinsSound';
import { Loading } from 'assets/Loading';
import { SuccessIcon } from 'assets/SuccessIcon';
import cx from 'classnames';
import { useTextInputContext } from 'components/TextInput';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useContractValues, useGlobalValues } from 'context/ContractValuesProvider';
import { UDPATE_DELAY } from 'helpers/constants';
import { Logger } from 'helpers/logging';
import { wait } from 'helpers/wait';
import { CustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import {
    identity,
    size,
    some,
} from 'lodash';
import React, {
    useCallback,
    useRef,
    useState,
} from 'react';

import Styles from './styles.module.sass';

interface ConnectButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isHeaderButton?: boolean;
    customLoadingMessage?: string;
    label?: string;

    limitMessage?: string;
    isLimitExceeded?: boolean;

    // triggers when onClick is finished
    onDone?(): void;

    onDoneWithoutErrors?(): void;

    // for when you just need onClick
    onClickOverride?: boolean;

    // override error (used for pid recalculate button)
    errorMessageOverride?: string;
}

export enum ConnectButtonText {
    CollateralPoolDeficit = 'Collateral Pool Deficit',
    Empty = '',
    InsufficientLiquidity = 'Insufficient Liquidity',
    GenericError = 'Something Went Wrong',
    Working = 'Working',
    Success = 'Success',
    Canceled = 'Transaction Canceled',
    NotEnoughBalance = 'Not Enough Balance',
    MaxAmountExceeded = 'Max Amount Exceeded',
    NotEnoughCollateral = 'Not Enough Collateral',
}

function LoadingMessage(loadingMessage: string = ConnectButtonText.Working): React.ReactElement {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <Loading/> <span className="ml-2">{loadingMessage}</span>
        </div>
    );
}

enum ErrorType {
    Canceled = 'User denied transaction',
    Canceled2 = 'Cannot set properties of undefined',
    Canceled3 = 'User denied transaction signature',
    CollateralPoolDeficit = 'Cannot withdraw in times of deficit',
    InsufficientLiquidity = 'INSUFFICIENT_LIQUIDITY',
    InsufficientXSDLiquidity = 'execution reverted: XSDWETH: K',
    InsufficientBankxLiquidity = 'execution reverted: BankXWETH: K',
    InsufficientArbitrageLiquidity = 'reason="execution reverted: BurnXSD:Burnable limit"',
    NotEnoughCollateral = 'Not enough collateral in pool'
}

function getErrorText(errorType: string): string {
    switch (errorType) {
        case ErrorType.InsufficientLiquidity:
        case ErrorType.InsufficientXSDLiquidity:
        case ErrorType.InsufficientBankxLiquidity:
        case ErrorType.InsufficientArbitrageLiquidity:
            return ConnectButtonText.InsufficientLiquidity;
        case ErrorType.Canceled:
        case ErrorType.Canceled2:
        case ErrorType.Canceled3:
            return ConnectButtonText.Canceled;
        case ErrorType.NotEnoughCollateral:
            return ConnectButtonText.NotEnoughCollateral;
        case ErrorType.CollateralPoolDeficit:
            return ConnectButtonText.CollateralPoolDeficit;
        default:
            return ConnectButtonText.Empty;
    }
}

export function ConnectButton({
    children,
    isHeaderButton,
    customLoadingMessage,
    label = 'Connect Wallet',
    limitMessage,
    isLimitExceeded = false,
    onDone,
    onDoneWithoutErrors,
    onClickOverride = false,
    errorMessageOverride,
    ...props
}: React.PropsWithChildren<ConnectButtonProps>): React.ReactElement | null {
    const { areValuesLoading: areGlobalValuesLoading, walletValues } = useGlobalValues();
    const { updateBalances } = walletValues;
    const { getThemedClass: tcx } = useAppSettings();
    const [{ wallet }, connectWallet] = useConnectWallet();
    const { clearInputs } = useTextInputContext();
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const contractValues = useContractValues();

    const [state, setState] = useState({
        textOverride: '',
        isLoading: false,
    });

    const clearButtonText = useCallback((delay = 5000): void => {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);
        timeoutRef.current = setTimeout((): void => {
            setState({
                isLoading: false,
                textOverride: '',
            });
        }, delay);
    }, []);

    const displaySuccessButton = useCallback((): void => {
        setState({
            isLoading: false,
            textOverride: ConnectButtonText.Success,
        });
        playCoinSound();
        clearButtonText();
    }, [clearButtonText]);

    function parseError(errorMessage: string): void {
        // the only button using errorMessageOverride is the pid recalculate button in the side bar
        if (errorMessageOverride) {
            setState({
                textOverride: errorMessageOverride,
                isLoading: false
            });
            return;
        }

        // we want to set the first match only
        const errorFound = some(ErrorType, (error): boolean => {

            const isErrorMatch = RegExp(error).test(errorMessage);
            Logger.log('checking error', error, isErrorMatch);

            if (isErrorMatch) {
                const overrideText = getErrorText(error);
                setState({
                    textOverride: overrideText,
                    isLoading: false,
                });
                // clearButtonText(10000);
                return true;
            }

            return false;
        });

        if (!errorFound) {
            setState({
                textOverride: ConnectButtonText.GenericError,
                isLoading: false,
            });
        }
    }

    const isDisabled = props?.disabled || areGlobalValuesLoading || state.isLoading;

    async function handleClick(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
        if (isDisabled) {
            return;
        }

        // if there is an override, clicking the button should just clear it and perform no action
        if (state.textOverride.length > 0) {
            return setState({
                textOverride: '',
                isLoading: false
            });
        }

        if (onClickOverride) {
            await props.onClick?.(event);
            return;
        }

        try {
            clearTimeout(timeoutRef.current as NodeJS.Timeout);

            setState({
                textOverride: '',
                isLoading: true
            });

            await props.onClick?.(event);

            setState({
                isLoading: true,
                textOverride: CustomLoadingMessage.CompletingTransaction,
            });

            // we want to delay the fetch to guarantee the contract values are updated
            await wait(UDPATE_DELAY);

            // this refreshes the metamask wallet balance
            updateBalances();

            // this refreshes the values in the wallet hook
            await Promise.all([
                walletValues.update('bankx'),
                walletValues.update('balance'),
                walletValues.update('xsd'),
            ]);

            // fetch all contract values again
            contractValues.resetResults();

            clearInputs();
            setState({
                textOverride: '',
                isLoading: false
            });
            displaySuccessButton();
            if (typeof onDoneWithoutErrors === 'function') {
                onDoneWithoutErrors();
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            Logger.error(error);
            parseError(error.message);
        } finally {
            if (typeof onDone === 'function') {
                onDone();
            }
        }
    }

    const { textOverride, isLoading } = state;
    const isSuccess = textOverride === ConnectButtonText.Success;
    const className = cx(props.className, {
        [Styles.error]: some([
            textOverride === ConnectButtonText.InsufficientLiquidity,
            textOverride === ConnectButtonText.Canceled,
            textOverride === ConnectButtonText.GenericError,
            size(errorMessageOverride) > 0 && textOverride === errorMessageOverride,
            isLimitExceeded,
        ]),
        [Styles.success]: isSuccess,
    });

    const content = function(): string | React.ReactNode {
        if (textOverride) {
            switch (textOverride) {
                case CustomLoadingMessage.CompletingTransaction:
                    return LoadingMessage(textOverride);
                default:
                    return textOverride;
            }
        }

        if (isLoading && customLoadingMessage) {
            return LoadingMessage(customLoadingMessage);
        }

        return children;
    }();

    if (!wallet) {
        return (
            <button
                onClick={() => connectWallet()}
                className={cx(props.className, {
                    [tcx('header-button')]: isHeaderButton,
                    [tcx('button-1')]: !isHeaderButton
                })}
            >
                {label}
            </button>
        );

    }

    if (isHeaderButton) {
        return null;
    }

    if (limitMessage && isLimitExceeded) {
        return (
            <button disabled className={className} onClick={identity}>
                {limitMessage || ConnectButtonText.NotEnoughBalance}
            </button>
        );
    }

    return (
        <button {...props} onClick={handleClick} className={className} disabled={isDisabled}>
            {isSuccess && <span className="mr-2"><SuccessIcon /></span>}
            {content}
        </button>
    );

}
