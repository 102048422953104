import React from 'react';

export const LiquiditySvg: React.ReactNode = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 16 16"
        width="16px"
        height="16px"
    >
        <path d="M 3 2 L 3 3 L 4.5 3 C 5.3350151 3 6 3.6649849 6 4.5 L 6 9 L 4 9 L 6.5 11.5 L 8 10 L 9.5 11.5 L 12 9 L 10 9 L 10 4.5 C 10 3.6649849 10.664985 3 11.5 3 L 13 3 L 13 2 L 11.5 2 C 10.125015 2 9 3.1250151 9 4.5 L 9 9 L 7 9 L 7 4.5 C 7 3.1250151 5.8749849 2 4.5 2 L 3 2 z M 2.5 6 A 0.50005 0.50005 0 0 0 2 6.5 L 2 12.5 C 2 13.322531 2.6774686 14 3.5 14 L 12.5 14 C 13.322531 14 14 13.322531 14 12.5 L 14 6.5 A 0.50005 0.50005 0 0 0 13.5 6 L 12 6 L 12 7 L 13 7 L 13 12.5 C 13 12.781469 12.781469 13 12.5 13 L 3.5 13 C 3.2185314 13 3 12.781469 3 12.5 L 3 7 L 4 7 L 4 6 L 2.5 6 z" />
    </svg>
);
