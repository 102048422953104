import cx from 'classnames';
import { ConnectButton } from 'components/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { Logger } from 'helpers/logging';
import {
    getNFTTier, getTotalPurchasePrice, mintNFT
} from 'helpers/nft';
import { addCommas } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import React from 'react';

type TPurchaseNFTBox = {
    className?: string
    userAddress?: string
    referrerAddress?: string | null
    disableDarkTheme?: boolean
    hasSettings?: boolean
    hasLandingPageRedirect?: boolean
}

export function PurchaseNFTBox({
    className = '',
    userAddress = '',
    referrerAddress,
    hasSettings = true,
    disableDarkTheme = false,
    hasLandingPageRedirect = false,
}: TPurchaseNFTBox) {
    const { getThemedClass: tcx } = useAppSettings();

    const {
        tokenLabel: token, chain
    } = useWallet();

    const { useInputState } = useTextInputContext();
    const [purchaseAmount, setPurchaseAmount] = useInputState(TextInputName.NFT);
    const { getProvider, contracts } = useWeb3Context();
    const provider = getProvider();
    Logger.log({
        contracts,
    });
    const { bankxNftContract } = contracts;

    const { nftInfo } = useGlobalValues();
    const { totalSupply } = nftInfo || {};

    const currentTier = getNFTTier(
        typeof totalSupply === 'number' ? totalSupply : -1,
        chain || ''
    );
    const {
        max, maxTier, price, tierAmount, maxUserCanBuy
    } = currentTier;

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    Logger.log('PurchaseNFTBox:', {
        maxUserCanBuy,
        currentTier,
        nftInfo,
        purchaseAmount,
        referrerAddress,
        totalSupply,
        price,
        tierAmount,
    });

    async function buyNFT() {
        try {
            resetLoadingMessages();
            await mintNFT(
                purchaseAmount || 0,
                totalSupply || 0,
                bankxNftContract,
                provider,
                userAddress,
                goToNextLoadingMessage,
                referrerAddress ?? undefined,
                chain ?? undefined,
            );
        } catch (e) {
            Logger.error(e);
            throw e;
        }
    }

    function onDone() {
        resetLoadingMessages();
    }

    function onDoneWithoutErrors() {
        if (hasLandingPageRedirect) {
            window.open('https://bankx.io/', '_blank');
        }
    }

    return (
        <div
            className={cx(
                tcx('white-card', disableDarkTheme),
                'purchase-nft-box',
                className
            )}
            style={{ marginBottom: 0 }}
        >
            {hasSettings ? (
                <Settings title='Buy NFT' />
            ) : (
                <div className='wc-title'>
                    <h3>Buy NFT</h3>
                </div>
            )}
            <div className='wc-body'>
                <div className='form-group' style={{ marginBottom: 0 }}>
                    <TextInput
                        autoComplete='off'
                        autoCorrect='off'
                        type='text'
                        pattern='^[0-9]*[.,]?[0-9]*$'
                        className={cx(tcx('form-control', disableDarkTheme))}
                        placeholder='How many?'
                        value={purchaseAmount}
                        onChange={(event) => {
                            const maxPurchaseAmount = 5_000 - (totalSupply || 0);
                            if (isNaN(Number(event.target.value))) {
                                setPurchaseAmount(undefined);
                            } else if (Number(event.target.value) > maxPurchaseAmount) {
                                setPurchaseAmount(maxPurchaseAmount);
                            } else {
                                setPurchaseAmount(Number(event.target.value));
                            }
                        }}
                        style={{
                            width: '33%',
                            minWidth: 120,
                            margin: '0 auto',
                        }}
                        name={TextInputName.NFT}
                        disabled={isLoading}
                    />
                </div>
                <div className='item'>
                    <div className={cx(tcx('si-list', disableDarkTheme))}>
                        <ul className={cx(tcx('mr-2', disableDarkTheme))}>
                            <li>
                                <b>Price per NFT</b>
                                <b>
                                    <FormattedValue
                                        isLoading={typeof currentTier.price !== 'number'}
                                        value={String(currentTier.price) || '0'}
                                        precision={
                                            {
                                                '0x89': 0,
                                                '0xa86a': 0,
                                            }[chain || ''] || 1
                                        }
                                        suffix={` ${token}`}
                                    />
                                </b>
                            </li>
                            <li>
                                <b>
                                    <FormattedValue
                                        isLoading={typeof totalSupply !== 'number'}
                                        value={String(totalSupply) || '0'}
                                        precision={0}
                                    />{' '}
                  of {addCommas(String(max), 0)} sold
                                </b>
                            </li>
                            <li>
                                <b>
                                    <FormattedValue
                                        isLoading={typeof tierAmount !== 'number'}
                                        value={String(tierAmount) || '0'}
                                        precision={0}
                                    />{' '}
                  of {addCommas(String(maxTier), 0)} before next price increase.
                                </b>
                            </li>
                            <li>
                                <b>Total Price:</b>
                                <b>
                                    <FormattedValue
                                        value={String(
                                            getTotalPurchasePrice(
                                                purchaseAmount ?? 0,
                                                totalSupply ?? 0,
                                                chain || '',
                                            )
                                        )}
                                        precision={2}
                                        suffix={` ${token}`}
                                    />
                                </b>
                            </li>
                        </ul>
                        <ConnectButton
                            className={tcx('button-1')}
                            disabled={!purchaseAmount || isLoading}
                            onClick={buyNFT}
                            onDone={onDone}
                            customLoadingMessage={customLoadingMessage}
                            onDoneWithoutErrors={onDoneWithoutErrors}
                        >
              Buy
                        </ConnectButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
