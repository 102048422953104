import { OnboardAPI } from '@web3-onboard/core';
import injectedWalletsModule from '@web3-onboard/injected-wallets';
import { init } from '@web3-onboard/react';
import walletConnectModule from '@web3-onboard/walletconnect';
import { getChains } from 'helpers/chains';

import { Storage } from './storage';

/*
  Creates OnBoard initialization object.
  Reference: https://docs.blocknative.com/onboard#initialization
*/
// const apiKey = '8d63ecd8-f182-4c82-8433-7060efa128b0';
const injectedWallet = injectedWalletsModule();
const walletConnect = walletConnectModule();

const arbitraryWalletStorageKey = '_w128430_sl901';

function storeWalletData(wallets: string[]): void {
    if (wallets.length > 0) {
        Storage.set(arbitraryWalletStorageKey, JSON.stringify(wallets));
    } else {
        Storage.delete(arbitraryWalletStorageKey);
    }
}

function getWalletData(): string[] {
    try {
        const data = Storage.get(arbitraryWalletStorageKey);
        return JSON.parse(data as string);
    } catch {
        return [];
    }
}

function setWalletSubscriptions(onboard: OnboardAPI): void {
    // https://docs.blocknative.com/onboard/core#auto-selecting-a-wallet
    const wallets = onboard.state.select('wallets');

    wallets.subscribe(wallets => {
        const walletLabels = wallets.map((wallet) => wallet?.label).filter(Boolean);
        storeWalletData(walletLabels);
    });
}

interface InitOptions {
    autoConnect?: boolean;
}
export function initOnboard({ autoConnect = false }: InitOptions = {}): void {
    const onboard = init({
        // apiKey,
        chains: getChains(),
        appMetadata: {
            name: 'BankX',
            icon: '/images/icon/x-logo.jpg',
            description: 'Please select a wallet to connect.',
            recommendedInjectedWallets: [{
                name: 'MetaMask',
                url: 'https://metamask.io'
            }]
        },
        wallets: [injectedWallet, walletConnect],
    });

    setWalletSubscriptions(onboard);

    if (!autoConnect) {
        return;
    }

    const walletData = getWalletData();

    if (walletData?.length > 0) {
        onboard.connectWallet({
            autoSelect: {
                label: walletData[0],
                disableModals: true
            }
        });
    }
}
