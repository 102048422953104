import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

function useD3(renderChartFn, data) {
    const ref = useRef();

    useEffect(() => {
        const svg = d3.select(ref.current);

        // Clear out old SVG content if exists
        if (svg.selectAll('*').size() > 0) {
            svg.selectAll('*').remove();
        }

        renderChartFn(d3.select(ref.current));
        return () => undefined;
    }, [renderChartFn, data]);

    return ref;
}

function PricingChart({
    data, width, silverPriceData
}) {
    const ref = useD3(
        (svg) => {
            const height = width / 1.6;
            const margin = {
                top: 30,
                right: 30,
                bottom: 40,
                left: 45,
            };

            data.forEach((d) => {
                d.date = new Date(d.date);
            });

            const x = d3
                .scaleTime()
                .domain(d3.extent(data, (d) => d.date))
                .range([margin.left, width - margin.right]);

            const y = d3
                .scaleLinear()
                .domain([d3.min([...data, ...silverPriceData], (d) => d.value) * 0.9, d3.max([...data, ...silverPriceData], (d) => d.value) * 1.1])
                .nice()
                .range([height - margin.bottom, margin.top]);

            // Handle silver data if exists
            if (silverPriceData.length) {
                silverPriceData.forEach((d) => {
                    d.date = new Date(d.date);
                });

                const line2 = d3
                    .line()
                    .curve(d3.curveBasis)
                    .x((d) => x(d.date))
                    .y((d) => y(d.value));

                svg
                    .append('path')
                    .datum(silverPriceData)
                    .attr('fill', 'none')
                    .attr('stroke', 'rgb(166, 181, 185)')
                    .attr('stroke-width', 2)
                    .attr('d', line2);

                svg
                    .append('text')
                    .attr('transform', 'translate(' + (width - margin.right) + ',' + y(silverPriceData[silverPriceData.length - 1].value) + ')')
                    .attr('dy', '-0.35em')
                    .attr('text-anchor', 'end')
                    .style('fill', 'rgb(166, 181, 185)')
                    .style('font-size', '10px')
                    .text('Silver');
            }

            svg.attr('viewBox', [
                0,
                0,
                width,
                height
            ]);

            /* GRADIENT AREA CHART */
            const area = d3
                .area()
                .curve(d3.curveBasis)
                .x((d) => x(d.date))
                .y0(margin.top) // Changed from d => y(0)
                .y1((d) => y(d.value)); // Changed from d => y(d.value)

            const gradient = svg
                .append('defs')
                .append('linearGradient')
                .attr('id', 'mygrad')
                .attr('x1', '0%')
                .attr('x2', '0%')
                .attr('y1', '100%') // Changed gradient direction
                .attr('y2', '0%'); // Changed gradient direction

            gradient
                .append('stop')
                .attr('offset', '0%')
                .style('stop-color', '#1975b8') // Changed color
                .style('stop-opacity', 0.7); // Changed opacity

            gradient
                .append('stop')
                .attr('offset', '100%')
                .style('stop-color', 'white') // Changed color
                .style('stop-opacity', 0.1); // Changed opacity

            svg
                .append('path')
                .datum(data)
                .attr('d', area)
                .style('fill', 'url(#mygrad)');
            /* END OF GRADIENT AREA CHART */

            /* LINE CHART */
            const line = d3
                .line()
                .curve(d3.curveBasis)
                .x((d) => x(d.date))
                .y((d) => y(d.value));

            svg
                .append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', '#81b8de')
                .attr('stroke-width', 2)
                .attr('d', line);
            /* END OF LINE CHART */

            /* AXIS */

            const yAxisGenerator = d3
                .axisLeft(y)
                .ticks(6)
                .tickSizeInner(-width + margin.right + margin.left)
                .tickFormat((d) => {
                    // 1e6 is 1,000,000+
                    if (d >= 1e6) {
                        return d3.format('$,.1f')(d / 1e6) + 'M';
                    // 1e3 is 1000+
                    } else if (d >= 1e3) {
                        return d3.format('$,.1f')(d / 1e3) + 'K';
                    } else if (d >= 1) {
                        return d3.format('$,.2f')(d);
                    } else {
                        return d3.format('$,.5f')(d);
                    }
                });

            // X Axis
            // Get earliest and latest date in the data
            const dateExtent = d3.extent(data, (d) => d.date);

            // Calculate range in hours
            const rangeInHours = (dateExtent[1] - dateExtent[0]) / 1000 / 60 / 60;

            let tickFormat, tickInterval;

            if (rangeInHours <= 48) {
                // Less than or equal to 2 days
                tickFormat = '%I %p';
                tickInterval = d3.timeHour.every(1); // every 2 hours
            } else if (rangeInHours <= 168 * 1.1) {
                // Less than or equal to 1 week
                tickFormat = '%b %-d';
                tickInterval = d3.timeDay.every(1); // every day
            } else if (rangeInHours <= 720 * 1.1) {
                // Less than or equal to 1 month
                tickFormat = '%b %-d';
                tickInterval = d3.timeDay.every(1); // every 2 days
            } else if (rangeInHours <= 1440 * 1.1) {
                // Less than or equal to 2 months
                tickFormat = '%b %-d';
                tickInterval = d3.timeDay.every(2); // every 5 days
            } else if (rangeInHours <= 2160 * 1.1) {
                // Less than or equal to 3 months
                tickFormat = '%b %-d';
                tickInterval = d3.timeDay.every(5); // every 10 days
            } else if (rangeInHours <= 8760 * 1.1) {
                // Less than or equal to 1 year
                tickFormat = '%b %-d';
                tickInterval = d3.timeMonth.every(1); // every 40 days
            } else {
                // More than 1 year
                tickFormat = '%Y-%m';
                tickInterval = d3.timeMonth.every(1); // every month
            }
            const xAxisGenerator = d3
                .axisBottom(x)
                .ticks(tickInterval)
                .tickPadding(4)
                .tickSizeInner(6)
                .tickFormat(d3.timeFormat(tickFormat));

            const xAxis = svg
                .append('g')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(xAxisGenerator);

            // color in tick lines
            xAxis.selectAll('line').attr('stroke', '#81b8de').attr('stroke-width', 1);

            // Rotate the labels
            xAxis
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .attr('transform', 'rotate(-50)');

            const yAxis = svg
                .append('g')
                .attr('transform', `translate(${margin.left},0)`)
                .call(yAxisGenerator);

            yAxis.select('.domain').remove();
            yAxis
                .selectAll('line')
                .attr('stroke', '#000')
                .attr('stroke-opacity', '0.04')
                .attr('stroke-width', 1) // make the stroke thinner
                .attr('stroke-dasharray', '1,1'); // make the lines dotted
            yAxis
                .selectAll('text')
                .attr('font-size', 8)
                .attr('font-weight', 'bold')
                .attr('fill', '#7B7B7B');

            xAxis.select('.domain').remove();
            xAxis
                .selectAll('text')
                .attr('font-size', 8)
                .attr('font-weight', 'bold')
                .attr('fill', '#7B7B7B');

            return svg.node();
        },
        [data]
    );

    return <svg ref={ref} id='chart' />;
}

export default PricingChart;
