import React from 'react';

export const ArbitrageSvg: React.ReactNode = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 16 16"
        width="16px"
        height="16px"
    >
        <path d="M 7.5 1 C 5.589844 1 3.867188 1.828125 2.675781 3.148438 L 1 1.476563 L 1 5 L 4.535156 5 L 3.386719 3.855469 C 4.394531 2.71875 5.859375 2 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 L 7 13 L 7 14 L 7.5 14 C 11.085938 14 14 11.085938 14 7.5 C 14 3.914063 11.085938 1 7.5 1 Z M 7.011719 4 L 7.011719 5 L 6.5 5 C 5.675781 5 5 5.675781 5 6.5 C 5 7.324219 5.675781 8 6.5 8 L 8.5 8 C 8.78125 8 9 8.21875 9 8.5 C 9 8.78125 8.78125 9 8.5 9 L 5 9 L 5 10 L 7.011719 10 L 7.011719 11 L 8.011719 11 L 8.011719 10 L 8.5 10 C 9.324219 10 10 9.324219 10 8.5 C 10 7.675781 9.324219 7 8.5 7 L 6.5 7 C 6.21875 7 6 6.78125 6 6.5 C 6 6.21875 6.21875 6 6.5 6 L 9 6 L 9 5 L 8.011719 5 L 8.011719 4 Z M 1.5 7 C 1.222656 7 1 7.222656 1 7.5 C 1 7.777344 1.222656 8 1.5 8 C 1.777344 8 2 7.777344 2 7.5 C 2 7.222656 1.777344 7 1.5 7 Z M 2.292969 10 C 2.210938 10 2.128906 10.023438 2.054688 10.066406 C 1.941406 10.132813 1.855469 10.242188 1.820313 10.371094 C 1.785156 10.5 1.804688 10.636719 1.871094 10.75 C 2.007813 10.988281 2.316406 11.070313 2.554688 10.933594 C 2.792969 10.792969 2.875 10.488281 2.734375 10.25 C 2.644531 10.09375 2.476563 9.996094 2.292969 10 Z M 4.476563 12.199219 C 4.304688 12.203125 4.152344 12.296875 4.066406 12.445313 C 4 12.558594 3.980469 12.695313 4.015625 12.824219 C 4.050781 12.953125 4.136719 13.0625 4.25 13.128906 C 4.363281 13.195313 4.5 13.214844 4.628906 13.179688 C 4.757813 13.144531 4.867188 13.058594 4.933594 12.945313 C 5.070313 12.707031 4.988281 12.402344 4.75 12.265625 C 4.667969 12.214844 4.570313 12.191406 4.476563 12.199219 Z" />
    </svg>
);
