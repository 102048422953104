import cx from 'classnames';
import { ConnectButton } from 'components/ConnectButton';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useContractValues, useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import { UDPATE_DELAY } from 'helpers/constants';
import { Logger } from 'helpers/logging';
import { getMsToRefreshAvailable, refreshCollateralRatio } from 'helpers/pid';
import { getThemedClassName } from 'helpers/theme';
import { wait } from 'helpers/wait';
import { CustomLoadingMessage, useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { NavLink } from 'react-router-dom';

import ReferralBox from '../ReferralBox/ReferralBox';
import SideInfo from '../SideInfo';
import Styles from './styles.module.scss';
import {
    ArbitrageSvg,
    CertificateSvg,
    CollateralSvg,
    LiquiditySvg,
    LoopingSvg,
    // LoopingSvg,
    MintSvg,
    NftSvg,
    PricingSvg,
    RedeemSvg,
    SwapSvg,
} from './svgs';

interface NavItem {
    route: string;
    svg: React.ReactNode;
    label: string;
    disabled?: boolean;
}

const NAV_ITEMS: NavItem[] = [
    {
        route: 'looping',
        svg: LoopingSvg,
        label: 'Looping',
        disabled: true,
    },
    {
        route: 'swap',
        svg: SwapSvg,
        label: 'Swap',
    },
    {
        route: 'liquidity-pool',
        svg: LiquiditySvg,
        label: 'Sell Liquidity',
    },
    {
        route: 'certificate',
        svg: CertificateSvg,
        label: 'Token Lockup Rewards',
    },
    {
        route: 'nft',
        svg: NftSvg,
        label: 'NFT',
    },
    {
        route: 'mint',
        svg: MintSvg,
        label: 'Mint',
    },
    {
        route: 'redeem',
        svg: RedeemSvg,
        label: 'Redeem',
    },
    {
        route: 'collateral',
        svg: CollateralSvg,
        label: 'Collateral',
    },
    {
        route: 'arbitrage',
        svg: ArbitrageSvg,
        label: 'Arbitrage',
    },
    {
        route: 'pricing',
        svg: PricingSvg,
        label: 'Pricing Charts',
    },
];

export function Sidebar() {
    const [showRecalculateInfo, setShowRecalculateInfo] = useState(false);
    const {
        isDarkTheme,
        isSidebarOpen,
        updateSettings,
    } = useAppSettings();

    function isActive(name: string) {
        return typeof window !== 'undefined'
            ? window.location.href.includes(name)
            : '';
    }

    function changeRoute() {
        updateSettings((prev) => ({
            ...prev,
            isSidebarOpen: false,
        }));
    }

    function getIsOpen() {
        return isSidebarOpen ? 'sidebar_open' : '';
    }

    const globalValues = useGlobalValues();
    const contractValues = useContractValues();
    const { contracts, getProvider } = useWeb3Context();
    const {
        pidContract,
        proxyContract,
    } = contracts;
    const provider = getProvider();

    const [isInCooldown, setIsInCooldown] = useState(true);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const setCooldownTimer = useCallback((): void => {
        timeoutRef.current = setTimeout((): void => {
            setIsInCooldown(false);
        }, getMsToRefreshAvailable());
    }, []);

    /* Recalculate button
    ---------------------------------------------------- */
    const {
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    const setLoadingMessage = useCallback((): void => {
        resetLoadingMessages(1);
        goToNextLoadingMessage(CustomLoadingMessage.Recalculate);
    }, [resetLoadingMessages, goToNextLoadingMessage]);

    async function onRecalculate(): Promise<void> {
        try {
            setLoadingMessage();
            globalValues.setAreValuesLoading(true);
            setIsInCooldown(true);
            await refreshCollateralRatio(pidContract, provider, proxyContract, globalValues.pidCooldownDuration, globalValues.chain);

            await wait(UDPATE_DELAY);
            contractValues.resetResults();
            setCooldownTimer();
        } catch (e) {
            setIsInCooldown(false);
            Logger.error(e);
            throw e;
        } finally {
            resetLoadingMessages();
            globalValues.setAreValuesLoading(false);
        }
    }

    // https://ossllc.atlassian.net/browse/BAN-427 - enable recalculate button in prod
    const showRecalculateBox = true;

    function RecalulateButton(): React.ReactElement | null {
        if (!showRecalculateBox) {
            return null;
        }

        return (
            <>
                <ConnectButton
                    className="withdraw-margin button-1"
                    style={{ height: '40px' }}
                    disabled={isInCooldown}
                    onClick={onRecalculate}
                    customLoadingMessage={customLoadingMessage}
                    errorMessageOverride="Cooling Down"
                >
                    RECALCULATE
                </ConnectButton>
            </>
        );
    }

    // clear cooldown on page load
    useEffect((): void => setCooldownTimer(), [setCooldownTimer]);

    useEffect((): void => {
        if (globalValues.areValuesLoading) {
            setLoadingMessage();
        } else {
            resetLoadingMessages();
        }
    }, [
        globalValues.areValuesLoading,
        setLoadingMessage,
        resetLoadingMessages
    ]);

    const recalculateBox = (
        <div className={cx(Styles.recalculateBox, 'side-info-wrapper bbm')}>
            <div
                className={`${getThemedClassName(
                    'side-info-box',
                    isDarkTheme
                )} side-info-box-h`}
            >
                {showRecalculateInfo && (
                    <div>
                        <img
                            src="/images/icon/close.svg"
                            onClick={() => setShowRecalculateInfo(false)}
                            className="info-icon close-transition"
                            alt=""
                        />
                        <p
                            className={getThemedClassName('box-p-info', isDarkTheme)}
                            style={{ marginTop: '15px' }}
                        >
                        Pay the gas fees to recalculate the collateral %, update the
                        inflation rate and start incentives if there is a deficit in
                        the liquidity pools or stablecoin collateral.
                        </p>
                    </div>
                )}
                {!showRecalculateInfo && (
                    <>
                        <div>
                            <div>
                                <img
                                    src="/images/icon/info.svg"
                                    onClick={() => setShowRecalculateInfo(true)}
                                    className="info-icon"
                                    alt=""
                                />
                                <p className="box-p">Recalculate Collateral %,</p>
                                <p className="box-p mb-1"> Incentives & Inflation Rate</p>
                            </div>
                        </div>
                        <RecalulateButton />
                    </>
                )}
            </div>
        </div>
    );

    return (
        <aside
            id="sidebar"
            className={`${getIsOpen()} ${getThemedClassName('aside', isDarkTheme)}`}
        >
            <div className="side-scetion">
                <SideInfo />
                <div className={getThemedClassName('main-menu', isDarkTheme)}>
                    <ul style={{ marginBottom: '0px' }}>
                        {
                            NAV_ITEMS
                                .filter(item => !item.disabled)
                                .map((item): React.ReactElement => {
                                    return (
                                        <li key={item.route}>
                                            <NavLink
                                                to={`/${item.route}`}
                                                className={isActive(item.route) ? 'active' : ''}
                                                onClick={changeRoute}
                                            > {item.svg} {item.label}
                                            </NavLink>
                                        </li>
                                    );
                                })
                        }
                    </ul>
                </div>
                {/* hiding in case we bring this feature back */}
                {showRecalculateBox && recalculateBox}
                <ReferralBox />
            </div>
        </aside>
    );
}
