import './styles.scss';

import cx from 'classnames';
import { ConnectButton, ConnectButtonText } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { Settings } from 'components/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { useWeb3Context } from 'context/Web3Provider';
import {
    burnBankx,
    BurnToken,
    burnXSD,
    calculateProfit,
} from 'helpers/arbitrage';
import { ChainIdsHex } from 'helpers/chains';
import { isValidInput } from 'helpers/input';
import { Logger } from 'helpers/logging';
import { truncate } from 'helpers/number';
import { useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { some } from 'lodash';
import React from 'react';

export function Arbitrage(): React.ReactElement {
    const {
        slippage,
        getThemedClass: tcx,
    } = useAppSettings();

    const globals = useGlobalValues();

    const isArbitrageDisabled = some([
        // disable the page if the price differential is less than half a penny
        Math.abs(globals.priceDifference) <= .005,

        // disable arbitrage if arbitrage action is paused in contract
        globals.arbitrage.isPaused,

        // disable arbitrage for Arbitrum
        globals.chain === ChainIdsHex.ArbitrumOne,

        // just placeholder for linter
        false,
    ]);

    const { useInputState } = useTextInputContext();
    const [inputAmount, setInputAmount] = useInputState(TextInputName.Arbitrage);

    const walletValues = globals.walletValues;

    const profit = calculateProfit({
        bankxPrice: globals.bankXTokenPrice,
        inputAmount: inputAmount,
        priceDifference: globals.priceDifference,
        silverPrice: globals.silverPrice,
        xsdPrice: globals.xsdPrice,
        slippage: slippage.value,
        isTestModeReverseCondition: false,
        ethPrice: globals.ethPriceInUsd,
    });

    const { contracts, getProvider } = useWeb3Context();
    const {
        arbitrageContract,
        bankxTokenContract,
        routerContract,
        xsdTokenContract,
    } = contracts;
    const provider = getProvider();

    function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (isArbitrageDisabled) {
            return;
        }
        setInputAmount(e.target.value);
    }

    const {
        isLoading,
        message: customLoadingMessage,
        next: goToNextLoadingMessage,
        reset: resetLoadingMessages,
    } = useCustomLoadingMessage();

    async function onConvert(): Promise<void> {
        // anti-ui tampering
        if (isArbitrageDisabled) {
            return;
        }

        try {
            if (profit.tokenToBurn === BurnToken.Bankx) {
                resetLoadingMessages(4);
                await burnBankx({
                    // contracts
                    arbitrageContract,
                    bankxTokenContract,
                    xsdTokenContract,
                    routerContract,
                    provider,

                    // values
                    bankxInputAmount: inputAmount,
                    bankxMinAmount: profit.amount,
                    ethMinAmount: profit.ethMinAmount,
                    xsdToApprove: profit.amountToApprove,

                    // helpers
                    goToNextLoadingMessage,
                });
            }

            if (profit.tokenToBurn === BurnToken.XSD) {
                resetLoadingMessages(4);
                await burnXSD({
                    // contracts
                    arbitrageContract,
                    bankxTokenContract,
                    xsdTokenContract,
                    routerContract,
                    provider,

                    // values
                    xsdInputAmount: inputAmount,
                    xsdMinAmount: profit.amount,
                    ethMinAmount: profit.ethMinAmount,
                    bankxToApprove: profit.amountToApprove,

                    // helpers
                    goToNextLoadingMessage,
                });
            }
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const hasValidInput = isValidInput(inputAmount);
    const isBurningXsd = profit.tokenToBurn === BurnToken.XSD;
    const walletAmount = isBurningXsd ? walletValues.xsd : walletValues.bankx;
    const maxAmountToBurn = globals.arbitrage.max;

    const limitMessage = function (): string {
        if (Number(inputAmount) > Number(walletAmount)) {
            return ConnectButtonText.NotEnoughBalance;
        }
        if (Number(inputAmount) > Number(maxAmountToBurn)) {
            return `Cannot Exceed Max ${profit.tokenToBurn}`;
        }
        if (!profit.isProfitable) {
            return 'Slippage Too High - Not Profitable';
        }
        return ConnectButtonText.Empty;
    }();
    const isDisabled =  limitMessage.length > 0 || !hasValidInput;

    function handleMaxBtnClick(e: React.FormEvent) {
        e.preventDefault();
        if (isArbitrageDisabled) {
            return;
        }
        const updateValue = Math.min(Number(maxAmountToBurn), Number(walletAmount) * 0.99);
        setInputAmount(truncate(updateValue, 5));
    }

    return (
        <DashboardLayout>
            <div className={tcx('content-wrapper')}>
                <TopCards />
                <div className={cx(tcx('main-card'), 'single-card')}>
                    <div className={cx(tcx('main-card-inner'), { 'disable-overlay': isArbitrageDisabled })}>
                        <Settings
                            isDisabled={isArbitrageDisabled}
                            isSoloCard={true}
                            title={`Burn ${profit.tokenToBurn}`}
                        />
                        <div className="main-card-body">
                            <div>
                                <div className="form-group with-max-btn" >
                                    <TextInput
                                        className={cx(tcx('form-control'), 'with-max-btn')}
                                        disabled={isArbitrageDisabled || isLoading}
                                        name={TextInputName.Arbitrage}
                                        onChange={onChange}
                                        placeholder={`Amount of ${profit.tokenToBurn} to Burn`}
                                        type="number"
                                        value={inputAmount}
                                    />
                                    <button onClick={handleMaxBtnClick} className="button-1 max-btn">MAX</button>
                                </div>

                                <div className={tcx('side-info-box2')}>
                                    <ul>
                                        <li>
                                            <label>Max {profit.tokenToBurn}:</label>
                                            <FormattedValue value={maxAmountToBurn} isLoading={isLoading}/>
                                        </li>
                                        <li>
                                            <label>{profit.tokenToBurn} Available:</label>
                                            <FormattedValue value={walletAmount} isLoading={isLoading}/>
                                        </li>
                                    </ul>
                                </div>
                                <h3 className="mint text-left">To Get More {profit.tokenToBurn}</h3>
                                <div className={cx(tcx('side-info-box'), 'dark-gray-bg')}>
                                    <ul>
                                        <li>
                                            <label>{profit.tokenToBurn} You Receive:</label>
                                            <FormattedValue value={profit.amount} />
                                        </li>
                                        <li>
                                            <label>Estimated Profit:</label>
                                            <FormattedValue value={profit.usdAmount} isCurrency/>
                                        </li>
                                    </ul>
                                </div>
                                <div className={cx('arbitrage__button-description mb-4')}>
                                    Arbitrage between our 2 liquidity pools to get more tokens.
                                </div>
                                <div className="btn-wrap">
                                    <ConnectButton
                                        className={tcx('button-1')}
                                        disabled={isDisabled || isArbitrageDisabled}
                                        onClick={onConvert}
                                        customLoadingMessage={customLoadingMessage}
                                        isLimitExceeded={limitMessage.length > 0}
                                        limitMessage={limitMessage}
                                        onDone={resetLoadingMessages}
                                    >
                                        Arbitrage
                                    </ConnectButton>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </DashboardLayout>
    );
}
